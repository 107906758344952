/**
 * Renders non-reversal line items that are not listed in the
 * `LINE_ITEMS` array in util/types.js
 *
 * The line items are rendered so that the line item code is formatted to human
 * readable form and the line total is printed as price.
 *
 * If you require another kind of presentation for your line items, add them to
 * the `LINE_ITEMS` array in util/types.js and create a specific line item
 * component for them that can be used in the `BookingBreakdown` component.
 */
import React from 'react';
import {intlShape} from '../../util/reactIntl';
import {formatMoney} from '../../util/currency';
import {LINE_ITEM_DISCOUNT, LINE_ITEMS, propTypes} from '../../util/types';

import css from './BookingBreakdown.css';

const LineItemDiscountMaybe = props => {
  const {transaction, isProvider, intl} = props;

  // resolve unknown non-reversal line items
  const allItems = transaction.attributes.lineItems.filter(
    item => LINE_ITEMS.indexOf(item.code) === -1 && !item.reversal
  );

  const items = isProvider
    ? allItems.filter(item => item.includeFor.includes('provider'))
    : allItems.filter(item => item.includeFor.includes('customer'));

  return items.length > 0 ? (
    <React.Fragment>
      {items.filter(item => item.code === LINE_ITEM_DISCOUNT).map((item, i) => {
        const formattedTotal = formatMoney(intl, item.lineTotal);

        return (
          <>
            <div key={`${i}-item.code`} className={css.lineItem}>
              <span className={css.itemLabel}>Discount { isProvider && (<>*</>)}</span>
              <span className={css.itemValue}>{formattedTotal}</span>
            </div>

            { isProvider && (
              <span className={css.feeInfo}>* The buyer used a discount code but don't worry, it comes off our fee. You still keep your full amount!</span>
            )}
          </>
        );
      })


      }
    </React.Fragment>
  ) : null;
};

LineItemDiscountMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemDiscountMaybe;
