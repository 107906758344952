import React, {useEffect, useState} from 'react'
import css from "./Filters.css";
import PropTypes from "prop-types";
import FilterCheckbox from "./FilterCheckbox";
import classNames from "classnames";

const FilterDropdown = ({data,filterState,handleChange, handleOpenState, openState}) => {

  const handleToggleClick = () => {
    if (openState !== data.id ) {
      handleOpenState(data.id);
    } else {
      handleOpenState(undefined);
    }
  }

  const [_openState, set_openState] = useState(false);
  useEffect(() => {
    set_openState(openState === data.id);
  },[openState])

  return <details
    id={`filter-${data.id}`}
    className={css.filter}
    open={_openState}
  >
    <summary
      className={classNames(css.filterButton,css.filterPrimaryButton)}
      aria-label={data.label}
      role="button"
      aria-expanded="false"
      aria-controls={`panel-${data.id}`}
      onClick={handleToggleClick}
    >
      {data.label}
    </summary>
    <div id={`panel-${data.id}`} className={css.filterPanel}>
      { data?.options?.length > 0 &&  <ul role="list">
        {data?.options?.map( ({ label,key}) => (
          <FilterCheckbox
            handleChange={ (checked) => {
              handleChange(data.queryParamName, {
                name : data.queryParamName,
                key : key,
                label : label,
                parentLabel : data.label,
                checked
              })
            }}
            label={label}
            name={data.queryParamName}
            value={key}
            checked={
              !!filterState?.[data.queryParamName]
                ?.find( _filter => {
                  return _filter.key === key;
                })
            }
          />
        ))}
      </ul>}
    </div>
  </details>
}

FilterDropdown.propTypes = {
  data : PropTypes.object.isRequired
}

export default FilterDropdown;