import React from 'react';
import classNames from 'classnames';
import css from './SearchCategories.css';

const data = [
  {
    title: 'Care',
    key: 'care',
  },
  {
    title: 'Feed',
    key: 'feed',
  },
  {
    title: 'Move',
    key: 'move',
  },
  {
    title: 'Organise',
    key: 'organise',
  },
  {
    title: 'Play',
    key: 'play',
  },
  {
    title: 'Sleep',
    key: 'sleep',
  },
  {
    title: 'Wear',
    key: 'wear',
  },
  {
    title: 'Everything',
    key: 'everything',
  },
  {
    title: 'Filter by age',
    key: 'age',
  },
];

const SearchCategories = ({ category, onClick }) => {
  /* Temporary list of static categories for styling purposes */

  return (
    <div className={css.listWrapper}>
      <ul className={css.list}>
        {data.map(item => {
          return (
            <>
              {item.key === 'age' && <div className={css.divider}></div>}
              <li
                key={item.key}
                className={classNames(css.item, category === item.key && css.itemActive)}
              >
                <a href="#" onClick={() => onClick({ pub_category: item.key })}>
                  {item.title}
                </a>
              </li>
            </>
          );
        })}
      </ul>
    </div>
  );
};

export default SearchCategories;
