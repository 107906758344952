import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { InlineTextButton, NamedLink } from '../../components';

import css from './TabNavHorizontal.css';

const { arrayOf, bool, func, node, object, oneOf, string, shape } = PropTypes;

const Tab = props => {
  const { className, disabled, text, selected, onClick, linkProps } = props;

  const linkClasses = classNames(
    css.tabContent,
    {
      [css.selectedTabContent]: selected,
      [css.disabled]: disabled,
    },
    css.tabText
  );

  const buttonClasses = classNames(
    css.tabContent,
    css.button,
    {
      [css.selectedTabContent]: selected,
      [css.disabled]: disabled,
    }
  );

  const isButton = !!onClick;

  return (
    <li className={className}>
      {isButton ? (
        <InlineTextButton rootClassName={buttonClasses} onClick={onClick}>
          <span> {text} </span>
        </InlineTextButton>
      ) : (
        <NamedLink className={linkClasses} {...linkProps}>
          <span> {text} </span> 
        </NamedLink>
      )}
    </li>
  );
};

Tab.defaultProps = { className: null, disabled: false, selected: false };

Tab.propTypes = {
  className: string,
  text: node.isRequired,
  disabled: bool,
  selected: bool,
  onClick: func,
  linkProps: object
};

const TabNavHorizontal = props => {
  const { className, rootClassName, tabRootClassName, tabs, skin } = props;
  const classes = classNames(rootClassName || css.root, className);
  const tabClasses = tabRootClassName || css.tab;
  return (
    <ul className={classes}>
      {tabs.map((tab, index) => {
        const key = typeof tab.text === 'string' ? tab.text : index;
        return <Tab key={key} className={tabClasses} {...tab} />;
      })}
    </ul>
  );
};

/**
 * A tab navigation element with buttons. Requires onClick
 * function param for tab objects passed as parameter.
 */
export const ButtonTabNavHorizontal = props => <TabNavHorizontal {...props} />;

ButtonTabNavHorizontal.defaultProps = {
  className: null,
  rootClassName: null,
  tabRootClassName: null,
  tabClassName: null,
};

ButtonTabNavHorizontal.propTypes = {
  className: string,
  rootClassName: string,
  tabRootClassName: string,
  tabs: arrayOf(
    shape({
      text: node.isRequired,
      disabled: bool,
      selected: bool,
      onClick: func.isRequired,
    })
  ).isRequired
};

/**
 * A tab navigation element with links. Requires linkProps
 * object param for tab objects passed as parameter.
 */
export const LinkTabNavHorizontal = props => <TabNavHorizontal {...props} />;

LinkTabNavHorizontal.defaultProps = {
  className: null,
  rootClassName: null,
  tabRootClassName: null,
  tabClassName: null,
};

LinkTabNavHorizontal.propTypes = {
  className: string,
  rootClassName: string,
  tabRootClassName: string,
  tabs: arrayOf(
    shape({
      text: node.isRequired,
      disabled: bool,
      selected: bool,
      linkProps: object.isRequired,
    })
  ).isRequired
};
