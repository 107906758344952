import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { withViewport } from '../../util/contextHelpers';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import config from '../../config';
import {
  Page,
  SectionHero,
  SectionText,
  SectionContentRail,
  SectionRecentlyListed,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  SectionTestimonial,
  SectionBrandCloud,
  SectionCombo,
  SectionTextOverImage,
  SectionCtaRail,
  SectionAsFeatured
} from '../../components';
import { TopbarContainer } from '../../containers';
import { loadData } from './LandingPage.duck';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import classNames from 'classnames';

// Import Section hero content 
import contentSectionHero from '../../content/landingpage-section-hero.json';

// Import Section Content Rail 
import contentSectionContentRail from '../../content/landingpage-content-rail.json';

// Import Section Testimonials
import contentTestimonial from '../../content/landingpage-testimonial-1.json';
import contentTestimonial2 from '../../content/landingpage-testimonial-2.json';

// Import Section combo content (x2)
import contentSectionCombo1 from '../../content/landingpage-combo-meet-the-founder.json';
import contentSectionCombo2 from '../../content/landingpage-combo-2.json';

import ContentSectionTextOverImage from '../../content/landingpage-text-over-image.json';

import contenSectionBrandCloud from '../../content/landingpage-brand-cloud.json';

// Import Section Content Rail 
import contentSectionCtaRail from '../../content/landingpage-cta-rail.json';

// Import Section as Featured content
import contentSectionAsFeatured from '../../content/landingpage-as-featured.json';



import facebookImage from '../../assets/The_Octopus_Club-01.png';
import twitterImage from '../../assets/The_Octopus_Club-01.png';
import css from './LandingPage.css';

/* Images for CTAs */
import Cta1Image from './take-me-to-play.png';
import Cta2Image from './invite-your-friends.jpg';

// Images for section hero
import imageDesktop1x from './720.jpg';
import imageMobile1x from './400.jpg';
import imageDesktop2x from './1440.jpg';
import imageMobile2x from './800.jpg';

const imageDesktop = [imageDesktop1x,imageDesktop2x];
const imageMobile = [imageMobile1x,imageMobile2x];


const MAX_MOBILE_SCREEN_WIDTH = 768;


// export const LandingPageComponent = props =>
export class LandingPageComponent extends Component {
  render() {
    const {
      history,
      intl,
      location,
      scrollingDisabled,
      recentlyListedListings,
      isAuthenticated,
      viewport,
      fetchRecentlySoldListingsInProgress,
      fetchRecentlySoldListingsError,
      recentlySoldListings,
    } = this.props;
    // Schema for search engines (helps them to understand what this page is about)
    // http://schema.org
    // We are using JSON-LD format
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
    const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
    const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

    const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;

    const recentlySoldTitle = intl.formatMessage({
      id: 'LandingPage.recentlySold',
    });

    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        contentType="website"
        description={schemaDescription}
        title={schemaTitle}
        facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
        twitterImages={[
          { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
        ]}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          description: schemaDescription,
          name: schemaTitle,
          image: [schemaImage],
        }}
        classNameContent={isMobileLayout && css.pageContent}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar className={css.mobileViewHeader}>
            <TopbarContainer />
          </LayoutWrapperTopbar>
         <LayoutWrapperMain>
            <div className={classNames(css.heroContainer, css.mobileView)}>

              <SectionHero
                className={css.hero}
                title={contentSectionHero.title}
                copy={contentSectionHero.copy}
                links={contentSectionHero.links}
                picture={contentSectionHero.picture}
                aspectRatioMobile={contentSectionHero.aspectRatioMobile}
                />
              </div>

              <SectionText
                  className={css.text}
                  title='Second hand shopping made easy'
                  copy='<p>From the latest must-haves to adorable itty-bitty baby clothes, find everything you need for your family in just a few clicks</p>'
                  alignmentOnMobile='left'
              />

              <SectionContentRail
                  className={css.text}
                  content={contentSectionContentRail}
              />

              <SectionTestimonial
                quote={contentTestimonial.quote}
                author={contentTestimonial.author}
                color={contentTestimonial.color}
                contrast={contentTestimonial.contrast}
              />

              <SectionAsFeatured
                className={css.text}
                title={contentSectionAsFeatured.title}
                logos={contentSectionAsFeatured.logos}
              />

              <SectionText
                  className={css.text}
                  title="Powered by parents like YOU"
                  color="pink"
                  contrast="negative"
              />

              <SectionBrandCloud
                title={contenSectionBrandCloud.title}
                copy={contenSectionBrandCloud.copy}
                brands={contenSectionBrandCloud.brands} 
              />

              <SectionRecentlyListed
                className={css.recentlyListed}
                listings={recentlyListedListings}
                history={history}
              />
    

              <SectionCombo     
                  className={css.text}
                  title={contentSectionCombo1.title}
                  copy={contentSectionCombo1.copy}
                  namedLink={contentSectionCombo1.namedLink}
                  namedLinkSlug={contentSectionCombo1.namedLinkSlug}
                  params={contentSectionCombo1.params}
                  to={contentSectionCombo1.to}
                  linkLabel={contentSectionCombo1.linkLabel}
                  color={contentSectionCombo1.color}
                  contrast={contentSectionCombo1.contrast}
                  layout={contentSectionCombo1.layout}
                  layoutMobile={contentSectionCombo1.layoutMobile}
                  image={contentSectionCombo1.image}
                  imageSrcset={contentSectionCombo1.imageSrcset}
                  imageAlt={contentSectionCombo1.imageAlt}
              />
    
              <SectionText
                  className={css.textLessWaste}
                  title="Less waste. More memories."
                  color="green"
                  contrast="positive"
              />

              <SectionText
                  className={css.text}
                  copy='<p>Our community has successfully rehomed<br /><strong>TENS OF THOUSANDS</strong><br />of maternity, baby, and kids’ items across the UK</p>'
                  namedLink='SignupPage'
                  linkLabel='Join for free'
                  color='green'
                  contrast='negative'
                  style='large'
              />
            
              <SectionCombo     
                  className={css.text}
                  title={contentSectionCombo2.title}
                  copy={contentSectionCombo2.copy}
                  namedLink={contentSectionCombo2.namedLink}
                  namedLinkSlug={contentSectionCombo2.namedLinkSlug}
                  params={contentSectionCombo2.params}
                  to={contentSectionCombo2.to}
                  linkLabel={contentSectionCombo2.linkLabel}
                  color={contentSectionCombo2.color}
                  contrast={contentSectionCombo2.contrast}
                  layout={contentSectionCombo2.layout}
                  image={contentSectionCombo2.image}
                  imageSrcset={contentSectionCombo2.imageSrcset}
                  imageAlt={contentSectionCombo2.imageAlt}
              />

              <SectionCtaRail
                  className={css.text}
                  content={contentSectionCtaRail}
              />

              <SectionText
                  className={css.octoplus}
                  copy='<p>All active Octopus Club members have access to over 20 of our favourite brands...for less.</p><p>Babysitting, wine, meals and much more. We have your family covered!</p>                  '
                  namedLink='OctoplusPage'
                  linkLabel='Give me the goods'
                  color='blue'
                  contrast='negative'
                  style='large'
                  logo='/static/logos/octoplus-logo-white.svg'
                  logoWidth="360"
                  logoHeight="85"
               />

              <SectionTextOverImage     
                  className={css.text}
                  title={ContentSectionTextOverImage.title}
                  copy={ContentSectionTextOverImage.copy}
                  namedLink={ContentSectionTextOverImage.namedLink}
                  namedLinkSlug={ContentSectionTextOverImage.namedLinkSlug}
                  linkLabel={ContentSectionTextOverImage.linkLabel}
                  color={ContentSectionTextOverImage.color}
                  contrast={ContentSectionTextOverImage.contrast}
                  layout={ContentSectionTextOverImage.layout}
                  image={ContentSectionTextOverImage.image}
                  imageSrcset={ContentSectionTextOverImage.imageSrcset}
                  imageAlt={ContentSectionTextOverImage.imageAlt}
              />
      
            <SectionTestimonial
                quote={contentTestimonial2.quote}
                author={contentTestimonial2.author}
                color={contentTestimonial2.color}
                contrast={contentTestimonial2.contrast}
              />

          </LayoutWrapperMain>
          <LayoutWrapperFooter className={css.mobileView}>
            <Footer showLogo={isMobileLayout ? false : true} intl={intl} />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

const { bool, object } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape,

  // from withViewport
  viewport: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;


  const {
    loadInProgress,
    loadListingsError,
    recentlyListedListings,
    recentlySoldListings,
  } = state.LandingPage;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    loadInProgress,
    loadListingsError,
    recentlyListedListings,
    isAuthenticated,
    recentlySoldListings,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withViewport,
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(LandingPageComponent);

LandingPage.loadData = loadData;

export default LandingPage;
