import React, {useState, useRef} from 'react';
import {TopbarContainer} from '../../containers';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  Modal,
  Button,
  NamedLink,
  ExternalLink,
} from '../../components';
import {isScrollingDisabled} from '../../ducks/UI.duck';

import css from './OctoplusPartnerPage.css';

import {withRouter} from 'react-router-dom';
import {injectIntl} from '../../util/reactIntl';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {loadData} from './OctoplusPartnerPage.duck';
import {hasAccessToOctoplus} from '../../util/user';
import ReactGA from 'react-ga4';

// Assets
import octoplusLogoImage from './octoplus-logo-white.svg';
import logoPlaceholder from './logoPlaceholder.png';
import quotationMark from './quotation-mark.svg';

import PropTypes from "prop-types";

const OctoplusPartnerPageComponent = props => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    intl,
    scrollingDisabled,
    partner,
    isAuthenticated,
    currentUser,
    loadPartnerInProgress,
    loadPartnerError,
  } = props;

  const codeInputRef = useRef();

  // TODO: possible need to add in extra schema stuff
  const title = intl.formatMessage({id: 'OctoplusPage.title'});

  const showCode = () => {
    setIsModalOpen(true);

    // record action in analytics
    let action;

    if (hasAccessToOctoplus(currentUser)) {
      action = 'PartnerCodeCopiedByOctoplusMember';
    } else if (isAuthenticated) {
      action = 'PartnerCodeCopiedByRegisteredUser';
    } else {
      action = 'PartnerCodeCopiedByAnonymousUser';
    }

    // record standard event for total number of clicks regardless of user
    ReactGA.event({
      category: 'PartnerCodeCopied',
      action: partner.slug,
      label: partner.partnerName,
      value: 1
    });

    // record specific event for total number of clicks per user type
    ReactGA.event({
      category: action,
      action: partner.slug,
      label: partner.partnerName,
      value: 1
    });
  }

  const handlePartnerWebsiteClick = () => {
    let action;

    if (hasAccessToOctoplus(currentUser)) {
      action = 'PartnerWebsiteClickedByOctoplusMember';
    } else if (isAuthenticated) {
      action = 'PartnerWebsiteClickedByRegisteredUser';
    } else {
      action = 'PartnerWebsiteClickedByAnonymousUser';
    }

    // record standard event for total number of clicks regardless of user
    ReactGA.event({
      category: 'PartnerWebsiteClicked',
      action: partner.slug,
      label: partner.partnerName,
      value: 1
    });

    // record specific event for total number of clicks per user type
    ReactGA.event({
      category: action,
      action: partner.slug,
      label: partner.partnerName,
      value: 1
    });
  }

  const copyCode = () => {
    codeInputRef.current.select();
    document.execCommand('copy');
  }

  const renderJoinButton = () => {
    if (!currentUser) {
      return (
        <div className={css.modalButtonsWrapper}>
          <NamedLink name="SignupPage" className={css.button}>
            Join our club for free
          </NamedLink>
        </div>
      )
    } else if (!hasAccessToOctoplus(currentUser)) {
      return (
        <div className={css.modalButtonsWrapper}>
          <NamedLink name="SearchPage" className={css.button}>
            Start buying & selling
          </NamedLink>
        </div>
      )
    }
  }

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer/>
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.wrapper}>
          {partner && (
            <>
              <Modal
                id="CopyDiscountModal"
                isOpen={isModalOpen}
                onClose={() => {
                  setIsModalOpen(false);
                }}
                usePortal
                contentClassName={css.modalContent}
                onManageDisableScrolling={() => {}}
              >
                <div>
                  <div className={css.modalTitle}></div>

                  {hasAccessToOctoplus(currentUser) ? (
                    <>
                      <div className={css.discountCode}>
                        {partner.partnerDiscountCodeType === 'code' ? (
                          <>
                            <input type="text" value={partner.partnerDiscountCode} ref={codeInputRef}/>
                            <a className={css.button} onClick={copyCode}><span>Copy</span></a>
                          </>
                        ) : (
                          <p>{partner.partnerDiscountCode}</p>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <p className={css.modalMessage}>To access discount codes, you need to be an active buyer and/or seller, i.e. have bought or sold something in the past 30 days, at The Octopus Club.</p>

                      {renderJoinButton()}
                    </>
                  )}
                </div>
              </Modal>

              <div className={css.header}>
                {/* Logo */}
                <h1 className={css.octoplusLogo}>
                  <a href="/octoplus">
                    <img src={octoplusLogoImage} alt="Octoplus"/>
                  </a>
                </h1>

                  {/* Perk card */}
                  <div className={css.perkCard}>
                      {/* Image */}
                      <div className={css.perkCardImage}>
                        <img src={partner.partnerFeatureImage[0].large1x }
                          srcSet={`${partner.partnerFeatureImage[0].large1x } 1x, ${partner.partnerFeatureImage[0].large2x } 2x`}
                          width="560"
                          height="560"
                          alt="" />
                      </div>
                    <div className={css.perkCardInfo}>
                        {/* Logo */}
                        <div className={css.perkCardLogo}>
                          <img src={partner.partnerLogo[0].large1x}
                            srcSet={`${partner.partnerLogo[0].large1x } 1x, ${partner.partnerLogo[0].large2x } 2x`}
                            width="256"
                            height="256"
                            alt="" />
                        </div>
                      <div className={css.perkCardCopy}>
                        <div dangerouslySetInnerHTML={{__html:partner.partnerDiscountSummaryTop}}></div>
                        <div className={css.perkCardCopyDiscount}>{partner.partnerDiscountSummaryMiddle}</div>
                        <div dangerouslySetInnerHTML={{__html:partner.partnerDiscountSummaryBottom}}></div>
                      </div>
                    </div>
                  </div>
                  <div className={css.container}>
                    <div dangerouslySetInnerHTML={{__html:partner.partnerDescription}} />
                  </div>
                </div>

                <div className={css.verticallySpaced}>
                  <div className={css.container}>

                    {/* Testimonial */}
                    <blockquote className={css.testimonial}>
                      <div className={css.testimonialQuote} dangerouslySetInnerHTML={{__html:partner.partnerReview}} />
                      <div className={css.testimonialAuthor}>{ partner.partnerReviewBy}</div>
                    </blockquote>
                    <div>

                    {/* Perk actions */}
                    <ul className={css.perkActions}>
                      <li>
                        <a className={css.button} onClick={showCode}>
                          Copy Discount Code
                        </a>
                      </li>
                      <li>
                        <ExternalLink onClick={handlePartnerWebsiteClick} href={partner.partnerUrl} className={`${css.button} ${css.buttonSecondary}`}>
                          Go To Partners Website
                        </ExternalLink>
                      </li>
                    </ul>

                    </div>
                  </div>
                </div>

                <div className={css.footer}>
                  <div>
                    <p>The Octopus Club’s benefits programme</p>
                  </div>
                  <div>
                    <NamedLink name="OctoplusPage" className={`${css.button} ${css.buttonWhite}`}>
                      See all deals
                    </NamedLink>
                  </div>
                </div>
              </>
            )}
          </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer/>
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
}

const {bool} = PropTypes;

OctoplusPartnerPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
};

const mapStateToProps = state => {
  const {isAuthenticated} = state.Auth;
  const {currentUser} = state.user;

  const {
    loadPartnerInProgress,
    loadPartnerError,
    partner,
  } = state.OctoplusPartnerPage;

  return {
    loadPartnerInProgress,
    loadPartnerError,
    partner,
    isAuthenticated,
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const OctoplusPartnerPage = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(OctoplusPartnerPageComponent);

OctoplusPartnerPage.loadData = loadData;

export default OctoplusPartnerPage;
