import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaFacebook.css';

const IconSocialMediaFacebook = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m24 12.0733c0-6.66788-5.3726-12.0733-12-12.0733-6.62742 0-12 5.40542-12 12.0733 0 6.0262 4.3882 11.021 10.125 11.9267v-8.4367h-3.04688v-3.49h3.04688v-2.65986c0-3.02589 1.7916-4.69729 4.5326-4.69729 1.3129 0 2.6862.23581 2.6862.23581v2.97117h-1.5132c-1.4906 0-1.9556.93074-1.9556 1.88552v2.26465h3.3281l-.532 3.49h-2.7961v8.4367c5.7368-.9057 10.125-5.9003 10.125-11.9267z" fill="currentColor"/>
    </svg>
  );
};

IconSocialMediaFacebook.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaFacebook.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaFacebook;
