import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { addMoney, formatMoney } from '../../util/currency';
import {
  propTypes,
  LINE_ITEM_PROVIDER_COMMISSION,
  LINE_ITEM_PROVIDER_FIXED_COMMISSION,
    LINE_ITEM_PROVIDER_COMBINED_COMMISSION
} from '../../util/types';

import css from './BookingBreakdown.css';

const LineItemProviderCommissionRefundMaybe = props => {
  const { transaction, isProvider, intl } = props;

  const refund = transaction.attributes.lineItems.filter(
    item => item.code === LINE_ITEM_PROVIDER_COMMISSION && item.reversal
  );

  const fixedCommissionRefund = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_PROVIDER_FIXED_COMMISSION && item.reversal
  ) ?  transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_PROVIDER_FIXED_COMMISSION && item.reversal
  ) : null ;

  const combinedCommissionRefund = transaction.attributes.lineItems.find(
      item => item.code === LINE_ITEM_PROVIDER_COMBINED_COMMISSION && item.reversal
  ) ?  transaction.attributes.lineItems.find(
      item => item.code === LINE_ITEM_PROVIDER_COMBINED_COMMISSION && item.reversal
  ) : null ;

  return isProvider && refund && refund.length > 0 ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="BookingBreakdown.refundProviderFee" />
      </span>
      {combinedCommissionRefund &&
      <span className={css.itemValue}>{formatMoney(intl, combinedCommissionRefund[0] && combinedCommissionRefund[0].lineTotal ? combinedCommissionRefund[0].lineTotal : 0)}</span>
      }
      <span className={css.itemValue}>{addMoney(intl, refund[0] && refund[0].lineTotal ? refund[0].lineTotal : 0, refund[1] && refund[1].lineTotal ? refund[1].lineTotal : 0, fixedCommissionRefund ? fixedCommissionRefund.lineTotal : null)}</span>
    </div>
  ) : null;
};

LineItemProviderCommissionRefundMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemProviderCommissionRefundMaybe;
