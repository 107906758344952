/**
 * This is a wrapper component for different Layouts.
 * Navigational 'aside' content should be added to this wrapper.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { LinkTabNavHorizontal } from '..';

import css from './LayoutWrapperNav.css';

const LayoutWrapperNav = props => {
  const { className, rootClassName, children, tabs, title } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <>
    { title && <h1 className={css.title}>{title}</h1> }
    <nav className={classes}>
      {tabs ? <LinkTabNavHorizontal rootClassName={css.tabs} tabRootClassName={css.tab} tabs={tabs} /> : null}
      {children}
    </nav>
    </>
  );
};

LayoutWrapperNav.defaultProps = {
  className: null,
  rootClassName: null,
  children: null,
  tabs: null,
};

const { node, string, array } = PropTypes;

LayoutWrapperNav.propTypes = {
  children: node,
  className: string,
  rootClassName: string,
  tabs: array,
};

export default LayoutWrapperNav;
