/*
 * Renders a group of checkboxes that can be used to select
 * multiple values from a set of options.
 *
 * The corresponding component when rendering the selected
 * values is PropertyGroup.
 *
 */

import React from 'react';
import { arrayOf, bool, node, shape, string, number } from 'prop-types';
import classNames from 'classnames';
import { FieldArray } from 'react-final-form-arrays';
import { FieldCheckbox, ValidationError } from '../../components';

import css from './FieldCheckboxGroup.css';
import {useFormState} from "react-final-form";

const FieldCheckboxRenderer = props => {
  const { values } = useFormState();

  const { className, rootClassName, label, twoColumns, id, fields, options, meta, type, maxNumber } = props;

  const classes = classNames(rootClassName || css.root, className);
  const listClasses = twoColumns ? classNames(css.list, css.twoColumns) : css.list;

  /**
   * perform check to see if a given checkbox needs to be disabled based
   * on max number of selections in array allowed
   * @param option
   * @returns {boolean}
   */
  const isOptionDisabled = (option) => {
    if (maxNumber > 0) {
      if (values[fields.name]) {
        return values[fields.name].length === maxNumber && !values[fields.name].includes(option.key);
      }
    }

    return false;
  };

  const displayMaxNumberNotice = () => {
    if (maxNumber > 0) {
      if (values[fields.name]) {
        return values[fields.name].length === maxNumber;
      }
    }

    return false;
  }

  return (
    <fieldset className={classes}>
      {label ? <legend>{label}</legend> : null}

      <ul className={listClasses}>
        {options.map((option, index) => {
          const fieldId = `${id}.${option.key}`;
          return (
            <li key={fieldId} className={css.item}>
              <FieldCheckbox
                type={type === 'radio' ? type : 'checkbox'}
                id={fieldId}
                name={type === 'radio' ? 'category' : fields.name}
                label={option.label}
                value={option.key}
                disabled={isOptionDisabled(option)}
              />
            </li>
          );
        })}
      </ul>
      <ValidationError fieldMeta={{ ...meta }} />

      { displayMaxNumberNotice() && (
          <p className={css.maxNumberWarning}>Maximum of {maxNumber} can be selected</p>
      )}
    </fieldset>
  );
};

FieldCheckboxRenderer.defaultProps = {
  rootClassName: null,
  className: null,
  label: null,
  twoColumns: false,
  maxNumber: 0
};

FieldCheckboxRenderer.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  label: node,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: node.isRequired,
    })
  ).isRequired,
  twoColumns: bool,
  maxNumber: number
};

const FieldCheckboxGroup = props => <FieldArray component={FieldCheckboxRenderer} {...props} />;

// Name and component are required fields for FieldArray.
// Component-prop we define in this file, name needs to be passed in
FieldCheckboxGroup.propTypes = {
  name: string.isRequired,
  maxNumber: number
};

export default FieldCheckboxGroup;
