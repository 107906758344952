import React from 'react';
import heroContent from "../../content/about-hero.json";
import {SectionHero} from "../../components";

const OurStoryTop = ({}) => {

    return <>
        <SectionHero
            title={heroContent.title}
            picture={heroContent.picture}
        />
    </>

}

export default OurStoryTop;