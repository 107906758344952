import React from 'react';
import config from '../../config';
import { twitterPageURL } from '../../util/urlHelpers';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  ExternalLink,
  SectionHero,
  SectionText,
  SectionSteps,
  SectionTestimonial,
  SectionInstagram
} from '../../components';


import css from './AboutPage.css';

// Import Section Text Did you know
import heroContent from '../../content/about-hero.json';
import didYouKNowContent from '../../content/about-did-you-know.json';
import theOctopusClubDifference from '../../content/about-the-octopus-club-difference.json';
import joinContent from '../../content/about-join-for-free.json';
import testimonial from '../../content/about-testimonial.json';
import instagramContent from '../../content/instagram.json';

const AboutPage = () => {
  const { siteTwitterHandle, siteFacebookPage } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  // prettier-ignore
  return (
    <StaticPage
      title="Our Story"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'AboutPage',
        description: 'About The Octopus Club',
        name: 'Our Story"',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain>

          <SectionHero
              className={css.hero}
              title={heroContent.title}
              picture={heroContent.picture}
          />

          <div className={css.container}>
            <div className={css.intro}>
            <p>
              The way we shop for our children needs to change. 
            </p>
            <p>
              Here&#x2019;s why our founder, Ana, created a sustainable way to give our little ones the BEST without compromising our planet, wallet and living room. 
            </p>
            </div>
            <p>
              It started with a late-night search. 
            </p>
            <p>
              My prenatal group chat convinced me that my life would change, if I had a Bumbo chair for my (then) 6-month-old Max. 
            </p>
            <p>
              There I was. Head down. Scrolling. Checking every review on every forum. Then I looked up. 
            </p>
            <p>
              <strong>My living room was already buried under tons of baby stuff.</strong>
            </p>
            <p>
              Things we <strong>hadn&#x2019;t even used yet</strong>, that I simply had to have the second he was Earth-side. 
            </p>
            <p>
              Things Max had <strong>already outgrown</strong>, which still had SO much life left in them. 
            </p>
            <p>
              Things that didn&#x2019;t <strong>spark his interest</strong>, left discarded and gathering dust. 
            </p>
            <p>
              That one search awakened something within me. 
            </p>
            <p>
            <strong>There&#x2019;s GOT to be another way? </strong>
            </p>

            <figure>
              <img src="/static/img/ana-2.jpg" width="1520" height="1013" alt="Ana" />
              <figcaption>Here’s why our founder, Ana, created a sustainable way to give our little ones the BEST without compromising our planet, wallet and living room.</figcaption>
            </figure>
            <p>
              I&#x2019;d tried to make environmentally-conscious purchases before Max. But, that planet-loving, furniture-upcycling, vintage-hunting, part of my mum brain seemed to shrink with every ping of the group chat. I assumed NEW = BEST. When in reality I was spending a fortune on things that had such a short shelf life. 
            </p>
            <p>
            <strong>I needed to find another way to shop for me and my baby. </strong>
            </p>
            <p>
              One that didn&#x2019;t involve spending hours trawling search results, looking for reliable sellers. Or putting in hopeful bids. 
            </p>
            <p>
              I just wanted to find other parents, like me, who were surrounded by treasured, discarded, outgrown, or unused items who wanted to pass on the memories and make space for the next phase. Out with the old. But, not in with the NEW. 
            </p>
            <p>
            <strong>A place rooted in kindness.<br />It didn&#x2019;t exist. So, I created it. </strong>
            </p>

          </div>

          <SectionText
              className={css.text}
              title={theOctopusClubDifference.title}
              copy={theOctopusClubDifference.copy}
              link={theOctopusClubDifference.link}
              linkLabel={theOctopusClubDifference.linkLabel}
              color={theOctopusClubDifference.color}
              contrast={theOctopusClubDifference.contrast}
        />

        <SectionText
          title={didYouKNowContent.title}
          copy={didYouKNowContent.copy}
          image={didYouKNowContent.image}
        />
      
      
        <SectionSteps 
            className={css.steps}
            title={joinContent.title}
            copy={joinContent.copy}
            link={joinContent.link}
            linkLabel={joinContent.linkLabel}
            steps={joinContent.steps}
            color={joinContent.color}
            contrast={joinContent.contrast}
          /> 

          <SectionTestimonial
              quote={testimonial.quote}
              author={testimonial.author}
              color={testimonial.color}
              contrast={testimonial.contrast}
            />

          <SectionInstagram 
            copy={instagramContent.copy}
            linkLabel={instagramContent.linkLabel}
            images={instagramContent.images}
          />

        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default AboutPage;
