import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaTwitter.css';

const IconSocialMediaTwitter = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="24" 
      height="21" 
      viewBox="0 0 24 21" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill="currentColor" d="m21.5 5v.7c0 6.7-4.9 14.4-14 14.4-2.8 0-5.4-.8-7.5-2.3.4 0 .8.1 1.2.1 2.3 0 4.4-.8 6.1-2.2-2.2 0-4-1.5-4.6-3.5.3.1.6.1.9.1.5 0 .9-.1 1.3-.2-2.2-.5-3.9-2.6-3.9-5 0 0 0 0 0-.1 0 .4 1.4.6 2.2.6-1.3-.8-2.2-2.4-2.2-4.1 0-.9.2-1.8.7-2.5 2.4 3 6 5 10.1 5.2-.1-.4-.1-.8-.1-1.2 0-2.8 2.2-5 4.9-5 1.4 0 2.7.6 3.6 1.6 1.1-.2 2.2-.6 3.1-1.2-.4 1.2-1.1 2.2-2.2 2.8 1-.1 1.9-.4 2.8-.8-.6 1-1.4 1.9-2.4 2.6z"/>
  </svg>
  );
};

IconSocialMediaTwitter.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaTwitter.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaTwitter;
