import React from 'react';
import { string, array, image, object } from 'prop-types';
import classNames from 'classnames';

import { NamedLink } from '../../components/';

import css from './SectionHero.css';

const SectionHero = props => {
  const { 
    rootClassName, 
    className, 
    title,
    copy,
    links,
    picture,
    aspectRatioMobile
  } = props;

  const aspectRatioMobileClass = aspectRatioMobile ? "aspectratiomobile-" + aspectRatioMobile : null;

  const classes = classNames(rootClassName || css.root, className, aspectRatioMobileClass);

  let actions, sources;

  if (links) {
    actions = links.map((link, index) => {
      return(
        <NamedLink 
          name={link.namedLink} 
          params={{ slug: link.namedLinkSlug }}
          to={{ state: { type: link.namedLinkTo } }}
          className={classNames(css.button, link.color)}
        >
          { link.linkLabel }
        </NamedLink>
        )
      }
    );
  }
  
  if (picture) {
    sources = picture.sources.map((source, index) => {
      return(
        <source srcset={source.srcset} media={source.size} />
        )
      }
    );
  }
 
  return (
    <div className={classes}>
      <div className={css.content}>
        <div className={css.contentTop}>
          <h1 className={css.headline} dangerouslySetInnerHTML={{ __html: title }}></h1>
        </div>

        { (!!copy || links) &&

        <div className={css.contentBottom}>
          
            { copy &&
            <div dangerouslySetInnerHTML={{ __html: copy }}></div>
            }

            { links &&
            <div className={css.contentActions}>
              {actions}
            </div>
            }
          </div>
          
          }

      </div>

    {picture &&      
      <picture>
      
        {sources}
        
        <img
          src={picture.src}
          className={css.background}
          loading="eager"
          alt={picture.alt}
        />
      </picture>
      
    }

    </div>
  );
};

SectionHero.defaultProps = { 
  rootClassName: null, 
  className: null,
  title: null,
  copy: null,
  links: null,
  picture: null,
  aspectRatioMobile: null
 };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
  title: string,
  copy: string,
  links: array,
  picture: object,
  aspectRatioMobile: string,
};

export default SectionHero;
