import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { Button, Form, FieldCurrencyInput, FieldCheckbox } from '../../components';
import css from './EditListingPricingForm.css';
import CustomCategorySelectFieldMaybe from './CustomCategorySelectFieldMaybe';

const { Money } = sdkTypes;

export const EditListingPricingFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        categories,
        values,
        productType,
        showSaleCheckbox,
      } = fieldRenderProps;

      const { collection } = values;

      const unitType = config.bookingUnitType;
      const isNightly = unitType === LINE_ITEM_NIGHT;
      const isDaily = unitType === LINE_ITEM_DAY;

      const translationKey = isNightly
        ? 'EditListingPricingForm.pricePerNight'
        : isDaily
        ? 'EditListingPricingForm.pricePerDay'
        : 'EditListingPricingForm.pricePerUnit';

      const pricePerUnitMessage = intl.formatMessage({
        id: translationKey,
      });

      const pricePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.priceInputPlaceholder',
      });

      const priceLabelMessage = intl.formatMessage({
        id: 'EditListingPricingForm.priceTitle',
      });

      const pricePostageLabelMessage = intl.formatMessage({
        id: 'EditListingPricingForm.pricePostageLabel',
      });

      const collectionLabelMessage = intl.formatMessage({
        id: 'EditListingPricingForm.collection',
      });

      const postageLabelMessage = intl.formatMessage({
        id: 'EditListingPricingForm.postage',
      });

      const collectionAndPostageLabelMessage = intl.formatMessage({
        id: 'EditListingPricingForm.collectionAndPostage',
      });

      const returnLabelMessage = intl.formatMessage({
        id: 'EditListingPricingForm.acceptReturns',
      });

      const saleLabelMessage = intl.formatMessage({
        id: 'EditListingPricingForm.sale',
      });

      const notePostagePrice = (
        <span className={css.note}>
          <FormattedMessage
            id="EditListingPricingForm.noteCalculatePostage"
            values={{
              link1: (
                <a href="https://theoctopusclub.com/faqs" target="_blank">
                  here
                </a>
              ),
              br: <br />,
            }}
          />
        </span>
      );

      const noteAcceptReturn = (
        <span className={css.note}>
          <FormattedMessage id="EditListingPricingForm.noteAcceptReturn" />
        </span>
      );

      const priceRequired = validators.required(
        intl.formatMessage({
          id: 'EditListingPricingForm.priceRequired',
        })
      );
      const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
      const minPriceRequired = validators.moneySubUnitAmountAtLeast(
        intl.formatMessage(
          {
            id: 'EditListingPricingForm.priceTooLow',
          },
          {
            minPrice: formatMoney(intl, minPrice),
          }
        ),
        config.listingMinimumPriceSubUnits
      );
      const priceValidators = config.listingMinimumPriceSubUnits
        ? validators.composeValidators(priceRequired, minPriceRequired)
        : priceRequired;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const { updateListingError, showListingsError } = fetchErrors || {};

      return (
        <Form onSubmit={handleSubmit} className={classes}>
          {updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.updateFailed" />
            </p>
          ) : null}
          {showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.showListingFailed" />
            </p>
          ) : null}

          {showSaleCheckbox && (
            <FieldCheckbox
              id="sale"
              name="sale"
              label={saleLabelMessage}
              className={css.checkboxPrice}
              type="checkbox"
            />
          )}

          <FieldCheckbox
            id="collection"
            name="collection"
            value="collection"
            label={collectionLabelMessage}
            className={css.checkboxPrice}
            type="radio"
          />

          {productType === 'sell' && (
            <>
              <FieldCheckbox
                id="postage"
                name="collection"
                value="postage"
                label={postageLabelMessage}
                className={css.checkboxPrice}
                type="radio"
              />

              <FieldCheckbox
                id="collection-and-postage"
                name="collection"
                value="collection-and-postage"
                label={collectionAndPostageLabelMessage}
                className={css.checkboxPrice}
                type="radio"
              />
            </>
          )}

          {productType === 'sell' && (
            <FieldCurrencyInput
              id="price"
              name="price"
              className={css.priceInput}
              label={priceLabelMessage}
              placeholder={pricePerUnitMessage}
              currencyConfig={config.currencyConfig}
              validate={priceValidators}
              inputRootClass={css.placeholderFix}
            />
          )}

          {collection !== 'collection' && (
            <>
              <FieldCurrencyInput
                id="postagePrice"
                name="postagePrice"
                className={css.postageInput}
                label={pricePostageLabelMessage}
                placeholder={pricePerUnitMessage}
                currencyConfig={config.currencyConfig}
                validate={priceRequired}
                inputRootClass={css.placeholderFix}
              />

              {notePostagePrice}
            </>
          )}

          <CustomCategorySelectFieldMaybe
            id="acceptReturn"
            name="acceptReturn"
            categories={categories}
            intl={intl}
            categoryLabel={returnLabelMessage}
            // categoryPlaceholder={conditionMessagePlaceholder}
            categoryRequiredMessage={intl.formatMessage({
              id: 'EditListingDescriptionForm.categoryPriceRequired',
            })}
          />

          {noteAcceptReturn}

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingPricingFormComponent.defaultProps = { fetchErrors: null };

EditListingPricingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPricingFormComponent);
