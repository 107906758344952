import React, { useState, useEffect } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import { Form, Button, FieldTextInput } from '../../components';
import CustomCategorySelectFieldMaybe from './CustomCategorySelectFieldMaybe';

import css from './EditListingDescriptionForm.css';

const TITLE_MAX_LENGTH = 60;
const VIEWPORT_348 = 348;
const VIEWPORT_393 = 393;
const VIEWPORT_481 = 481;
const VIEWPORT_532 = 532;
const VIEWPORT_533 = 533;

const useWindowSize = () => {
  const isClient = typeof window === 'object';

  const getSize = () => ({
    width: isClient ? window.innerWidth : undefined,
    height: isClient ? window.innerHeight : undefined,
  });

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) return false;

    const handleResize = () => {
      setWindowSize(getSize());
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

const EditListingDescriptionFormComponent = props => {
  const width = useWindowSize().width;
  return (
    <FinalForm
      {...props}
      render={fieldRenderProps => {
        const {
          categories,
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          productType,
          values,
        } = fieldRenderProps;

        const titleMessage =
          productType === 'give'
            ? intl.formatMessage({ id: 'EditListingDescriptionForm.titleGive' })
            : intl.formatMessage({ id: 'EditListingDescriptionForm.title' });
        const titlePlaceholderMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.titlePlaceholder',
        });
        const titleRequiredMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.titleRequired',
        });
        const maxLengthMessage = intl.formatMessage(
          { id: 'EditListingDescriptionForm.maxLength' },
          {
            maxLength: TITLE_MAX_LENGTH,
          }
        );

        const descriptionMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.description',
        });
        const descriptionPlaceholderMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.descriptionPlaceholder',
        });
        const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
        const descriptionRequiredMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.descriptionRequired',
        });

        const brandMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.brand',
        });
        const brandMessagePlaceholder = intl.formatMessage({
          id: 'EditListingDescriptionForm.brandPlaceholder',
        });

        const colorMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.color',
        });
        const colorMessagePlaceholder = intl.formatMessage({
          id: 'EditListingDescriptionForm.colorPlaceholder',
        });

        const ageMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.age',
        });
        const ageMessagePlaceholder = intl.formatMessage({
          id: 'EditListingDescriptionForm.agePlaceholder',
        });

        const sizeMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.size',
        });
        const sizeMessagePlaceholder = intl.formatMessage({
          id: 'EditListingDescriptionForm.sizePlaceholder',
        });

        const conditionMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.condition',
        });

        const conditionDetailMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.conditionDetail',
        });
        const conditionDetailMessagePlaceholder = intl.formatMessage({
          id: 'EditListingDescriptionForm.conditionDetailPlaceholder',
        });

        const { updateListingError, createListingDraftError, showListingsError } =
          fetchErrors || {};
        const errorMessageUpdateListing = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
          </p>
        ) : null;

        // This error happens only on first tab (of EditListingWizard)
        const errorMessageCreateListingDraft = createListingDraftError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
          </p>
        ) : null;

        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
          </p>
        ) : null;

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            {errorMessageCreateListingDraft}
            {errorMessageUpdateListing}
            {errorMessageShowListing}
            {width <= VIEWPORT_348 ? (
              <FieldTextInput
                id="title"
                name="title"
                className={css.title}
                type="text"
                label={titleMessage}
                placeholder={titlePlaceholderMessage}
                maxLength={TITLE_MAX_LENGTH}
                validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
                autoFocus
                type="textarea"
                isRegInput
                rows="2"
              />
            ) : (
              <FieldTextInput
                id="title"
                name="title"
                className={css.title}
                type="text"
                label={titleMessage}
                placeholder={titlePlaceholderMessage}
                maxLength={TITLE_MAX_LENGTH}
                validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
                autoFocus
              />
            )}

            {/* <FieldTextInput
              id="description"
              name="description"
              className={css.description}
              type="textarea"
              label={descriptionMessage}
              placeholder={descriptionPlaceholderMessage}
              validate={composeValidators(required(descriptionRequiredMessage))}
            /> */}

            {width <= VIEWPORT_393 ? (
              <FieldTextInput
                id="brand"
                name="brand"
                className={css.description}
                inputRootClass={css.placeholderFix2}
                type="text"
                label={brandMessage}
                placeholder={brandMessagePlaceholder}
                type="textarea"
                rows="2"
                isRegInput
              />
            ) : (
              <FieldTextInput
                id="brand"
                name="brand"
                className={css.description}
                type="text"
                label={brandMessage}
                placeholder={brandMessagePlaceholder}
              />
            )}

            <FieldTextInput
              id="color"
              name="color"
              className={css.description}
              type="text"
              label={colorMessage}
              placeholder={colorMessagePlaceholder}
            />

            {width <= VIEWPORT_481 ? (
              <FieldTextInput
                id="age"
                name="age"
                className={css.description}
                type="text"
                label={ageMessage}
                placeholder={ageMessagePlaceholder}
                type="textarea"
                rows="2"
                isRegInput
              />
            ) : (
              <FieldTextInput
                id="age"
                name="age"
                className={css.description}
                type="text"
                label={ageMessage}
                placeholder={ageMessagePlaceholder}
              />
            )}

            {width <= VIEWPORT_533 ? (
              <FieldTextInput
                id="size"
                name="size"
                className={css.description}
                type="text"
                label={sizeMessage}
                placeholder={sizeMessagePlaceholder}
                type="textarea"
                rows="2"
                isRegInput
              />
            ) : (
              <FieldTextInput
                id="size"
                name="size"
                className={css.description}
                type="text"
                label={sizeMessage}
                placeholder={sizeMessagePlaceholder}
              />
            )}

            {/* <CustomCategorySelectFieldMaybe
              id="category"
              name="category"
              categories={categories}
              intl={intl}
            /> */}

            <CustomCategorySelectFieldMaybe
              id="condition"
              name="condition"
              categories={categories}
              intl={intl}
              categoryLabel={conditionMessage}
              // categoryPlaceholder={conditionMessagePlaceholder}
            />

            <FieldTextInput
              id="description"
              name="description"
              className={css.description}
              type="textarea"
              label={conditionDetailMessage}
              placeholder={conditionDetailMessagePlaceholder}
              rows={width <= VIEWPORT_532 ? 2 : 1}
            />

            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </Form>
        );
      }}
    />
  );
};

EditListingDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  categories: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);
