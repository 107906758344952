import unionWith from 'lodash/unionWith';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import { formatDateStringToUTC, getExclusiveEndDate } from '../../util/dates';
import { fetchCurrentUser } from '../../ducks/user.duck';
import config from '../../config';
import {subCategories} from "../../marketplace-custom-config";

const SHOWN_COUNT = 24;
const SHOW_MORE_COUNT = 12;

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/SearchPage/SEARCH_LISTINGS_ERROR';

export const SEARCH_MAP_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_MAP_LISTINGS_REQUEST';
export const SEARCH_MAP_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_MAP_LISTINGS_SUCCESS';
export const SEARCH_MAP_LISTINGS_ERROR = 'app/SearchPage/SEARCH_MAP_LISTINGS_ERROR';

export const SEARCH_MAP_SET_ACTIVE_LISTING = 'app/SearchPage/SEARCH_MAP_SET_ACTIVE_LISTING';
export const SEARCH_SET_SHOWN_PRODUCT = 'app/SearchPage/SEARCH_SET_SHOWN_PRODUCT';
export const SEARCH_RESET_SHOWN_PRODUCT = 'app/SearchPage/SEARCH_RESET_SHOWN_PRODUCT';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
  searchMapListingIds: [],
  searchMapListingsError: null,
  shownProdCount: SHOWN_COUNT,
};

const resultIds = data => data.data.map(l => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchMapListingIds: [],
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:
      const oldData = (state.currentPageResultIds && state.currentPageResultIds) || [];
      const data = [...resultIds(payload.data)];
      const newArr = [...new Map(data.map(item => [item.uuid, item])).values()]; //remove duplicate id;
      const isPage1 = state.searchParams.page === 1;

      return {
        ...state,
        currentPageResultIds: isPage1 ? resultIds(payload.data) : newArr,
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };

    case SEARCH_MAP_LISTINGS_REQUEST:
      return {
        ...state,
        searchMapListingsError: null,
      };
    case SEARCH_MAP_LISTINGS_SUCCESS: {
      const searchMapListingIds = unionWith(
        state.searchMapListingIds,
        resultIds(payload.data),
        (id1, id2) => id1.uuid === id2.uuid
      );
      return {
        ...state,
        searchMapListingIds,
      };
    }
    case SEARCH_MAP_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchMapListingsError: payload };

    case SEARCH_MAP_SET_ACTIVE_LISTING:
      return {
        ...state,
        activeListingId: payload,
      };

    case SEARCH_SET_SHOWN_PRODUCT:
      return {
        ...state,
        shownProdCount: state.shownProdCount + SHOW_MORE_COUNT,
      };
    case SEARCH_RESET_SHOWN_PRODUCT:
      return {
        ...state,
        shownProdCount: SHOWN_COUNT,
      };
    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = response => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchMapListingsRequest = () => ({ type: SEARCH_MAP_LISTINGS_REQUEST });

export const searchMapListingsSuccess = response => ({
  type: SEARCH_MAP_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchMapListingsError = e => ({
  type: SEARCH_MAP_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const setShownProdCount = e => ({
  type: SEARCH_SET_SHOWN_PRODUCT,
});

export const reSetShownProdCount = e => ({
  type: SEARCH_RESET_SHOWN_PRODUCT,
});

export const searchListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchListingsRequest(searchParams));

  const { perPage, mapSearch, hideSold, ...rest } = searchParams;

  const params = {
    ...rest,
    perPage: mapSearch ? 100 : perPage,
  };

  if ( !!searchParams.page) {
    params.page = searchParams.page
  } else {
    params.page = 1;
  }

  if (!searchParams.keywords) {
    params.sort = searchParams?.sort || 'createdAt'
  } else {
    params.sort = searchParams?.sort || 'relevance'
  }

  // have to change some params to be multi enum
  params.pub_subCategories && (params.pub_subCategories = `has_any: ${params.pub_subCategories}`);
  params.pub_ages && (params.pub_ages = `has_any: ${params.pub_ages}`);
  params.pub_genders && (params.pub_genders = `has_any: ${params.pub_genders}`);
  params.pub_seasons && (params.pub_seasons = `has_any: ${params.pub_seasons}`);

  // if sort equals the value relevance then just change to empty string as that is what sharetribe requires
  if (params.sort === 'relevance') {
    params.sort = '';
  }

  params.pub_collection && (params.pub_collection = `collection-and-postage,${params.pub_collection}`)

  switch (hideSold) {
    case true:
      params.pub_sold = false;
      break;
    case false:
      params.pub_sold = undefined;
      break;
    case undefined:
      params.pub_sold = false;
      break;
    default:
      params.pub_sold = false;
      break;
  }

  // parse param put through just to remove empty values as it can screw with results e.g. pub_collection=""
  let parsedParams = {};
  Object.entries(params).forEach(([key, value]) => {
    if (value !== null) {
      let include = true;

      if (typeof value === 'string' && value.length === 0) {
        include = false;
      }

      if (include) {
        parsedParams[key] = params[key];
      }
    }
  });

  return sdk.listings
    .query(parsedParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchListingsError(storableError(e)));
      throw e;
    });
};

export const updateFavorites = data => (dispatch, getState, sdk) => {
  return sdk.currentUser
    .updateProfile({ publicData: data })
    .then(() => dispatch(fetchCurrentUser()))
    .catch(e => console.log('error', e));
};

export const setActiveListing = listingId => ({
  type: SEARCH_MAP_SET_ACTIVE_LISTING,
  payload: listingId,
});

export const searchMapListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchMapListingsRequest(searchParams));

  const { perPage, ...rest } = searchParams;
  const params = {
    ...rest,
    per_page: perPage,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchMapListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchMapListingsError(storableError(e)));
      throw e;
    });
};
