import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '..';

import css from './SectionHero.css';

const HeroSubtitle = props => {
  const { intl, classes } = props;
  const buyLink = (
    <NamedLink className={css.link} name="SearchPage" key="SectionHero.buy">
      <FormattedMessage id="SectionHero.buy" />
    </NamedLink>
  );
  const sellLink = (
    <NamedLink
      className={css.link}
      name="NewListingPage"
      key="SectionHero.sell"
      to={{ state: { type: 'sell' } }}
    >
      <FormattedMessage id="SectionHero.sell" />
    </NamedLink>
  );
  const giveLink = (
    <NamedLink
      className={css.link}
      name="NewListingPage"
      key="SectionHero.give"
      to={{ state: { type: 'give' } }}
    >
      <FormattedMessage id="SectionHero.give" />
    </NamedLink>
  );
  const heroSubtitle = intl.formatMessage(
    { id: 'SectionHero.subTitle' },
    { buyLink, sellLink, giveLink }
  );
  return <h2 className={classes}>{heroSubtitle}</h2>;
};

export default HeroSubtitle;
