import React from 'react';
import { string, image } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import { NamedLink } from '../../components/';

import css from './SectionTextOverImage.css';

const SectionTextOverImage = props => {
  const { 
    rootClassName, 
    className, 
    title,
    copy,
    namedLink,
    namedLinkSlug,
    linkLabel,
    color,
    image,
    imageSrcset,
    imageAlt
  } = props;

  const classes = classNames(rootClassName || css.root, className, color);

  return (
    <div className={classes}>
      <div className={css.image}>
        <img 
          src={image}
          srcset={imageSrcset}
          width="560"
          height="560"
          alt={imageAlt}
          loading="lazy"
        />
      </div>
      <div className={css.container}>
       <h2 className={css.title}> { title} </h2>
        <div dangerouslySetInnerHTML={{ __html: copy }}></div>
        { namedLink && 
        <div className={css.footer}>
          <NamedLink
            className={css.button}
            name={namedLink}
            params={{ slug: namedLinkSlug }}
            >
                { linkLabel }
          </NamedLink>
        </div>
        }
      </div>
    </div>
  );
};

SectionTextOverImage.defaultProps = { 
  rootClassName: null, 
  className: null,
  title: null,
  copy: null,
  namedLink: null,
  namedLinkSlug: null,
  linkLabel: null,
  color: null,
  image: null,
  imageSrcset: null,
  imageAlt: string
};

SectionTextOverImage.propTypes = {
  rootClassName: string,
  className: string,
  title: string,
  copy: string,
  namedLink: string,
  namedLinkSlug: string,
  linkLabel: string,
  color: string,
  image: image,
  imageSrcset: image,
  imageAlt: string
};

export default SectionTextOverImage;
