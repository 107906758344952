import React, { Component } from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Page,
  UserNav,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
  SearchResultsPanel,
} from '../../components';
import { TopbarContainer } from '../../containers';

import { fetchFavorites, updateFavorites } from './MyFavoritePage.duck';
import css from './MyFavoritePage.css';

export class MyFavoritePageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPressFavorite: {},
      currentRef: null,
    };

    this.currentUser = {};
    this.shouldUpdate = true;
    this.fetchFavorites = this.fetchFavorites.bind(this);
    this.pressFavorite = this.pressFavorite.bind(this);
    this.getCurrentRef = this.getCurrentRef.bind(this);
  }

  fetchFavorites() {
    const { currentUser, onFetchFavorites } = this.props;
    const favorites =
      currentUser &&
      currentUser.attributes.profile.publicData &&
      currentUser.attributes.profile.publicData.favorites;

    favorites && onFetchFavorites(favorites);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.shouldUpdate) {
      this.fetchFavorites();
      this.shouldUpdate = false;

      const { currentUser } = this.props;
      this.currentUser = currentUser;
    }
  }

  pressFavorite(isFavorite, id) {
    const { onUpdateFavorites } = this.props;
    const userData = this.currentUser;
    const { publicData } = userData.attributes.profile;
    const { favorites } = publicData || {};

    const data = {
      ...publicData,
      favorites: favorites.filter(item => item !== id.uuid),
    };

    this.currentUser.attributes.profile.publicData = data;
    onUpdateFavorites(data, id.uuid);
  }

  getCurrentRef(ref) {
    if (!this.state.currentRef && !this.shouldUpdate) {
      this.setState({
        currentRef: ref,
      });
    }
  }

  render() {
    const {
      currentUser,
      currentUserListing,
      scrollingDisabled,
      intl,
      listings,
      fetching,
      history,
    } = this.props;

    const user = ensureCurrentUser(currentUser);

    const title = intl.formatMessage({ id: 'TopbarDesktop.favourites' });

    return (
      <Page className={css.root} title={title} scrollingDisabled={false}>
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="MyFavoritePage">
              <UserNav selectedPageName="MyFavoritePage" skin="light" />
            </TopbarContainer>
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.content}>
              <h1 className={css.pageTitle}>{title}</h1>
              {fetching && <p>Loading...</p>}
              {!fetching && listings.length === 0 && <p></p>}
              <SearchResultsPanel
                // className={css.searchListingsPanel}
                // pagination={listingsAreLoaded ? pagination : null}
                // search={searchParamsForPagination}
                // setActiveListing={onActivateListing}
                getCurrentRef={this.getCurrentRef}
                history={history}
                className={css.searchListingsPanel}
                listings={listings}
                currentUser={currentUser}
                isPressFavorite={this.state.isPressFavorite}
                pressFavorite={this.pressFavorite}
                hideFooter={true}
              />
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

const mapStateToProps = state => {
  const { currentUser, currentUserListing } = state.user;
  const { listings, fetching } = state.MyFavoritePage;
  return {
    currentUser,
    fetching,
    listings,
    currentUserListing,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchFavorites: ids => dispatch(fetchFavorites(ids)),
    onUpdateFavorites: (data, id) => dispatch(updateFavorites(data, id)),
  };
};

const MyFavoritePage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(MyFavoritePageComponent);

export default MyFavoritePage;
