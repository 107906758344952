import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import { Form, Button, FieldTextInput } from '../../components';

import css from './ContactUsForm.css';

const TITLE_MAX_LENGTH = 60;
const MESSAGE_MAX_LENGTH = 1000;

const ContactUsFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        className,
        disabled,
        handleSubmit,
        intl,
        invalid,
        pristine,
        updated,
        updateInProgress,
        success,
      } = fieldRenderProps;

      const titleRequiredMessage = 'Required *';
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );

      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);

      const submitReady = updated && pristine;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress || success;

      return (
        <Form onSubmit={handleSubmit}>
          <FieldTextInput
            id="name"
            name="name"
            className={css.field}
            type="text"
            label="Name"
            maxLength={TITLE_MAX_LENGTH}
            validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
            autoFocus
          />

          <FieldTextInput
            id="email"
            name="email"
            className={css.field}
            type="email"
            label="Email"
            maxLength={TITLE_MAX_LENGTH}
            validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
          />

          <FieldTextInput
            id="message"
            name="message"
            className={css.field}
            type="textarea"
            label="Message"
            maxLength={MESSAGE_MAX_LENGTH}
            validate={composeValidators(required(titleRequiredMessage))}
            rows={10}
          />

          <div className={css.submitContainer}>
            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              SEND
            </Button>

            <p>{success && 'Sent'}</p>
          </div>
        </Form>
      );
    }}
  />
);

ContactUsFormComponent.defaultProps = { className: null, fetchErrors: null };

ContactUsFormComponent.propTypes = {
  className: string,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
};

export default compose(injectIntl)(ContactUsFormComponent);
