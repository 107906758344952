import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingPricingForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';
import { ensureDotSeparator, getPrice } from '../../util/currency';

import css from './EditListingPricingPanel.css';
import { nightsBetween } from '../../util/dates';

const { Money } = sdkTypes;

const EditListingPricingPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    location,
  } = props;

  const search = useLocation().search;
  const putOnSale = new URLSearchParams(search).get('put_on_sale');

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { price, publicData } = currentListing.attributes;
  const { postagePrice, acceptReturn, type: productType } = publicData;
  let sale = publicData.sale;
  const oldPrice = publicData.oldPrice || {
    amount: price ? price.amount : 0,
    currency: config.currencyConfig,
  };
  if (putOnSale) {
    sale = true;
  }

  const collection = publicData && publicData.collection;
  const collectionInPerson = publicData && publicData.collectionInPerson;

  let convertPostagePrice = '';

  if (postagePrice && collection) {
    //put decimal places
    const convertToArr = Array.from(postagePrice);
    const length = convertToArr.length;
    convertToArr.splice(length - 2, 0, '.');

    //convert postage price to money
    convertPostagePrice = postagePrice && getPrice(convertToArr.join(''), config.currencyConfig);
  }

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingPricingPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingWizard.tabLabelPricing" />
  );

  const isCollectionAnObject = collection && typeof collection === 'object';
  const isCollectionInPersonAnObject = collectionInPerson && typeof collectionInPerson === 'object';

  const isCollectionOnly =
    collection === 'collection' ||
    !!(isCollectionAnObject ? collection && collection.length : collection === 'collection') ||
    productType === 'give';

  const isPostageOnly =
    collection === 'postage' ||
    (isCollectionInPersonAnObject
      ? !isCollectionOnly && !(collectionInPerson && collectionInPerson.length)
      : collection === 'postage');

  const isCollectionAndPostage =
    collection === 'collection-and-postage' || (!isCollectionOnly && !isPostageOnly);

  const priceCurrencyValid = price instanceof Money ? price.currency === config.currency : true;
  const form = priceCurrencyValid ? (
    <EditListingPricingForm
      className={css.form}
      initialValues={{
        price,
        collection: isCollectionOnly
          ? 'collection'
          : isPostageOnly
          ? 'postage'
          : 'collection-and-postage',
        acceptReturn,
        postagePrice: postagePrice ? new Money(postagePrice, config.currency) : null,
        sale,
      }}
      onSubmit={values => {
        const { price, postagePrice, collection, acceptReturn, sale } = values;

        //get amount from money object and ensure it will be string format
        //postagePrice must not be in Money type to avoid error in sharetribe

        const postageAmount = postagePrice && postagePrice.amount ? postagePrice.amount : 0;
        const updatedPublicData = {
          collection,
          postagePrice: postageAmount,
          acceptReturn,
          collectionInPerson: null,
          sale,
        };

        if (putOnSale || sale) {
          updatedPublicData.oldPrice = oldPrice;
        }

        const updatedValues = {
          price: productType === 'give' ? new Money(0, config.currency) : price,
          publicData: updatedPublicData,
        };

        onSubmit(updatedValues);
      }}
      onChange={onChange}
      saveActionMsg={submitButtonText}
      disabled={disabled}
      ready={ready}
      updated={panelUpdated}
      updateInProgress={updateInProgress}
      fetchErrors={errors}
      categories={config.custom.pricingOptions}
      productType={productType}
      putOnSale={putOnSale}
      showSaleCheckbox={isPublished}
    />
  ) : (
    <div className={css.priceCurrencyInvalid}>
      <FormattedMessage id="EditListingPricingPanel.listingPriceCurrencyInvalid" />
    </div>
  );

  return (
    <div className={classes}>
      <h2 className={css.title}>{panelTitle}</h2>
      {form}
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingPricingPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingPricingPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingPricingPanel;
