import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { currentUserShowSuccess } from '../../ducks/user.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import { fetchCurrentUser } from '../../ducks/user.duck';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const QUERY_LISTING_REQUEST = 'app/MyFavoritesPage/QUERY_LISTING_REQUEST';
export const QUERY_LISTING_SUCCESS = 'app/MyFavoritesPage/QUERY_LISTING_SUCCESS';
export const REMOVE_LISTING = 'app/MyFavoritesPage/REMOVE_LISTING';

// ================ Reducer ================ //

const initialState = {
  listings: [],
  fetching: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case QUERY_LISTING_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case QUERY_LISTING_SUCCESS: {
      return {
        ...state,
        listings: [...payload.params],
        fetching: false,
      };
    }
    case REMOVE_LISTING: {
      const newListing = state.listings.filter(item => item.id.uuid !== payload.id);
      return {
        ...state,
        listings: newListing,
        fetching: false,
      };
    }
    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const fetchRequest = () => ({
  type: QUERY_LISTING_REQUEST,
});

export const updateFavoritesSuccess = params => ({
  type: QUERY_LISTING_SUCCESS,
  payload: { params },
});

export const removeFavorites = id => ({
  type: REMOVE_LISTING,
  payload: { id },
});

// ================ Thunk ================ //

export const updateFavorites = (data, id) => (dispatch, getState, sdk) => {
  dispatch(removeFavorites(id));
  return sdk.currentUser.updateProfile({ publicData: data });
};

export const fetchFavorites = ids => (dispatch, getState, sdk) => {
  dispatch(fetchRequest());

  const data = ids.map(async id => {
    const listingId = new UUID(id);
    const params = {
      id: listingId,
      include: ['images'],
      'fields.listing': ['title', 'geolocation', 'price', 'publicData', 'state'],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
      'fields.image': ['variants.scaled-medium'],
      'limit.images': 1,
    };

    try {
      const results = await sdk.listings.show(params);

      // we only want to show unclosed items
      if (results.data.data.attributes.state !== 'closed') {
        return { ...results.data.data, images: results.data.included };
      }
    } catch (e) {
      // favourites can be deleted in the console but the users still have a reference to this
      console.log(`Error retrieving favourite: `, e)
    }
  });

  return Promise.all(data)
      .then(data => {
        let favourites = [];

        // some results will be null due to the listing being closed
        data.forEach((favourite) => {
          if (favourite) {
            favourites.push(favourite);
          }
        })

        dispatch(updateFavoritesSuccess(favourites));
      })
      .catch(e => {
        console.log('error', e);
      });
};
