import React, { Component } from 'react';
import { array, bool, func, number, oneOf, object, shape, string } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import debounce from 'lodash/debounce';
import unionWith from 'lodash/unionWith';
import classNames from 'classnames';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import pickBy from 'lodash/pickBy';
import { parse, stringify } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { SearchMap, ModalInMobile, Page, Button } from '../../components';
import { TopbarContainer } from '../../containers';
import Toggle from 'react-toggle';
import { types as sdkTypes } from '../../util/sdkLoader';

import {
  searchListings,
  searchMapListings,
  setActiveListing,
  setShownProdCount,
  reSetShownProdCount,
  updateFavorites,
} from './SearchPage.duck';
import {
  pickSearchParamsOnly,
  validURLParamsForExtendedData,
  validFilterParams,
  createSearchResultSchema,
} from './SearchPage.helpers';
import MainPanel from './MainPanel';
import css from './SearchPage.css';
import {subCategories} from "../../marketplace-custom-config";

const { LatLng, LatLngBounds } = sdkTypes;

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 24;
const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout
const SEARCH_WITH_MAP_DEBOUNCE = 300; // Little bit of debounce before search is initiated.

export class SearchPageComponent extends Component {
  constructor(props) {
    super(props);
    // parse query parameters, including a custom attribute named category
    const { mapSearch, hideSold } = parse(props.location.search);

    this.state = {
      isSearchMapOpenOnMobile: mapSearch,
      isMobileModalOpen: false,
      isFilterCollapsed: false,
      currentRef: null,
      showMap: mapSearch,
      hideSold: hideSold,
      isPressFavorite: {},
    };

    this.searchMapListingsInProgress = false;

    this.onMapMoveEnd = debounce(this.onMapMoveEnd.bind(this), SEARCH_WITH_MAP_DEBOUNCE);
    this.onOpenMobileModal = this.onOpenMobileModal.bind(this);
    this.onCloseMobileModal = this.onCloseMobileModal.bind(this);
    this.redirectToURLWithoutModalState = this.redirectToURLWithoutModalState.bind(this);
    this.onResultButtonClick = this.onResultButtonClick.bind(this);
    this.scrollToMyRef = this.scrollToMyRef.bind(this);
    this.getCurrentRef = this.getCurrentRef.bind(this);
    this.loadDataSearch = this.loadDataSearch.bind(this);
    this.changeView = this.changeView.bind(this);
    this.toggleMap = this.toggleMap.bind(this);
    this.hideSoldItems = this.hideSoldItems.bind(this);
    this.pressFavorite = this.pressFavorite.bind(this);
  }

  // Callback to determine if new search is needed
  // when map is moved by user or viewport has changed
  onMapMoveEnd(viewportBoundsChanged, data) {
    const { viewportBounds, viewportCenter } = data;

    const routes = routeConfiguration();
    const searchPagePath = pathByRouteName('SearchPage', routes);
    const currentPath =
      typeof window !== 'undefined' && window.location && window.location.pathname;

    // When using the ReusableMapContainer onMapMoveEnd can fire from other pages than SearchPage too
    const isSearchPage = currentPath === searchPagePath;

    // If mapSearch url param is given
    // or original location search is rendered once,
    // we start to react to "mapmoveend" events by generating new searches
    // (i.e. 'moveend' event in Mapbox and 'bounds_changed' in Google Maps)
    if (viewportBoundsChanged && isSearchPage) {
      const { history, location, filterConfig } = this.props;

      // parse query parameters, including a custom attribute named category
      const { address, bounds, mapSearch, ...rest } = parse(location.search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
      });

      //const viewportMapCenter = SearchMap.getMapCenter(map);
      const originMaybe = config.sortSearchByDistance ? { origin: viewportCenter } : {};

      const searchParams = {
        address,
        ...originMaybe,
        bounds: viewportBounds,
        mapSearch: true,
        ...validFilterParams(rest, filterConfig),
      };

      history.push(createResourceLocatorString('SearchPage', routes, {}, searchParams));
    }
  }

  // Invoked when a modal is opened from a child component,
  // for example when a filter modal is opened in mobile view
  onOpenMobileModal() {
    this.setState({ isMobileModalOpen: true });
  }

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  onCloseMobileModal() {
    this.setState({ isMobileModalOpen: false });
  }

  redirectToURLWithoutModalState(modalStateParam) {
    const { history, location } = this.props;
    const { pathname, search, state } = location;
    const queryParams = pickBy(parse(search), (v, k) => {
      return k !== modalStateParam;
    });
    const stringified = stringify(queryParams);
    const searchString = stringified ? `?${stringified}` : '';
    history.push(`${pathname}${searchString}`, state);
  }

  getCurrentRef(ref) {
    if (!this.state.currentRef) {
      this.setState({
        currentRef: ref,
      });
    }
  }

  onResultButtonClick() {
    this.scrollToMyRef();
  }

  scrollToMyRef(event) {
    if (this.state.currentRef.current) {
      this.state.currentRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }

  loadDataSearch(data) {
    this.props.onLoadSearch({
      ...data,
      perPage: RESULT_PAGE_SIZE,
      include: ['author', 'images'],
      'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
      // 'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
      'fields.image': ['variants.scaled-medium'],
      'limit.images': 1,
    });
  }

  toggleMap() {
    const isWindowDefined = typeof window !== 'undefined';
    const { history, location, filterConfig } = this.props;
    // parse query parameters, including a custom attribute named category
    const { ...rest } = parse(location.search);
    const isMobileLayout = isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;
    const defaultBounds = isMobileLayout
      ? [56.1543591, 2.3823084, 48.14742239, -4.98025716]
      : [54.23424235, 4.27616152, 50.54036727, -6.22776534];
    const routes = routeConfiguration();
    const searchParams = {
      bounds: defaultBounds.join(','),
      mapSearch: true,
      ...validFilterParams(rest, filterConfig),
    };

    history.push(createResourceLocatorString('SearchPage', routes, {}, searchParams));
  }

  changeView() {
    if (this.state.showMap) {
      const { location } = this.props;
      const { bounds, mapSearch, ...rest } = parse(location.search);

      this.props.history.push(
        createResourceLocatorString('SearchPage', routeConfiguration(), {}, rest)
      );
    } else {
      this.toggleMap();
    }

    this.setState({ showMap: !this.state.showMap });
  }

  hideSoldItems(isMobile) {
    const routes = routeConfiguration();

    const { history, location, filterConfig } = this.props;

    // parse query parameters, including a custom attribute named category
    const { ...rest } = parse(location.search);

    const searchParams = {
      ...rest,
      hideSold: !this.state.hideSold,
    };

    if (isMobile) {
      searchParams.browsecategories = 'open';
    }

    this.setState({ hideSold: !this.state.hideSold });
    history.push(createResourceLocatorString('SearchPage', routes, {}, searchParams));
  }

  pressFavorite(isFavorite, id) {
    const { currentUser, onUpdateFavorites } = this.props;
    const userData = currentUser;
    const { publicData } = userData.attributes.profile;
    const { favorites } = publicData || {};
    this.setState({
      isPressFavorite: {
        ...this.state.isPressFavorite,
        [id.uuid]: !isFavorite,
      },
    });
    if (!isFavorite) {
      const data = {
        ...publicData,
        favorites: favorites ? [...favorites, id.uuid] : [id.uuid],
      };

      onUpdateFavorites(data);
    } else {
      const data = {
        ...publicData,
        favorites: favorites.filter(item => item !== id.uuid),
      };

      onUpdateFavorites(data);
    }
  }

  render() {
    const {
      intl,
      listings,
      filterConfig,
      sortConfig,
      history,
      location,
      mapListings,
      onManageDisableScrolling,
      pagination,
      scrollingDisabled,
      searchInProgress,
      searchListingsError,
      searchParams,
      activeListingId,
      onActivateListing,
      onSetShownProdCount,
      onResetShownProdCount,
      shownProdCount,
      currentUser,
    } = this.props;
    // eslint-disable-next-line no-unused-vars
    const { mapSearch, page, ...searchInURL } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });
    const { showMap } = this.state;

    // urlQueryParams doesn't contain page specific url params
    // like mapSearch, page or origin (origin depends on config.sortSearchByDistance)
    const urlQueryParams = pickSearchParamsOnly(searchInURL, filterConfig, sortConfig);

    // Page transition might initially use values from previous search
    const urlQueryString = stringify(urlQueryParams);
    const paramsQueryString = stringify(
      pickSearchParamsOnly(searchParams, filterConfig, sortConfig)
    );
    const wearCategoryExcluded =
      urlQueryString &&
      paramsQueryString &&
      (urlQueryString.includes('wear-maternity%2C') &&
        !paramsQueryString.includes('wear-maternity%2C'));
    const searchParamsAreInSync = urlQueryString === paramsQueryString || wearCategoryExcluded;

    const validQueryParams = validURLParamsForExtendedData(searchInURL, filterConfig);
    const subCategoriesQueryParams =
      (searchInURL.pub_subCategories && { pub_subCategories: searchInURL.pub_subCategories }) || {};
    const isWindowDefined = typeof window !== 'undefined';
    const isMobileLayout = isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;
    const shouldShowSearchMap =
      (isMobileLayout && this.state.isSearchMapOpenOnMobile) ||
      (location.state && location.state.openMap && this.state.isSearchMapOpenOnMobile === null);

    const onMapIconClick = () => {
      this.useLocationSearchBounds = true;
      this.setState({ isSearchMapOpenOnMobile: true });

      this.changeView();
    };

    const defaultBounds = isMobileLayout
      ? [new LatLng(56.1543591, 2.3823084), new LatLng(48.14742239, -4.98025716)]
      : [new LatLng(54.23424235, 4.27616152), new LatLng(50.54036727, -6.22776534)];

    const { address, bounds = new LatLngBounds(...defaultBounds), origin } = searchInURL || {};
    const { title, description, schema } = createSearchResultSchema(listings, address, intl);

    // Set topbar class based on if a modal is open in
    // a child component
    const topbarClasses = this.state.isMobileModalOpen
      ? classNames(css.topbarBehindModal, css.topbar)
      : css.topbar;

    // N.B. openMobileMap button is sticky.
    // For some reason, stickyness doesn't work on Safari, if the element is <button>
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        description={description}
        title={title}
        schema={schema}
      >
        <TopbarContainer
          className={topbarClasses}
          currentPage="SearchPage"
          currentSearchParams={urlQueryParams}
          onShowMap={onMapIconClick}
        >
          <div className={css.rowEnd}>
            <div className={css.buttonContainer}>
              <p className={css.mapIconText}>
                <FormattedMessage id="SearchFilters.hideSoldItems" />
              </p>
              <div className={css.mapIcon}>
                <Toggle
                  defaultChecked={this.state.hideSold}
                  onChange={() => {
                    this.hideSoldItems();
                  }}
                />
              </div>
            </div>
            <div className={css.buttonContainer}>
              <p className={css.mapIconText}>
                <FormattedMessage id="SearchFilters.openMapView" />
              </p>
              <div className={css.mapIcon}>
                <Toggle
                  defaultChecked={this.state.showMap}
                  onChange={() => {
                    this.changeView();
                    onMapIconClick();
                  }}
                />
              </div>
            </div>
          </div>
        </TopbarContainer>
        <div className={css.container}>

          <MainPanel

            onMapToggleClick={onMapIconClick}
            mapIsShown={this.state.showMap}

            urlQueryParams={{ ...validQueryParams, ...subCategoriesQueryParams }}
            listings={listings}
            searchInProgress={searchInProgress}
            searchListingsError={searchListingsError}
            searchParamsAreInSync={searchParamsAreInSync}
            onActivateListing={onActivateListing}
            onManageDisableScrolling={onManageDisableScrolling}
            onOpenModal={this.onOpenMobileModal}
            onCloseModal={this.onCloseMobileModal}
            onMapIconClick={onMapIconClick}
            pagination={pagination}
            searchParamsForPagination={parse(location.search)}
            history={history}
            showAsModalMaxWidth={MODAL_BREAKPOINT}
            redirectToURLWithoutModalState={this.redirectToURLWithoutModalState}
            onResultButtonClick={this.onResultButtonClick}
            getCurrentRef={this.getCurrentRef}
            loadData={this.loadDataSearch}
            onSetShownProdCount={onSetShownProdCount}
            onResetShownProdCount={onResetShownProdCount}
            shownProdCount={shownProdCount}
            showMap={showMap}
            isMobileLayout={isMobileLayout}
            hideSoldItems={this.hideSoldItems}
            hideSold={this.state.hideSold}
            currentUser={currentUser}
            pressFavorite={this.pressFavorite}
            isPressFavorite={this.state.isPressFavorite}
          >
            <SearchMap
              reusableContainerClassName={css.map}
              activeListingId={activeListingId}
              bounds={bounds}
              center={origin}
              isSearchMapOpenOnMobile={this.state.isSearchMapOpenOnMobile}
              location={location}
              listings={mapListings || []}
              onMapMoveEnd={this.onMapMoveEnd}
              onCloseAsModal={() => {
                onManageDisableScrolling('SearchPage.map', false);
              }}
              messages={intl.messages}
            />
          </MainPanel>
          <ModalInMobile
            className={css.mapPanel}
            id="SearchPage.map"
            isModalOpenOnMobile={shouldShowSearchMap}
            onClose={() => {
              this.setState({ isSearchMapOpenOnMobile: false });
              this.changeView();
            }}
            showAsModalMaxWidth={MODAL_BREAKPOINT}
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <div className={css.mapWrapper}>
              {shouldShowSearchMap ? (
                <SearchMap
                  reusableContainerClassName={css.map}
                  activeListingId={activeListingId}
                  bounds={bounds}
                  center={origin}
                  isSearchMapOpenOnMobile={shouldShowSearchMap}
                  location={location}
                  listings={mapListings || []}
                  onMapMoveEnd={this.onMapMoveEnd}
                  onCloseAsModal={() => {
                    onManageDisableScrolling('SearchPage.map', false);
                  }}
                  messages={intl.messages}
                />
              ) : null}
            </div>
          </ModalInMobile>
        </div>
      </Page>
    );
    /* eslint-enable jsx-a11y/no-static-element-interactions */
  }
}

SearchPageComponent.defaultProps = {
  listings: [],
  mapListings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  tab: 'listings',
  filterConfig: config.custom.filters,
  sortConfig: config.custom.sortConfig,
  activeListingId: null,
};

SearchPageComponent.propTypes = {
  listings: array,
  mapListings: array,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onSearchMapListings: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,
  tab: oneOf(['filters', 'listings', 'map']).isRequired,
  filterConfig: propTypes.filterConfig,
  sortConfig: propTypes.sortConfig,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
    searchMapListingIds,
    activeListingId,
    shownProdCount,
  } = state.SearchPage;
  const pageListings = getListingsById(state, currentPageResultIds);
  const mapListings = getListingsById(
    state,
    unionWith(currentPageResultIds, searchMapListingIds, (id1, id2) => id1.uuid === id2.uuid)
  );
  const { currentUser } = state.user;

  return {
    listings: pageListings,
    mapListings,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
    shownProdCount,
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onSearchMapListings: searchParams => dispatch(searchMapListings(searchParams)),
  onActivateListing: listingId => dispatch(setActiveListing(listingId)),
  onLoadSearch: search => dispatch(searchListings(search)),
  onSetShownProdCount: () => dispatch(setShownProdCount()),
  onResetShownProdCount: () => dispatch(reSetShownProdCount()),
  onUpdateFavorites: data => dispatch(updateFavorites(data)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SearchPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(SearchPageComponent);

SearchPage.loadData = (params, search) => {
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  const { page = 1, address, origin, ...rest } = queryParams;
  const originMaybe = config.sortSearchByDistance && origin ? { origin } : {};

  const data = {
    ...rest,
    ...originMaybe,
    page,
    perPage: RESULT_PAGE_SIZE,
    include: ['author', 'images'],
    'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    // 'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    'fields.image': ['variants.scaled-medium'],
    'limit.images': 1,
  };

  if (queryParams.pub_subCategories) {
    const newOptions = queryParams.pub_subCategories && queryParams.pub_subCategories.split(',');
    if (newOptions && newOptions.includes('wear-maternity')) {
      const wearCategories = newOptions.filter(item => item.includes('wear-maternity'));
      if (wearCategories.length > 1) {
        const index = newOptions.findIndex(item => item === 'wear-maternity');
        newOptions.splice(index, 1);
        data.pub_subCategories = newOptions.join(',');
      }
    }
  }

  return searchListings(data);
};

export default SearchPage;
