import React from 'react';
import { string, array } from 'prop-types';
import classNames from 'classnames';
import { NamedLink } from '../../components/';

import css from './SectionBrandCloud.css';

const SectionBrandCloud = props => {
  const { 
    rootClassName, 
    className, 
    title,
    copy,
    brands
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  let brandList;

  if(!!brands) {

     brandList = brands.map((brand) => {
      let keyword = brand.keyword ? brand.keyword : brand.name;
      return(
        <li>
          <NamedLink
              name="SearchPage"
              to={{ search: `&sort=createdAt&hideSold=true&keywords=${keyword}&page=1` }}
              className={css.pill}>
            {brand.name}
          </NamedLink>
        </li>
      )
    })

  return (
    <div className={classes}>
      <div className={css.container}>
        <h2 className={css.title}> { title } </h2>
        { copy &&
          <div dangerouslySetInnerHTML={{ __html: copy }}></div>
        }
        { !!brands &&
          <ul className={css.cloud}>
            { brandList}
          </ul>

        }

         
         
      </div>
    </div>
  )};
};

SectionBrandCloud.defaultProps = { 
  rootClassName: null, 
  className: null,
  title: null,
  copy: null,
  brands: null
 };

SectionBrandCloud.propTypes = {
  rootClassName: string,
  className: string,
  title: string,
  copy: string,
  brands: array
};

export default SectionBrandCloud;
