import ReactGA from "react-ga4";

export class LoggingAnalyticsHandler {
  trackPageView(url) {
  }
}

//tweak the code for GA4 setup - not sure why this isn't using ReactGA tho - it seems it never was but
// some other parts of the impl are (including server side) ?
export class GoogleAnalyticsHandler {
  constructor(gtag) {
    if (typeof gtag !== 'function') {
      throw new Error('Variable `gtag` missing for Google Analytics');
    }
    this.gtag = gtag;
  }

  trackPageView(url) {
    //https://developers.google.com/analytics/devguides/collection/ga4/views?client_type=gtag#manually_send_page_view_events
    if (this.gtag) {
      this.gtag('event', 'page_view', {
        page_location: url,
      }
      )
    }
  }
}

export class FacebookPixel {
  constructor() {
    (function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

    window.fbq('init', `${process.env.REACT_APP_FACEBOOKPIXEL_ID}`);
  }

  trackPageView(url) {
    if (url.includes('/signup')) {
      window.fbq('track', 'PageView');
      window.fbq('track', 'View Content');
      window.fbq('track', 'Signup page');
    } else if (url === '/l/new') {
      // DO NOTHING, CANT WORKOUT WHY THE EXISTING ROUTING DOES THIS
    } else if (url.includes('/new/description')) {
      window.fbq('track', 'PageView');
      window.fbq('track', 'View Content');
      window.fbq('track', 'Start Listing');
    } else if (url.includes('/checkout')) {
      // window.fbq('track', 'PageView');
      window.fbq('track', 'InitiateCheckout');
    } else if (url.includes('/order/')) {
      window.fbq('track', 'PageView');
      window.fbq('track', 'Purchase');
    } else if (url.includes('/l/')) {
      window.fbq('track', 'PageView');
      window.fbq('track', 'View Content');
    } else {
      window.fbq('track', 'PageView');
    }
  }
}
