import React from 'react';
import { string, array, image } from 'prop-types';
import classNames from 'classnames';

import { CtaCard } from '../../components/';

import css from './SectionCtaRail.css';

const SectionCtaRail = props => {
  const { 
    rootClassName, 
    className, 
    content
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  const contentBoxes = content.items.map((item, index) => {
    return(
      <CtaCard 
        title={item.title}
        copy={item.copy}
        namedLink={item.namedLink}
        namedLinkSlug={item.namedLinkSlug}
        namedLinkTo={item.namedLinkTo}
        linkLabel={item.linkLabel}
        color={item.color}
      />
    )
  })
  

  return (
    <div className={classes}>
      <div className={css.content}>
       {contentBoxes}
     </div>
    </div>
  );
};

SectionCtaRail.defaultProps = { 
  rootClassName: null, 
  className: null,
  title: null,
  content: null
 };

SectionCtaRail.propTypes = {
  rootClassName: string,
  className: string,
  content: array
};

export default SectionCtaRail;
