import React, { Component } from 'react';
import { array, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, Field, useField } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { nonEmptyArray, composeValidators } from '../../util/validators';
import { isUploadImageOverLimitError } from '../../util/errors';
import { AddImages, Button, Form, ValidationError } from '../../components';
import Resizer from 'react-image-file-resizer';

import css from './EditListingPhotosForm.css';

const ACCEPT_IMAGES = '.png, .jpg, .gif, .webp';

const loadImagedDimension = async file => {
  return new Promise((res, rej) => {
    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      res({ width: img.width, height: img.height });
    };
  });
};

const rotateImage = async file => {
  return new Promise(async (res, rej) => {
    const { width, height } = await loadImagedDimension(file);
    const maxWidth = width;
    const maxHeight = height;
    const compressFormat = 'JPEG';
    const quality = 100;
    const rotation = 90;
    const outputType = 'blob';

    const blobToFile = blob => {
      return new File([blob], file.name, { type: 'image/jpeg' });
    };

    const responseUriFunc = uri => {
      const newFile = blobToFile(uri);
      res(newFile);
    };

    Resizer.imageFileResizer(
      file, //is the file of the new image that can now be uploaded...
      maxWidth, // is the maxWidth of the  new image
      maxHeight, // is the maxHeight of the  new image
      compressFormat, // is the compressFormat of the  new image
      quality, // is the quality of the new image
      rotation, // is the degree of clockwise rotation to apply to the image.
      responseUriFunc, // is the callBack function of the new image URI
      outputType // is the output type of the new image
    );
  });
};

const resizeImage = async file => {
  return new Promise(async (res, rej) => {
    const maxWidth = 2000;
    const maxHeight = 2000;
    const compressFormat = 'JPEG';
    const quality = 100;
    const rotation = 0;
    const outputType = 'blob';

    const blobToFile = blob => {
      return new File([blob], file.name, { type: 'image/jpeg' });
    };

    const responseUriFunc = uri => {
      const newFile = blobToFile(uri);
      res(newFile);
    };

    Resizer.imageFileResizer(
      file, //is the file of the new image that can now be uploaded...
      maxWidth, // is the maxWidth of the  new image
      maxHeight, // is the maxHeight of the  new image
      compressFormat, // is the compressFormat of the  new image
      quality, // is the quality of the new image
      rotation, // is the degree of clockwise rotation to apply to the image.
      responseUriFunc, // is the callBack function of the new image URI
      outputType // is the output type of the new image
    );
  });
};

export class EditListingPhotosFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUploadRequested: false,
      imagesOrder: this.props.images.map(v => v.id),
    };
    this.onImageUploadHandler = this.onImageUploadHandler.bind(this);
    this.onRotateImage = this.onRotateImage.bind(this);
    this.onResizeImage = this.onResizeImage.bind(this);
    this.onUpdateImageOrder = this.onUpdateImageOrder.bind(this);
    this.submittedImages = [];
  }

  async onImageUploadHandler(file, id) {
    if (file) {
      this.setState({ imageUploadRequested: true });
      this.props
        .onImageUpload({ id: id ? id : `${file.name}_${Date.now()}`, file })
        .then(() => {
          this.setState({ imageUploadRequested: false });
        })
        .catch(() => {
          this.setState({ imageUploadRequested: false });
        });
    }
  }

  async onRotateImage(image) {
    const rotateImageFile = await rotateImage(image.file);
    this.onImageUploadHandler(rotateImageFile, image.id);
  }

  async onResizeImage(file, id) {
    const resizeImageFile = await resizeImage(file);
    this.onImageUploadHandler(resizeImageFile, id);
  }

  async onUpdateImageOrder(newImages) {
    this.setState({
      imagesOrder: newImages.map(v => {
        // console.log('onUpdateImageOrder', v);
        return v.id.uuid || v.id;
      }),
    });
  }

  render() {
    return (
      <>
        <FinalForm
          {...this.props}
          onImageUploadHandler={this.onImageUploadHandler}
          onResizeImage={this.onResizeImage}
          imageUploadRequested={this.state.imageUploadRequested}
          initialValues={{ images: this.props.images }}
          // onUpdateImageOrder={{ images: this.props.images }}
          mutators={{
            setValue: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value);
            },
          }}
          render={fieldRenderProps => {
            const {
              form,
              className,
              fetchErrors,
              handleSubmit,
              images,
              imageUploadRequested,
              intl,
              invalid,
              onImageUploadHandler,
              onResizeImage,
              onRemoveImage,
              disabled,
              ready,
              saveActionMsg,
              updated,
              updateInProgress,
            } = fieldRenderProps;

            images.map((v, i) => {
              v.order =
                this.state.imagesOrder.indexOf(v.id) != -1
                  ? this.state.imagesOrder.indexOf(v.id)
                  : this.state.imagesOrder.indexOf(v.id.uuid);
            });

            const orderedImages = images.sort((a, b) => {
              return a.order < b.order ? -1 : 1;
            });

            // console.log('orderedImages', orderedImages);

            const chooseImageText = (
              <span className={css.chooseImageText}>
                <span className={css.chooseImage}>
                  <FormattedMessage id="EditListingPhotosForm.chooseImage" />
                </span>
                <span className={css.imageTypes}>
                  <FormattedMessage id="EditListingPhotosForm.imageTypes" />
                </span>
              </span>
            );

            const imageRequiredMessage = intl.formatMessage({
              id: 'EditListingPhotosForm.imageRequired',
            });

            const {
              publishListingError,
              showListingsError,
              updateListingError,
              uploadImageError,
            } = fetchErrors || {};
            const uploadOverLimit = isUploadImageOverLimitError(
              uploadImageError
            );

            let uploadImageFailed = null;

            if (uploadOverLimit) {
              uploadImageFailed = (
                <p className={css.error}>
                  <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadOverLimit" />
                </p>
              );
            } else if (uploadImageError) {
              uploadImageFailed = (
                <p className={css.error}>
                  <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadFailed" />
                </p>
              );
            }

            // NOTE: These error messages are here since Photos panel is the last visible panel
            // before creating a new listing. If that order is changed, these should be changed too.
            // Create and show listing errors are shown above submit button
            const publishListingFailed = publishListingError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPhotosForm.publishListingFailed" />
              </p>
            ) : null;
            const showListingFailed = showListingsError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPhotosForm.showListingFailed" />
              </p>
            ) : null;

            const submittedOnce = this.submittedImages.length > 0;
            // imgs can contain added images (with temp ids) and submitted images with uniq ids.
            const arrayOfImgIds = imgs =>
              imgs.map(i => (typeof i.id === 'string' ? i.imageId : i.id));
            const imageIdsFromProps = arrayOfImgIds(images);
            const imageIdsFromPreviousSubmit = arrayOfImgIds(
              this.submittedImages
            );
            const imageArrayHasSameImages = isEqual(
              imageIdsFromProps,
              imageIdsFromPreviousSubmit
            );
            const pristineSinceLastSubmit =
              submittedOnce && imageArrayHasSameImages;

            const submitReady = (updated && pristineSinceLastSubmit) || ready;
            const submitInProgress = updateInProgress;
            const submitDisabled =
              invalid ||
              disabled ||
              submitInProgress ||
              imageUploadRequested ||
              ready;

            const classes = classNames(css.root, className);

            return (
              <Form
                className={classes}
                onSubmit={e => {
                  this.submittedImages = images;

                  form.mutators.setValue('images', orderedImages);

                  handleSubmit(e);
                }}
              >
                {updateListingError ? (
                  <p className={css.error}>
                    <FormattedMessage id="EditListingPhotosForm.updateFailed" />
                  </p>
                ) : null}

                {/* <p className={css.note}>
                  <FormattedMessage id="EditListingPhotosForm.note1" /> <br />
                  <FormattedMessage id="EditListingPhotosForm.note2" /> <br />
                  <FormattedMessage id="EditListingPhotosForm.note3" />
                </p> */}

                {images && images.length > 1 && (
                  <p className={css.note}>Click and drag to rearrange</p>
                )}

                <AddImages
                  classNameWrap={css.imagesFieldWrap}
                  className={css.imagesField}
                  images={orderedImages}
                  thumbnailClassName={css.thumbnail}
                  savedImageAltText={intl.formatMessage({
                    id: 'EditListingPhotosForm.savedImageAltText',
                  })}
                  onRemoveImage={onRemoveImage}
                  onRotateImage={this.onRotateImage}
                  onUpdateImageOrder={this.onUpdateImageOrder}
                >
                  {images && images.length < 10 && (
                    <Field
                      id="addImage"
                      name="addImage"
                      accept={ACCEPT_IMAGES}
                      form={null}
                      label={chooseImageText}
                      type="file"
                      disabled={imageUploadRequested}
                    >
                      {fieldprops => {
                        const {
                          accept,
                          input,
                          label,
                          disabled: fieldDisabled,
                        } = fieldprops;
                        const { name, type } = input;
                        const onChange = e => {
                          const files = e.target.files;
                          form.change(`addImage`, files);
                          form.blur(`addImage`);
                          for (let i = 0; i < files.length; i++) {
                            onResizeImage(files[i]);
                            //onImageUploadHandler(files[i]);
                          }
                        };
                        const inputProps = {
                          accept,
                          id: name,
                          name,
                          onChange,
                          type,
                          multiple: true,
                        };
                        return (
                          <div className={css.addImageWrapper}>
                            <div className={css.aspectRatioWrapper}>
                              {fieldDisabled ? null : (
                                <input
                                  {...inputProps}
                                  className={css.addImageInput}
                                />
                              )}
                              <label htmlFor={name} className={css.addImage}>
                                {label}
                              </label>
                            </div>
                          </div>
                        );
                      }}
                    </Field>
                  )}

                  <Field
                    component={props => {
                      const { input, meta } = props;
                      return (
                        <div className={css.imageRequiredWrapper}>
                          <input {...input} />
                          <ValidationError fieldMeta={meta} />
                        </div>
                      );
                    }}
                    name="images"
                    type="hidden"
                    validate={composeValidators(
                      nonEmptyArray(imageRequiredMessage)
                    )}
                  />
                </AddImages>

                {uploadImageFailed}

                {/* <p className={css.tip}>
                  Note: Having good photos is essential in making your item
                  stand out and have a good chance of selling well and fast.
                  Take a look at our{' '}
                  <a href="/the-octopus-club-guide" target="_blank">
                    How to take good photos guide
                  </a>{' '}
                  for some quick and easy tips.
                </p> */}

                {publishListingFailed}
                {showListingFailed}

                <Button
                  className={css.submitButton}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  ready={submitReady}
                >
                  {saveActionMsg}
                </Button>
              </Form>
            );
          }}
        />
      </>
    );
  }
}

EditListingPhotosFormComponent.defaultProps = { fetchErrors: null, images: [] };

EditListingPhotosFormComponent.propTypes = {
  fetchErrors: shape({
    publishListingError: propTypes.error,
    showListingsError: propTypes.error,
    uploadImageError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  images: array,
  intl: intlShape.isRequired,
  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  onRemoveImage: func.isRequired,
};

export default compose(injectIntl)(EditListingPhotosFormComponent);
