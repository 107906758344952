import React from 'react';
import {Page, SectionInstagram, SectionSteps, SectionTestimonial, SectionText} from "../../components";
import css from "../AboutPage/AboutPage.css";
import theOctopusClubDifference from "../../content/about-the-octopus-club-difference.json";
import didYouKNowContent from "../../content/about-did-you-know.json";
import joinContent from "../../content/about-join-for-free.json";
import testimonial from "../../content/about-testimonial.json";
import instagramContent from "../../content/instagram.json";

const OurStoryMain = () => {

  return <>
    <SectionText
        title={theOctopusClubDifference.title}
        copy={theOctopusClubDifference.copy}
        namedLink={theOctopusClubDifference.namedLink}
        linkLabel={theOctopusClubDifference.linkLabel}
        color={theOctopusClubDifference.color}
        contrast={theOctopusClubDifference.contrast}
    />

    <SectionText
        title={didYouKNowContent.title}
        copy={didYouKNowContent.copy}
        image={didYouKNowContent.image}
        logo={didYouKNowContent.logo}
        logoWidth={didYouKNowContent.logoWidth}
        logoHeight={didYouKNowContent.logoHeight}
    />


    <SectionSteps
        title={joinContent.title}
        copy={joinContent.copy}
        namedLink={joinContent.namedLink}
        namedLinkSlug={joinContent.namedLinkSlug}
        linkLabel={joinContent.linkLabel}
        steps={joinContent.steps}
        color={joinContent.color}
        contrast={joinContent.contrast}
    />

    <SectionTestimonial
        quote={testimonial.quote}
        author={testimonial.author}
        color={testimonial.color}
        contrast={testimonial.contrast}
    />

    <SectionInstagram
        copy={instagramContent.copy}
        linkLabel={instagramContent.linkLabel}
        images={instagramContent.images}
    />
  </>

}

export default OurStoryMain;