import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Button, FieldCheckboxGroup, Form } from '../../components';

import css from './EditListingFeaturesForm.css';
import {subCategories} from "../../marketplace-custom-config";

const EditListingFeaturesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={fieldRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        name,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        form,
        filterConfig,
      } = fieldRenderProps;

      const { category } = values;

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = !values.category || submitInProgress;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.showListingFailed" />
        </p>
      ) : null;

      const agesOptions = config.custom.ages.filter(item => {
        if (
          category &&
          category.includes('wear') &&
          (category.includes('move') || category.includes('play'))
        ) {
          return true;
        }

        if (category && category.includes('wear')) {
          return item.key.includes('month') || item.wear == true;
        } else {
          return item.wear != true;
        }
      });

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          <FieldCheckboxGroup
            className={css.features}
            id="category"
            name="category"
            options={config.custom.categories.filter(i => i.key != 'everything' && i.key != 'age')}
            maxNumber={2}
          />

          <h3 className={css.title}>Which subcategories does your item belong to?</h3>

          {category && category.length
            ? category.map(c => {
                return (
                  <div key={c}>
                    {config.custom.filterSubCategories(c)[0]['subCategories'].map((subCategory, index) => {
                      return (
                        <FieldCheckboxGroup
                          key={index}
                          className={css.features}
                          id={subCategory.name}
                          name={subCategory.name}
                          options={subCategory.items}
                        />
                      );
                    })}
                  </div>
                );
              })
            : null}

          {category &&
            category.length > 0 &&
            category.some(c => config.custom.filterSubCategories(c)[0].ages) && (
              <>
                <h3 className={css.title}>Which size is your item?</h3>
                <h4 className={css.categoryGroupLabel}>Baby & kids clothing:</h4>
                <FieldCheckboxGroup
                  className={css.features}
                  id="ages"
                  name="ages"
                  options={agesOptions}
                />
              </>
            )}

          {category && category.includes('wear') && (
            <>
              <div>
                <h4 className={css.categoryGroupLabel}>Footwear:</h4>
                <FieldCheckboxGroup
                  className={css.features}
                  id="ages"
                  name="ages"
                  options={config.custom.shoeSizes}
                />
              </div>

              <div>
                <h4 className={css.categoryGroupLabel}>Adult clothing:</h4>
                <FieldCheckboxGroup
                  className={css.features}
                  id="ages"
                  name="ages"
                  options={config.custom.maternitySizes}
                />
              </div>
            </>
          )}

          {/* {values.category && (
            <FieldCheckboxGroup
              className={css.features}
              id={name}
              name={name}
              options={config.custom.filterAmenities(values.category)[0]['amenities'][0]}
            />
          )}

          {(values.category && values.amenities && values.category === 'play') ||
          values.category === 'wear' ? (
            <FieldCheckboxGroup
              className={css.features}
              id={name}
              name={name}
              options={config.custom.filterAmenities(values.category)[0]['amenities'][1]}
            />
          ) : null} */}

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingFeaturesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingFeaturesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

const EditListingFeaturesForm = EditListingFeaturesFormComponent;

export default EditListingFeaturesForm;
