/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_PENDING_APPROVAL, LISTING_STATE_CLOSED, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { formatMoney } from '../../util/currency';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import {
  Page,
  NamedLink,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  SearchCategories,
  Heart,
  Modal,
} from '../../components';
import { TopbarContainer, NotFoundPage } from '../../containers';
import Toggle from 'react-toggle';
import classNames from 'classnames';

import {
  sendEnquiry,
  loadData,
  setInitialValues,
  fetchTransactionLineItems,
  updateFavorites, loadLatestListingData,
} from './ListingPage.duck';
import SectionImages from './SectionImages';
import SectionHeading from './SectionHeading';
import SectionConditionMaybe from './SectionConditionMaybe';
import SectionDescriptionMaybe from './SectionDescriptionMaybe';
import SectionFeaturesMaybe from './SectionFeaturesMaybe';
import SectionHostMaybe from './SectionHostMaybe';
import SectionRelatedProducts from './SectionRelatedProducts';
import SectionMapMaybe from './SectionMapMaybe';
import css from './ListingPage.css';
import { initialUpdateUser } from '../../ducks/user.duck';

import moment from 'moment';
import {subCategories} from "../../marketplace-custom-config";

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID, Money } = sdkTypes;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return {
      formattedPrice: price.amount > 0 ? formattedPrice : 'Free',
      priceTitle: formattedPrice,
    };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const categoryLabel = (categories, key) => {
  const cat = categories.find(c => c.key === key);
  return cat ? cat.label : key;
};

export class ListingPageComponent extends Component {
  constructor(props) {
    super(props);
    const { enquiryModalOpenForListingId, params } = props;
    this.state = {
      pageClassNames: [],
      imageCarouselOpen: false,
      enquiryModalOpen: enquiryModalOpenForListingId === params.id,
      showIndexImage: 0,
      selectedCollectionOption: 'postage',

      // should this be combined?
      showMap: false,
      mapVisibility : false,
      ////
      isPressFavorite: null,
      soldModalOpen: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onContactUser = this.onContactUser.bind(this);
    this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);
    this.onChangeImage = this.onChangeImage.bind(this);
    this.filterListing = this.filterListing.bind(this);
    this.onCollectionOptionChange = this.onCollectionOptionChange.bind(this);
    this.handlePutOnSale = this.handlePutOnSale.bind(this);
  }

  onCollectionOptionChange(e) {
    if (e && e.target && e.target.value) {
      this.setState({
        selectedCollectionOption: e.target.value,
      });
    }
  }

  handleSubmit(values) {
    const {
      history,
      getListing,
      params,
      callSetInitialValues,
      onInitializeCardPaymentData,
      onLoadLatestListingData,
    } = this.props;
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);

    // load listing again incase it has changed and actually been purchased since page was loaded
    onLoadLatestListingData(listing.id.uuid).then((latestListing) => {
      if (latestListing.attributes.publicData.sold) {
        this.setState({ soldModalOpen: true })
      } else {
        const collectionType = listing.attributes.publicData.collection;

        /* const { bookingDates, ...bookingData } = values; */
        const bookingDates = {
          startDate: moment().toDate(),
          endDate: moment()
            .add(1, 'days')
            .toDate(),
        };
        /* TODO remove quantity hardcoded */
        const bookingData = { quantity: 1 };
        const initialValues = {
          listing,
          bookingData,
          bookingDates: {
            bookingStart: bookingDates.startDate,
            bookingEnd: bookingDates.endDate,
          },
          confirmPaymentError: null,
          collectionOption:
            collectionType !== 'collection-and-postage'
              ? collectionType
              : this.state.selectedCollectionOption,
        };

        const routes = routeConfiguration();
        // Customize checkout page state with current listing and selected bookingDates
        const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);
        // console.log('initialValues', initialValues);
        callSetInitialValues(setInitialValues, initialValues);

        // Clear previous Stripe errors from store if there is any
        onInitializeCardPaymentData();

        // Redirect to CheckoutPage
        history.push(
          createResourceLocatorString(
            'CheckoutPage',
            routes,
            { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
            {}
          )
        );
      }
    }).catch((e) => {

    });
  }

  handlePutOnSale() {
    const { history, getListing, params } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);
    // Redirect to CheckoutPage
    history.push(
      `${createResourceLocatorString(
        'EditListingPage',
        routes,
        {
          id: listing.id.uuid,
          slug: createSlug(listing.attributes.title),
          type: 'edit',
          tab: 'pricing',
        },
        {}
      )}?put_on_sale=true`
    );
  }

  filterListing(searchParams) {
    this.props.history.push(
      createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams)
    );
  }

  onContactUser() {
    const { currentUser, history, callSetInitialValues, params, location } = this.props;

    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, { enquiryModalOpenForListingId: params.id });

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      this.setState({ enquiryModalOpen: true });
    }
  }

  pressFavorite(isFavorite, id) {
    const { currentUser, onUpdateFavorites } = this.props;
    const userData = currentUser;
    const { publicData } = userData.attributes.profile;
    const { favorites } = publicData || {};
    this.setState({ isPressFavorite: !isFavorite });
    if (!isFavorite) {
      const data = {
        ...publicData,
        favorites: favorites ? [...favorites, id.uuid] : [id.uuid],
      };

      onUpdateFavorites(data);
    } else {
      const data = {
        ...publicData,
        favorites: favorites.filter(item => item !== id.uuid),
      };

      onUpdateFavorites(data);
    }
  }

  onChangeImage(type, length) {
    let index;

    if (
      (length - 1 === this.state.showIndexImage && type === 'next') ||
      (this.state.showIndexImage === 0 && type === 'back')
    ) {
      index = type === 'next' ? 0 : length - 1;
    } else {
      index = type === 'next' ? (this.state.showIndexImage += 1) : (this.state.showIndexImage -= 1);

      if (length < index) {
        index = 1;
      }
    }

    this.setState({ showIndexImage: index });
  }

  onSubmitEnquiry(values) {
    const { history, params, onSendEnquiry, getOwnListing, getListing } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const isPendingApprovalVariant = params.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = params.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));
    const { message } = values;

    const { publicData, price } = currentListing.attributes;
    const productType = publicData && publicData.type;

    const isGive = !price || (price && price.amount === 0) || productType === 'give';

    onSendEnquiry(listingId, message.trim(), isGive)
      .then(txId => {
        this.setState({ enquiryModalOpen: false });

        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }

  render() {
    const {
      isAuthenticated,
      currentUser,
      getListing,
      getOwnListing,
      intl,
      onManageDisableScrolling,
      params: rawParams,
      location,
      scrollingDisabled,
      showListingError,
      sendEnquiryInProgress,
      sendEnquiryError,
      categoriesConfig,
      subCategoriesConfig,
      timeSlots,
      fetchTimeSlotsError,
      filterConfig,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      history,
      salePending,
      salePendingRequest,
    } = this.props;

    const listingId = new UUID(rawParams.id);
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));

    const {
      description = '',
      geolocation = null,
      price = null,
      title = '',
      publicData,
    } = currentListing.attributes;

    const productType = publicData && publicData.type;
    const collectionInPerson = publicData && publicData.collectionInPerson;
    const collection = publicData && publicData.collection;
    const sale = publicData && publicData.sale;
    const oldPrice = publicData && publicData.oldPrice;

    const isGive = !price || (price && price.amount === 0) || productType === 'give';

    const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
    const params = { slug: listingSlug, ...rawParams };

    const listingType = isDraftVariant
      ? LISTING_PAGE_PARAM_TYPE_DRAFT
      : LISTING_PAGE_PARAM_TYPE_EDIT;
    const listingTab = isDraftVariant ? 'photos' : 'description';

    const isApproved =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    const isFavorite =
      currentUser &&
      currentListing.id &&
      currentUser.attributes.profile.publicData &&
      currentUser.attributes.profile.publicData.favorites &&
      currentUser.attributes.profile.publicData.favorites.includes(currentListing.id.uuid);

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
    }

    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
          longWordClass: css.longWord,
        })}
      </span>
    );

    //const [ mapVisibility, setMapVisibility] = useState(false);

    const toggleMap = () => {

      this.setState({
        mapVisibility : !this.state.mapVisibility
      })

    }

    const topbar = (
      <TopbarContainer>
        <div className={css.buttonContainer}>
          <p className={css.mapIconText}>
            <FormattedMessage id="SearchFilters.openMapView" />
          </p>
          <div className={css.mapIcon}>
            <Toggle
              defaultChecked={this.state.showMap}
              onChange={() => {
                this.setState({ showMap: !this.state.showMap });
              }}
            />
          </div>
        </div>
      </TopbarContainer>
    );

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found

      return <NotFoundPage />;
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });

      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>
                <FormattedMessage id="ListingPage.loadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    const handleViewPhotosClick = e => {
      // Stop event from bubbling up to prevent image click handler
      // trying to open the carousel as well.
      e.stopPropagation();
      this.setState({
        imageCarouselOpen: true,
      });
    };
    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
    const showContactUser = authorAvailable && (!currentUser || (currentUser && !isOwnListing));

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

    const { formattedPrice, priceTitle } =
      price && price.amount
        ? priceData(price, intl)
        : priceData(new Money(0, config.currency), intl);
    const formattedOldPrice =
      sale && oldPrice && priceData(new Money(oldPrice.amount, config.currency), intl);

    let postagePriceFormat;

    if (publicData && publicData.postagePrice !== 'undefined') {
      const money = new Money(publicData.postagePrice, price.currency);
      const { priceTitle } = priceData(money, intl);
      postagePriceFormat = priceTitle;
    }

    const handleBookingSubmit = values => {
      const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
      if (isOwnListing || isCurrentlyClosed) {
        window.scrollTo(0, 0);
      } else {
        this.handleSubmit(values);
      }
    };

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map(image => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find(i => i.name === variantName) : null;

          return variant || size;
        })
        .filter(variant => variant != null);

    const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = listingImages(currentListing, 'twitter');
    const schemaImages = JSON.stringify(facebookImages.map(img => img.url));
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage(
      { id: 'ListingPage.schemaTitle' },
      { title, price: formattedPrice, siteTitle }
    );

    const hostLink = (
      <NamedLink
        className={css.authorNameLink}
        name="ListingPage"
        params={params}
        to={{ hash: '#host' }}
      >
        {authorDisplayName}
      </NamedLink>
    );

    const categoryOptions = findOptionsForSelectFilter('category', filterConfig);
    const category =
      publicData && publicData.category ? (
        <span>
          {categoryLabel(categoryOptions, publicData.category)}
          <span className={css.separator}>•</span>
        </span>
      ) : null;

    const heartComponent = currentUser ? (
      <Heart
        className={css.heart}
        isSelected={this.state.isPressFavorite !== null ? this.state.isPressFavorite : isFavorite}
        onClick={() => this.pressFavorite(isFavorite, currentListing.id)}
      />
    ) : null;

    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        contentType="website"
        description={description}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'ItemPage',
          description: description,
          name: schemaTitle,
          image: schemaImages,
        }}
      >
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <Modal
              id="ItemSoldModal"
              isOpen={this.state.soldModalOpen}
              onClose={() => {
                this.setState({ soldModalOpen: !this.state.soldModalOpen });
              }}
              usePortal
              contentClassName={css.enquiryModalContent}
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <div>
                <div className={css.modalTitle}></div>

                <>
                  <p className={css.modalMessage}>Sorry, that item has now been sold or given. Please have a look at similar items below or <a class="" href="/contact-us">contact us</a> and we can try to find what you are looking for!</p>
                </>
              </div>
            </Modal>

            <div className={css.listingContainer}>

              <SectionImages
                  title={title}
                  listing={currentListing}
                  isOwnListing={isOwnListing}
                  heart={heartComponent}
                  editParams={{
                    id: listingId.uuid,
                    slug: listingSlug,
                    type: listingType,
                    tab: listingTab,
                  }}
                  imageCarouselOpen={this.state.imageCarouselOpen}
                  onImageCarouselClose={() => this.setState({ imageCarouselOpen: false })}
                  handleViewPhotosClick={handleViewPhotosClick}
                  onManageDisableScrolling={onManageDisableScrolling}
                  nextImage={this.onChangeImage}
                  indexImage={this.state.showIndexImage}
                />

                <div className={css.mainContent}>

                      <SectionHeading
                        priceTitle={priceTitle}
                        formattedPrice={isGive ? 'Free' : formattedPrice}
                        richTitle={richTitle}
                        category={category}
                        authorId={currentAuthor && currentAuthor.id.uuid}
                        authorDisplayName={authorDisplayName}
                        hostLink={hostLink}
                        showContactUser={showContactUser}
                        onContactUser={this.onContactUser}
                        onButtonClick={handleBookingSubmit}
                        condition={publicData && publicData.condition}
                        productType={productType}
                        onCollectionOptionChange={this.onCollectionOptionChange}
                        selectedCollectionOption={this.state.selectedCollectionOption}
                        isGive={isGive}
                        collectionInPerson={collectionInPerson}
                        collection={collection}
                        currentUser={currentUser}
                        listingId={currentListing.id}
                        salePending={salePending}
                        salePendingRequest={salePendingRequest}
                        isSold={publicData && publicData.sold}
                        isOwnListing={isOwnListing}
                        handlePutOnSale={this.handlePutOnSale}
                        formattedOldPrice={formattedOldPrice}
                        sale={sale}
                        postagePrice={postagePriceFormat}
                        location={publicData && publicData.location}

                        // Additonal props to allow contact link
                        // to work from header instead of SectionHostMaybe component
                        title={title}
                        listing={currentListing}
                        onContactUser={this.onContactUser}
                        isEnquiryModalOpen={isAuthenticated && this.state.enquiryModalOpen}
                        onCloseEnquiryModal={() => this.setState({ enquiryModalOpen: false })}
                        sendEnquiryError={sendEnquiryError}
                        sendEnquiryInProgress={sendEnquiryInProgress}
                        onSubmitEnquiry={this.onSubmitEnquiry}
                        onManageDisableScrolling={onManageDisableScrolling}
                      />

                      <SectionDescriptionMaybe description={description} />

                      <SectionFeaturesMaybe
                        options={subCategoriesConfig}
                        publicData={publicData}
                        postagePrice={postagePriceFormat}
                        isGive={isGive}
                        toggleMap={toggleMap}
                        mapVisibility={this.state.mapVisibility}
                      />

                      {/* Show map on Show Map toggle */}
                      {this.state.mapVisibility &&
                        <SectionMapMaybe
                          geolocation={geolocation}
                          publicData={publicData}
                          listingId={currentListing.id}
                        />
                      }

                      <SectionHostMaybe
                        // title={title}
                        // hostLink={hostLink}
                        authorId={currentAuthor && currentAuthor.id.uuid}
                        listing={currentListing}
                        // authorDisplayName={authorDisplayName}
                        // onContactUser={this.onContactUser}
                        // isEnquiryModalOpen={isAuthenticated && this.state.enquiryModalOpen}
                        // onCloseEnquiryModal={() => this.setState({ enquiryModalOpen: false })}
                        // sendEnquiryError={sendEnquiryError}
                        // sendEnquiryInProgress={sendEnquiryInProgress}
                        // onSubmitEnquiry={this.onSubmitEnquiry}
                        // currentUser={currentUser}

                        // onManageDisableScrolling={onManageDisableScrolling}
                        // salePending={salePending}
                        // isSold={publicData && publicData.sold}
                      />
                </div>
              </div>

            {currentListing && currentListing.id && currentListing.id.uuid && publicData && (
                <SectionRelatedProducts
                  category={publicData.category}
                  id={currentListing.id.uuid}
                  history={history}
                />
              )}

          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer className={css.footer} />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ListingPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  sendEnquiryError: null,
  filterConfig: config.custom.filters,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  sendEnquiryInProgress: bool.isRequired,
  sendEnquiryError: propTypes.error,
  onSendEnquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  filterConfig: array,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    enquiryModalOpenForListingId,
    salePending,
    salePendingRequest,
  } = state.ListingPage;
  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    salePending,
    salePendingRequest,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values) => dispatch(setInitialValues(values)),
  onSendEnquiry: (listingId, message, isGive) => dispatch(sendEnquiry(listingId, message, isGive)),
  onFetchTransactionLineItems: (bookingData, listingId, isOwnListing) =>
    dispatch(fetchTransactionLineItems(bookingData, listingId, isOwnListing)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onUpdateFavorites: data => dispatch(updateFavorites(data)),
  onLoadLatestListingData: (listingId) => dispatch(loadLatestListingData(listingId))
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ListingPageComponent);

ListingPage.setInitialValues = initialValues => setInitialValues(initialValues);
ListingPage.loadData = loadData;

export default ListingPage;
