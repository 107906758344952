import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './AddImages.css';
import imgRotate from './rotate.png';

const RotateImageButton = props => {
  const { className, rootClassName, onClick } = props;
  const classes = classNames(rootClassName || css.rotateImage, className);
  return (
    <button className={classes} onClick={onClick} type="button">
      <img src={imgRotate} width="30" />
    </button>
  );
};

RotateImageButton.defaultProps = { className: null, rootClassName: null };

const { func, string } = PropTypes;

RotateImageButton.propTypes = {
  className: string,
  rootClassName: string,
  onClick: func.isRequired,
};

export default RotateImageButton;
