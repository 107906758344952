import React from 'react';
import css from './SectionCtaColumns.css';
import { NamedLink } from '../../components';

const SectionCtaColumns = props => {
  const namedLink = props.isAuthenticated ? 'NewListingPage' : 'SignupPage';

  return (
    <div className={css.ctaColumnsSizer}>
      <div className={css.ctaColumns}>
        <div className={css.column}>
          <div className={css.columnInner}>
            Want to <br />
            <NamedLink name={namedLink}>
              <span>list an item</span>
            </NamedLink>
            ?
          </div>
        </div>
        <div className={css.column}>
          <div className={css.columnInner}>
            Want to <br />
            <NamedLink name="GhostPage" params={{ slug: 'the-octopus-club-guide' }}>
              take good photos
            </NamedLink>{' '}
            <br />
            of your items?
          </div>
        </div>
        <div className={css.column}>
          <div className={css.columnInner}>
            Suggestions on <br />
            <NamedLink name="GhostPage" params={{ slug: 'the-octopus-club-guide' }}>
              how to sell quick
            </NamedLink>
            ?
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionCtaColumns;
