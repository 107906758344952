import React from 'react';
import config from '../../config';
import { StaticPage } from '../../containers';
import { 
  Button, 
  PrimaryButton, 
  SecondaryButton 
} from '../../components';

import css from './Theme.css';
import classNames from 'classnames';

const Theme = () => {
  // prettier-ignore
  return (
    <StaticPage
      title="The Octopus Club Theme"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'AboutPage',
        description: 'The Octopus Club Theme',
        name: 'The Octopus Club Theme',
      }}
    >

      <div className={css.contentWrapper}>
          <h1 className={css.pageTitle}>The Octopus Club Theme</h1>
            <div className={css.contentMain}>

            <section>
              <h3> Colours </h3>

              <div className={css.swatches}>
              <div className={classNames(css.swatch, css.yellow100 )}></div>
              <div className={classNames(css.swatch, css.yellow200 )}></div>
              <div className={classNames(css.swatch, css.yellow300 )}></div>
              <div className={classNames(css.swatch, css.yellow400 )}></div>
              <div className={classNames(css.swatch, css.yellow500 )}></div>
              <div className={classNames(css.swatch, css.yellow600 )}></div>
              <div className={classNames(css.swatch, css.yellow700 )}></div>
              <div className={classNames(css.swatch, css.yellow800 )}></div>
              <div className={classNames(css.swatch, css.yellow900 )}></div>
             
              <div className={classNames(css.swatch, css.pink100 )}></div>
              <div className={classNames(css.swatch, css.pink200 )}></div>
              <div className={classNames(css.swatch, css.pink300 )}></div>
              <div className={classNames(css.swatch, css.pink400 )}></div>
              <div className={classNames(css.swatch, css.pink500 )}></div>
              <div className={classNames(css.swatch, css.pink600 )}></div>
              <div className={classNames(css.swatch, css.pink700 )}></div>
              <div className={classNames(css.swatch, css.pink800 )}></div>
              <div className={classNames(css.swatch, css.pink900 )}></div>

              <div className={classNames(css.swatch, css.green100 )}></div>
              <div className={classNames(css.swatch, css.green200 )}></div>
              <div className={classNames(css.swatch, css.green300 )}></div>
              <div className={classNames(css.swatch, css.green400 )}></div>
              <div className={classNames(css.swatch, css.green500 )}></div>
              <div className={classNames(css.swatch, css.green600 )}></div>
              <div className={classNames(css.swatch, css.green700 )}></div>
              <div className={classNames(css.swatch, css.green800 )}></div>
              <div className={classNames(css.swatch, css.green900 )}></div>
              </div>

          </section>
          <section>
            <h1>H1 Header</h1>
            <h2>H2 Header</h2>
            <h3>H3 Header</h3>
            <h4>H4 Header</h4>
            <h5>H5 Header</h5>
            <h6>H6 Header</h6>

            <p>Paragraph</p>
            <ul><li>Unordered list</li><li>Unordered list</li><li>Unordered list</li></ul>
            <ol><li>Ordered list</li><li>Ordered list</li><li>Ordered list</li></ol>
            </section>
            <form>
              <fieldset>
                <h3>Form header</h3>
                <div>
                  <label for="input">Text input</label>
                  <input type="text" name="input" placeholder="Placholder" />
                  <div class="">Error message</div>
                </div>

                <div>
                  <label for="input">Select</label>
                  <select name="select">
                    <option>Select…</option>
                    </select>
                  <div class="">Error message</div>
                </div>

                <div>
                  <label for="input">Label</label>
                  <input type="text" name="input" placeholder="Placeholder" />
                  <div class="">Error message</div>
                </div>

              <div>
                <h3>Buttons</h3>
                <p>
                  <Button type="submit">Button</Button>
                </p>
                <p>
                  <PrimaryButton  type="submit">Primary button</PrimaryButton>
                </p>
                <p>
                  <SecondaryButton type="submit">Secondary button</SecondaryButton>
                </p>
              </div>
              </fieldset>

              <div>
                <a href="#">Badge</a>
              </div>

            </form>

            <hr />

            <div>
              <div class="">Product card</div>
            </div>
            </div>
          </div>
    </StaticPage>
  );
};

export default Theme;
