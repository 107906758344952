import React from 'react';
import { string, array } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';

import { ExternalLink } from '../../components/';

import css from './SectionInstagram.css';

const SectionInstagram = props => {
  const { 
    rootClassName, 
    className, 
    copy,
    linkLabel,
    images
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  const { siteInstagramPage } = config;

  const instagramImages = images ? (
    images.map((image) => {
      return(
          <ExternalLink
          key="linkToInstagram"
          href={siteInstagramPage}
          >
          <img 
            src={image.url} 
            width="250" 
            height="250" 
            alt="" />
        </ExternalLink>
        )
      })
  ) : null;

  return (
    <div className={classes}>
      <div className={css.container}>
        { instagramImages &&
        
        <div className={css.images}>
          {instagramImages}  
        </div>

        }
        <div className={css.copy}  dangerouslySetInnerHTML={{ __html: copy }}></div>
        <div className={css.footer}>
          <ExternalLink
            key="linkToInstagram"
            href={siteInstagramPage}
            className={css.button}
          >
            { linkLabel}
          </ExternalLink>
        </div>
      </div>
    </div>
  );
};

SectionInstagram.defaultProps = { 
  rootClassName: null, 
  className: null,
  copy: null,
  link: null,
  images: null
};

SectionInstagram.propTypes = {
  rootClassName: string,
  className: string,
  copy: string,
  linkLabel: string,
  images: array
};

export default SectionInstagram;
