import moment from "moment";

export const hasAccessToOctoplus = (currentUser) => {
  if (currentUser) {
    const publicData = currentUser.attributes.profile.publicData || {};

    if (publicData.octoplusAccessFrom) {
      let accessFrom = new moment(publicData.octoplusAccessFrom);
      let now = new moment();

      if (now.isBefore(accessFrom.add(30, 'd'))) {
        return true;
      }
    }
  }

  return false;
}

export const octoplusAccessUntil = (currentUser) => {
  if (currentUser) {
    const publicData = currentUser.attributes.profile.publicData || {};

    if (publicData.octoplusAccessFrom) {
      return new moment(publicData.octoplusAccessFrom).add(30, 'd').format('DD MMM YYYY HH:mm');
    }
  }
}

export const octplusAccessUntilDays = (currentUser) => {
  if (currentUser) {
    const publicData = currentUser.attributes.profile.publicData || {};

    if (publicData.octoplusAccessFrom) {
      let accessFrom = new moment(publicData.octoplusAccessFrom);
      let now = new moment();

      return accessFrom.add(30, 'd').diff(now, 'days');
    }
  }
}