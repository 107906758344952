import { getPartner } from '../../util/api';

export const PARTNER_REQUEST = 'app/OctoplusPartnerPage/PARTNER_REQUEST';
export const PARTNER_SUCCESS = 'app/OctoplusPartnerPage/PARTNER_SUCCESS';
export const PARTNER_ERROR = 'app/OctoplusPartnerPage/PARTNER_ERROR';

export const partnerRequest = () => ({
  type: PARTNER_REQUEST,
});

export const loadPartnerSuccess = response => {
  return {
    type: PARTNER_SUCCESS,
    payload: response,
  };
};

export const loadPartnerError = e => ({
  type: PARTNER_ERROR,
  error: true,
  payload: e,
});

const initialState = {
  loadPartnerInProgress: false,
  loadPartnerError: null,
  partner: null,
};

const octoplusPartnerPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case PARTNER_REQUEST:
      return {
        ...state,
        loadPartnerInProgress: true,
        partner: null,
        loadPartnerError: null,
      };
    case PARTNER_SUCCESS:
      return {
        ...state,
        partner: payload,
        loadPartnerInProgress: false,
      };
    case PARTNER_ERROR:
      return {
        ...state,
        loadPartnerInProgress: false,
        loadPartnerError: payload
      };

    default:
      return state;
  }
};

export default octoplusPartnerPageReducer;

export const loadPartner = params => (dispatch) => {
  // hack for ssr rendering as the api code all relised on window.
  if (typeof window === "undefined") return null;

  dispatch(partnerRequest());

  return getPartner(params.id)
    .then(response => {
      dispatch(loadPartnerSuccess(response));
    })
    .catch(e => {
      dispatch(loadPartnerError(e));
      throw e;
    });
};

export const loadData = loadPartner;
