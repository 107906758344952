import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconList.css';

const IconList = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={css.icon} viewBox="0 0 14 12" xmlns="http://www.w3.org/2000/svg">
      <rect width="14" height="2" />
      <rect y="5" width="14" height="2" />
      <rect y="10" width="14" height="2" />
  </svg>
  );
};

IconList.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconList.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconList;
