import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import {
  Button,
  NamedLink,
  IconList,
  IconMap
} from '..';
import css from './QuickFilters.css';
import Toggle from 'react-toggle';

import { intlShape, injectIntl } from '../../util/reactIntl';
import FilterCheckbox from "../Filters/FilterCheckbox";

const QuickFilters = ({
  filterState,
  handleSoldChange,
  handleFilterChange,
  hideSold,
  mapIsShown,
  onMapToggleClick

}) => {

  return (
    <div className={css.root}>
	    <ul role="list">
        <FilterCheckbox
          label="Show sold items"
          value="true"
          name="hideSold"
          checked={!hideSold}
          handleChange={(checked) => {
            handleSoldChange(!checked)
          }}
        />
        {/* <FilterCheckbox
          label="Collection only"
          value="collection-only"
          name="pub_shipping"
          checked={!!filterState?.['pub_shipping']?.find(({key}) => key==='collection-only')}
          handleChange={ (checked) => {handleFilterChange('pub_shipping', {
            name : 'pub_shipping',
            key : 'collection-only',
            label : 'Collection only',
            parentLabel : 'Shipping',
            checked
          })}} /> */}
      </ul>
      <div className={css.mapToggle} onClick={() => {
        onMapToggleClick()
        }}>
          { mapIsShown  ? (
            <><IconList /> <span className={css.mapToggleLabel}> List <span>view</span> </span> </>
          ): (
            <><IconMap /> <span className={css.mapToggleLabel}> Map <span>view</span> </span> </>
          )
          }
        </div>
     </div>
  );
};

QuickFilters.defaultProps = {
};

const { array, node, object, string } = PropTypes;

QuickFilters.propTypes = {
};

export default injectIntl(QuickFilters);
