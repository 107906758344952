import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import {
  Avatar,
  Button,
  InlineTextButton,
  IconNotification,
  IconSearch,
  IconMenu,
  IconClose,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
} from '../../components';
import { TopbarSearchForm } from '../../forms';

import css from './TopbarDesktop.css';
import {subCategories} from "../../marketplace-custom-config";

const TopbarDesktop = props => {
  const {
    className,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    currentSearchParams,
    type,
    location,
    currentProductType,
    openMobileNavState,
    toggleNavigation,
    closeNavigation
  } = props;

  const { pathname, state } = location;
  const productType = (state && state.type) || currentProductType;
  const [mounted, setMounted] = useState(false);

  const [ searchIsOpen, setsearchIsOpen ] = useState(false);
  const handleSearch = () => {
    setsearchIsOpen(!searchIsOpen);
  };



  const toggleSearch = (event) => {
    if (window.matchMedia("(min-width: 768px)").matches) {
      document.documentElement.classList.toggle('has-search-opened');

    } else {
      toggleNavigation();
    }
    setTimeout( () => document.getElementById('keyword-search').focus(), 500);

  }

  const closeSearch = (event) => {
    document.documentElement.classList.remove('has-search-opened');
  }

  const [openNavState, setOpenNavState] = useState({
    shopFor : false,
    takeMeTo : false,
    user : false
  })


  const toggleSubnavigationShopFor = () => {

    setOpenNavState({
      shopFor: !openNavState.shopFor,
      takeMeTo: false,
      user : false
    })

  }

  const toggleSubnavigationTakeMeTo = () => {
    setOpenNavState({
      shopFor: false,
      takeMeTo: !openNavState.takeMeTo,
      user : false
    })
  }

  const toggleSubnavigationUser = () => {
    setOpenNavState({
      shopFor: false,
      takeMeTo: false,
      user : !openNavState.user
    })
  }

  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);

  const searchToggleIcon = (
      <li>
        <Button
          onClick={toggleSearch}
          rootClassName={css.searchToggle}
        >
          <span className={css.searchToggleText}>
            {/* {closeButtonMessage || <FormattedMessage id="Modal.close" />} */}
            Search
          </span>
          <IconSearch rootClassName={css.searchIcon} />
        </Button>
      </li>
  );

  const search = (
    <>
    <TopbarSearchForm
      className={css.searchPanel}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      currentSearchParams={currentSearchParams}
      closeSearch={closeSearch}
      closeNavigation={closeNavigation}

    />
    <Button
      onClick={closeSearch}
      rootClassName={css.closeSearchButton}
      >
        <IconClose />
        <span className={css.closeSearchButtonLabel}>Close search</span>
    </Button>
    </>
  );


  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot}> { notificationCount } </div> : null;

  const inboxLink = authenticatedOnClientSide ? (
      <NamedLink
        className={
          productType === 'inbox'
            ? classNames(css.linkActive, css.basicLink, css.inboxContainer)
            : classNames(css.basicLink, css.inboxContainer)
        }
        name="InboxPage"
        params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
        to={{ state: { type: 'inbox' } }}
      >
        <IconNotification />
        <span className={css.inbox}>
          {notificationDot}
        </span>
      </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const user = ensureCurrentUser(currentUser);
  const displayName = user.attributes.profile.firstName;

  const profileMenu = authenticatedOnClientSide ? (
    <li>
      <Menu>
        <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
          <Avatar className={css.avatar} user={currentUser} disableProfileLink />
          <span className={css.displayName}>{ displayName }</span>

        </MenuLabel>
        <MenuContent className={css.profileMenuContent}>
          <MenuItem key="OpenListingsPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('OpenListingsPage'))}
              name="OpenListingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.yourListingsLink" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="MyFavoritePage">
            <NamedLink
              className={classNames(css.profileSettingsLink, currentPageClass('MyFavoritePage'))}
              name="MyFavoritePage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.favourites" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="ProfileSettingsPage">
            <NamedLink
              className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
              name="ProfileSettingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="AccountSettingsPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
              name="AccountSettingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="logout">
            <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.logout" />
            </InlineTextButton>
          </MenuItem>
        </MenuContent>
      </Menu>
    </li>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarDesktop.login" />
      </NamedLink>
  );

  return (
      <>
      <nav className={css.navigationContainer}aria-label="Main Navigation">
        <ul className={classNames(css.navigation,css.navigationPrimary)}>
          <li>
            <NamedLink
              name="SearchPage"
              activeClassName={css.active}
            >
              Buy
            </NamedLink>
          </li>
          <li>
          <NamedLink
            name="NewListingPage"
            className={
              productType === 'sell' ? classNames(css.active, css.basicLink) : css.basicLink
            }
            to={{ state: { type: 'sell' } }}
            >
              Sell
          </NamedLink>
          </li>
          <li>
          <NamedLink
            name="NewDonateListingPage"
            className={
              productType === 'give' ? classNames(css.active, css.basicLink) : css.basicLink
            }
            to={{ state: { type: 'give' } }}
            >
            Donate
          </NamedLink>
          </li>
          <li>
          <NamedLink
            name="OctoplusPage"
            to={{ state: { type: 'octoplus' } }}
            activeClassName={css.active}
            >
            Benefit
          </NamedLink>
          </li>
        </ul>

        <ul className={classNames(css.navigation,css.navigationSecondary)}>
          <li>
          <Button
            onClick={toggleSubnavigationShopFor}
            rootClassName={css.navigationButton}
            aria-haspopup="true"
            aria-expanded={openNavState.shopFor}
          >
            Shop for
          </Button>
          <ul className={css.subNavigation}>
              <li><a href="/s?pub_ages=month-0-3%2Cmonth-18-24%2Cmonth-9-12%2Cmonth-6-9%2Cmonth-3-6%2Cmonth-12-18">Baby</a></li>
              <li><a href="/s?pub_ages=age-3-4%2Cage-4-5%2Cage-5-6%2Cage-6-7%2Cage-7-8%2Cage-8-9%2Cage-9-10%2Cage-10-11%2Cage-2-3%2Cage-11-12">Kids</a></li>
              <li><a href="/s?pub_genders=wear-maternity">Maternity</a></li>
          </ul>
          </li>
          <li>
          <Button
            onClick={toggleSubnavigationTakeMeTo}
            rootClassName={css.navigationButton}
            aria-haspopup="true"
            aria-expanded={openNavState.takeMeTo}
          >
            Take me to
          </Button>
            <ul className={css.subNavigation}>
                <li><a href="/s?pub_category=care">Care</a></li>
                <li><a href="/s?pub_category=feed">Feed</a></li>
                <li><a href="/s?pub_category=move">Move</a></li>
                <li><a href="/s?pub_category=organise">Organise</a></li>
                <li><a href="/s?pub_category=play">Play</a></li>
                <li><a href="/s?pub_category=read">Read</a></li>
                <li><a href="/s?pub_category=sleep">Sleep</a></li>
                <li><a href="/s?pub_category=wear">Wear</a></li>
            </ul>
          </li>
          <li>
            <NamedLink
              to={{ state: { type: 'how-it-works' } }}
              name="GhostPage"
              params={{ slug: 'how-it-works' }}
              activeClassName={css.active}
              >
              How it works
            </NamedLink>
          </li>
          {/* <li>
            <NamedLink
              name="OctoplusPage"
              to={{ state: { type: 'octoplus' } }}
              activeClassName={css.active}
              >
              Octoplus
            </NamedLink>
          </li> */}
        </ul>
        { authenticatedOnClientSide && (
            <ul className={classNames(css.navigation,css.navigationTertiaryMobile)}>
              <li>
                <NamedLink
                  name="InboxPage"
                  params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
                  to={{ state: { type: 'inbox' } }}>
                <FormattedMessage id="TopbarDesktop.inbox" /> {notificationDot}
                </NamedLink>
              </li>
              <li>
                <NamedLink name="OpenListingsPage">
                <FormattedMessage id="TopbarDesktop.yourListingsLink" />
                </NamedLink>
              </li>
              <li>
                <NamedLink name="MyFavoritePage">
                  <FormattedMessage id="TopbarDesktop.favourites" />
                  </NamedLink>
              </li>
              <li>
                <NamedLink name="ProfileSettingsPage">
                  <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
                </NamedLink>
              </li>
              <li>
                <NamedLink name="AccountSettingsPage">
                  <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
                </NamedLink>
              </li>
              <li>
                <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
                  <FormattedMessage id="TopbarDesktop.logout" />
                </InlineTextButton>
              </li>
            </ul>
        )}

        </nav>

        <ul className={classNames(css.navigation,css.navigationTertiary)}>
          {searchToggleIcon}

          { authenticatedOnClientSide && (
          <>
          <li>
            <Button
                onClick={toggleSubnavigationUser}
                rootClassName={css.navigationButton}
                className={css.userAvatarButton}
                aria-haspopup="true"
                aria-expanded={openNavState.user}
              >
                <Avatar className={css.avatar} user={currentUser} disableProfileLink />
                <span className={css.displayName}>{ displayName }</span>
              </Button>
              <ul className={classNames(css.subNavigation,css.subNavigationUser)}>
              <li>
                <NamedLink name="OpenListingsPage">
                <FormattedMessage id="TopbarDesktop.yourListingsLink" />
                </NamedLink>
              </li>
              <li>
                <NamedLink name="MyFavoritePage">
                  <FormattedMessage id="TopbarDesktop.favourites" />
                  </NamedLink>
              </li>
              <li>
                <NamedLink name="ProfileSettingsPage">
                  <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
                </NamedLink>
              </li>
              <li>
                <NamedLink name="AccountSettingsPage">
                  <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
                </NamedLink>
              </li>
              <li>
                <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
                  <FormattedMessage id="TopbarDesktop.logout" />
                </InlineTextButton>
              </li>
              </ul>
            </li>
            <li>{inboxLink}</li>
         </>
         )}

         { !authenticatedOnClientSide && (
          <>
          <li>{signupLink}</li>
          <li>{loginLink}</li>
          </>
         )}
        </ul>

        {search}


        <Button
            onClick={toggleNavigation}
            rootClassName={css.toggle}
            aria-label="Menu"
            aria-pressed={openMobileNavState}>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span className={css.toggleLabel}>Menu</span>
          </Button>

          <div className={css.footer}>
            <NamedLink
              className={css.createNewListingLink}
              name="NewListingPage"
              name="NewListingPage"
              to={{ state: { type: 'sell' } }}
            >
              <FormattedMessage id="TopbarMobileMenu.newListingLink" />
            </NamedLink>
          </div>
    </>

  );
};

const { bool, func, object, number, string } = PropTypes;

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  currentSearchParams: {},
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  // currentSearchParams: object.isRequired,
};

export default TopbarDesktop;
