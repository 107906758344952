import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import {filters} from '../../marketplace-custom-config';
import {useHistory} from "react-router-dom";
import {
  Button,
  FieldCheckbox,
  FieldCheckboxGroup,
  IconClose
} from '..';

import css from './Filters.css';

import { intlShape, injectIntl } from '../../util/reactIntl';
import FilterDropdownGroup from "./FilterDropdownGroup";
import FilterDropdown from "./FilterDropdown";

const Filters = ({filterState,handleClearAll,handleFilterChange,handleToggleOpenFilters}) => {


  const [openState, setOpenState] = useState(undefined);

  const handleOpenState = ( currentOpenDropdown ) => {
    setOpenState(currentOpenDropdown)
  }

  const _handleFilterState = (name,value) => {
    if (window.matchMedia("(min-width: 768px)").matches) {
        handleOpenState(undefined);
    }
    handleFilterChange(name,value)
  }

  const handleClose = '';//intl.formatMessage({ id: 'Modal.closeModal' });
  const closeFilterMessage = '';//intl.formatMessage({ id: 'Modal.closeModal' });
  const closeButtonMessage = '';
  const closeButtonClasses = classNames(css.close);


  const handleResetButton = () => {
    handleClearAll();
    handleToggleOpenFilters();
  }


  return (
    <div className={css.root}>

        <h2 className={css.filterTitle}>Filters</h2>
    
        <Button
            onClick={handleToggleOpenFilters}
            rootClassName={closeButtonClasses}
            title={closeFilterMessage}
        >
            <span className={css.closeText}>
            {closeButtonMessage || <FormattedMessage id="Modal.close" />}
            </span>
            <IconClose rootClassName={css.closeIcon} />
        </Button>

        <div className={css.container}>

            <FilterDropdownGroup
              data={ filters.find( ({id}) => id === 'category') }
              filterState={filterState}
              handleChange={_handleFilterState}
              handleOpenState={handleOpenState}
              includeAllCheckbox={true}
              openState={openState}
            />
            <FilterDropdownGroup
              data={ filters.find( ({id}) => id === 'age-size') }
              columns={true}
              filterState={filterState}
              handleChange={_handleFilterState}
              handleOpenState={handleOpenState}
              openState={openState}
            />
            <FilterDropdown
              data={filters.find( ({id}) => id === 'gender')}
              filterState={filterState}
              handleChange={_handleFilterState}
              handleOpenState={handleOpenState}
              openState={openState}
            />
            <FilterDropdown
              data={filters.find( ({id}) => id === 'condition')}
              filterState={filterState}
              handleChange={_handleFilterState}
              handleOpenState={handleOpenState}
              openState={openState}
            />
            <FilterDropdown
              data={filters.find( ({id}) => id === 'season')}
              filterState={filterState}
              handleChange={_handleFilterState}
              handleOpenState={handleOpenState}
              openState={openState}
            />
            <FilterDropdown
              data={filters.find( ({id}) => id === 'shipping')}
              filterState={filterState}
              handleChange={_handleFilterState}
              handleOpenState={handleOpenState}
              openState={openState}
            />

        </div>

        {/* Buttons only visible on mobile */}
        <div className={css.applyPanel}>
            <Button className={css.reset} onClick={handleResetButton}>
                Reset
            </Button>
            <Button className={css.apply} onClick={handleToggleOpenFilters}>
                Apply
            </Button>
        </div>

    </div>
  );
};

Filters.defaultProps = {
};

Filters.propTypes = {

};

export default injectIntl(Filters);
