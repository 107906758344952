import React from 'react';
import { string, image } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import { NamedLink } from '..';

import css from './CtaCard.css';

const CtaCard = props => {
  const { 
    rootClassName, 
    className, 
    title,
    copy,
    namedLink,
    namedLinkSlug,
    namedLinkTo,
    linkLabel,
    color
  } = props;

  const classes = classNames(rootClassName || css.root, className, color);
  
  return (
    <div className={classes}>
      <div className={css.copy}>
          <h2 className={css.title}> {title}</h2>
          { copy &&
          <div dangerouslySetInnerHTML={{ __html: copy }}></div>
          }
     </div>
     { namedLink && 
     <div className={css.footer}>
        <NamedLink 
          name={namedLink}
          params={{ slug: namedLinkSlug }}
          to={{ state: { type: namedLinkTo } }}
          className={css.button}
          >
          { linkLabel }
        </NamedLink>
      </div>
     }
    </div>
  );
};

CtaCard.defaultProps = { 
  rootClassName: null, 
  className: null,
  title: null,
  copy: null,
  namedLink: null,
  namedLinkSlug: null,
  namedLinkTo: null,
  linkLabel: null,
  color: null
 };

CtaCard.propTypes = {
  rootClassName: string,
  className: string,
  title: string,
  copy: string,
  namedLink: string,
  namedLinkSlug: string,
  namedLinkTo: string,
  linkLabel: string,
  color: string
};

export default CtaCard;
