import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconMenu.css';

const IconMenu = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="26"
      height="23"
      viewBox="0 0 26 23"
      xmlns="http://www.w3.org/2000/svg"
    >
    <path d="M1 1.5h24M1 11.5h24M1 21.5h24" stroke="currentColor" strokeWidth="1.755" strokeMiterlimit="10" strokeLinecap="round"/>
    </svg>
  );
};

IconMenu.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconMenu.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconMenu;
