import { getPartners } from '../../util/api';

export const PARTNERS_REQUEST = 'app/OctoplusPage/PARTNERS_REQUEST';
export const PARTNERS_SUCCESS = 'app/OctoplusPage/PARTNERS_SUCCESS';
export const PARTNERS_ERROR = 'app/OctoplusPage/PARTNERS_ERROR';

export const partnersRequest = () => ({
  type: PARTNERS_REQUEST,
});

export const loadPartnersSuccess = response => {
  return {
    type: PARTNERS_SUCCESS,
    payload: response,
  };
};

export const loadPartnersError = e => ({
  type: PARTNERS_ERROR,
  error: true,
  payload: e,
});

const initialState = {
  loadPartnersInProgress: false,
  loadPartnersError: null,
  partners: [],
};

const octoplusPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case PARTNERS_REQUEST:
      return {
        ...state,
        loadPartnersInProgress: true,
        partners: [],
        loadPartnersError: null,
      };
    case PARTNERS_SUCCESS:
      return {
        ...state,
        partners: payload,
        loadPartnersInProgress: false,
      };
    case PARTNERS_ERROR:
      return {
        ...state,
        loadPartnersInProgress: false,
        loadPartnersError: payload
      };

    default:
      return state;
  }
};

export default octoplusPageReducer;

export const loadPartners = () => (dispatch, getState, sdk) => {
  // hack for ssr rendering as the api code all relised on window.
  if (typeof window === "undefined") return null;

  dispatch(partnersRequest());

  return getPartners()
    .then(response => {
      dispatch(loadPartnersSuccess(response));
    })
    .catch(e => {
      dispatch(loadPartnersError(e));
      throw e;
    });
};

export const loadData = loadPartners;
