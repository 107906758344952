import React from 'react';
import css from './SectionCta.css';
import CtaBanner from '../../components/CtaBanner/CtaBanner';

const SectionCta = props => {
  return (
    <div className={css.ctaSizer}>
      <div className={css.cta}>
        <CtaBanner
          title={props.title}
          buttonTitle={props.buttonTitle}
          flipped={props.flipped}
          linkName={props.linkName}
          history={props.history}
          search={props.search}
        />
        <div className={css.image}>
          <img className={css.rootForImage} src={props.image} alt={props.title} />
        </div>
      </div>
    </div>
  );
};

export default SectionCta;
