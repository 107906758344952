import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


import css from './SortByNew.css';

import { intlShape, injectIntl } from '../../util/reactIntl';

const SortByNew = ({    
  rootClassName, 
  className, 
  label,
  sortState, 
  handleSortByChange
}) => {

  const classes = classNames(rootClassName || css.root, className);

  const [selected, setSelected] = useState(sortState);

  const _handleChange = (e) => {
    setSelected(e.target.value);
    handleSortByChange(e.target.value)
  }

  return (
    <div className={classes}>
	    <label htmlFor="SortBy">{label}</label> 
      <div className={css.select}>
        <select
          name="sort_by"
          id="SortBy"
          onChange={_handleChange}
        >
          <option value="relevance" selected={sortState === 'relevance'}>Relevance</option>
          <option value="createdAt" selected={sortState === 'createdAt'}>Newest</option>
          <option value="-createdAt" selected={sortState === '-createdAt'}>Oldest</option>
          <option value="-price" selected={sortState === '-price'}>Lowest price</option>
          <option value="price" selected={sortState === 'price'}>Highest price</option>
        </select>
      </div>

   
   
    </div>

  );
};

SortByNew.defaultProps = {
  label: "Sort by:"
};

SortByNew.propTypes = {
};

export default injectIntl(SortByNew);
