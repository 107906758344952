import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSearch.css';

const IconSearch = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="currentColor">
	<path d="M9.5,15.1c-3.1,0-5.6-2.5-5.6-5.6c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.2S3.8,8.8,3.8,8.8C3.7,8.7,3.7,8.7,3.6,8.7
		c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.2,0-0.2,0C3.1,8.7,3,8.7,2.9,8.8C2.9,8.8,2.8,8.9,2.8,8.9S2.8,9.1,2.8,9.2c0,0.1,0,0.2,0,0.3
		c0,3.7,3,6.8,6.7,6.8c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.4c0-0.2-0.1-0.3-0.2-0.4C9.8,15.1,9.7,15.1,9.5,15.1z"/>
	<path d="M23.4,20.5l-5.9-5.9c1.2-2,1.7-4.3,1.3-6.7c-0.4-2.3-1.6-4.4-3.4-5.8C13.6,0.6,11.3-0.1,8.9,0C6.6,0.2,4.4,1.1,2.8,2.8
		S0.1,6.7,0,9c-0.1,2.3,0.6,4.6,2.1,6.5c1.5,1.8,3.6,3,5.9,3.4s4.7-0.1,6.6-1.4l5.9,5.9c0.4,0.4,0.9,0.6,1.5,0.6c0,0,0,0,0,0
		c0.3,0,0.5-0.1,0.8-0.2c0.2-0.1,0.5-0.3,0.7-0.4C24.2,22.6,24.2,21.3,23.4,20.5z M3.6,15.4c-1.4-1.4-2.2-3.2-2.4-5.1
		C1,8.4,1.5,6.5,2.6,4.9c1.1-1.6,2.7-2.8,4.5-3.3c1.8-0.6,3.8-0.5,5.6,0.3s3.2,2.1,4.2,3.8c0.9,1.7,1.2,3.7,0.8,5.6
		c-0.4,1.9-1.4,3.6-2.9,4.8c-1.5,1.2-3.4,1.9-5.3,1.9c-1.1,0-2.2-0.2-3.2-0.6C5.3,16.8,4.4,16.2,3.6,15.4z M22.6,22.6
		c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0-0.2,0.1-0.3,0.1h0c-0.2,0-0.5-0.1-0.7-0.3l-5.7-5.8c0.2-0.2,0.4-0.4,0.6-0.6
		c0.2-0.2,0.4-0.5,0.6-0.7l5.7,5.8C22.9,21.6,22.9,22.2,22.6,22.6L22.6,22.6z"/>
      </g>
    </svg>
  );
};

IconSearch.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconSearch.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconSearch;
