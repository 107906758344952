/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { AvatarLarge, InlineTextButton, NamedLink, NotificationBadge } from '../../components';

import css from './TopbarMobileMenu.css';

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    onLogout,
  } = props;

  const user = ensureCurrentUser(currentUser);
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  if (!isAuthenticated) {
    return (
      <div className={css.root}>
        <div className={css.content}>
          <nav className={classNames(css.mobileMenu, css.noMarginTop)}>
            <div className={css.navWrapper}>
              {/* TODO: Convert to actual working links */}
              <NamedLink
                className={classNames(css.navigationLink, css.boldBlackText)}
                name="SearchPage">
                <span>Buy</span>
              </NamedLink>
              <NamedLink
                className={classNames(css.navigationLink, css.boldBlackText)}
                name="NewListingPage"
                to={{ state: { type: 'sell' } }}
              >
                <span>Sell</span>
              </NamedLink>
              <NamedLink
                className={classNames(css.navigationLink, css.boldBlackText)}
                name="NewGiveListingPage"
                to={{ state: { type: 'give' } }}
              >
                <span>Give</span>
              </NamedLink>
              <br />
              <NamedLink
                className={classNames(css.navigationLink, css.normalBlackText)}
                name="OctoplusPage"
                to={{ state: { type: 'octoplus' } }}
              >
                <span>Octo<strong>plus</strong></span>
              </NamedLink>
              <br />
              <NamedLink
                className={classNames(css.navigationLink, css.normalBlackText)}
                name="GhostPage"
                params={{ slug: 'how-it-works' }}
                to={{ state: { type: 'how-it-works' } }}
              >
                <span className={css.basicLinkInner}>How it works</span>
              </NamedLink>
              <NamedLink
                className={classNames(css.navigationLink, css.normalBlackText)}
                name="GhostPage" params={{ slug: 'faqs' }}>
                <span className={css.basicLinkInner}>FAQs</span>
              </NamedLink>
              <br />
              <NamedLink
                className={classNames(css.navigationLink, css.normalBlackText, currentPageClass('SignupPage'))}
                name="SignupPage"
              >
                <FormattedMessage id="TopbarMobileMenu.signupLink" />
              </NamedLink>
              <NamedLink
                className={classNames(css.navigationLink, css.normalBlackText, currentPageClass('LoginPage'))}
                name="LoginPage"
              >
                <FormattedMessage id="TopbarMobileMenu.loginLink" />
              </NamedLink>
            </div>
          </nav>
        </div>
      </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;

  return (
    <div className={css.root}>
      <AvatarLarge className={css.avatar} user={currentUser} />
      <div className={css.content}>
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
        <br />
        <div className={css.navWrapper}>
          {/* TODO: Convert to actual working links */}
          <NamedLink
            className={classNames(css.navigationLink, css.boldBlackText)}
            name="SearchPage"
          >
            <span>Buy</span>
          </NamedLink>
          <NamedLink
            className={classNames(css.navigationLink, css.boldBlackText)}
            name="NewListingPage"
            to={{ state: { type: 'sell' } }}
          >
            <span>Sell</span>
          </NamedLink>
          <NamedLink
            className={classNames(css.navigationLink, css.boldBlackText)}
            name="NewGiveListingPage"
            to={{ state: { type: 'give' } }}
          >
            <span>Give</span>
          </NamedLink>
          <br />
          <NamedLink
            className={classNames(css.navigationLink, css.normalBlackText)}
              name="OctoplusPage"
              to={{ state: { type: 'octoplus' } }}
            >
            <span>Octo<strong>plus</strong></span>
          </NamedLink>
          <br />
          <NamedLink
            className={classNames(css.navigationLink, css.normalBlackText)}
            name="GhostPage"
            params={{ slug: 'how-it-works' }}
            to={{ state: { type: 'how-it-works' } }}
          >
            <span className={css.basicLinkInner}>How it works</span>
          </NamedLink>
          <NamedLink
            className={classNames(css.navigationLink, css.normalBlackText)}
            name="GhostPage"
            params={{ slug: 'faqs' }}
          >
            <span className={css.basicLinkInner}>FAQs</span>
          </NamedLink>
        </div>
        <br />
        <NamedLink
          className={classNames(
            css.navigationLink,
            css.normalBlackText,
            currentPageClass('InboxPage')
          )}
          name="InboxPage"
          params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
        >
          <FormattedMessage id="TopbarMobileMenu.inboxLink" />
          {notificationCountBadge}
        </NamedLink>
        <NamedLink
          className={classNames(
            css.navigationLink,
            css.normalBlackText,
            currentPageClass('OpenListingsPage')
          )}
          name="OpenListingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(
            css.navigationLink,
            css.normalBlackText,
            currentPageClass('MyFavoritePage')
          )}
          name="MyFavoritePage"
        >
          <FormattedMessage id="TopbarMobileMenu.favourites" />
        </NamedLink>
        <NamedLink
          className={classNames(
            css.navigationLink,
            css.normalBlackText,
            currentPageClass('ProfileSettingsPage')
          )}
          name="ProfileSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(
            css.navigationLink,
            css.normalBlackText,
            currentPageClass('AccountSettingsPage')
          )}
          name="AccountSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
        </NamedLink>
        <br />
        <InlineTextButton
          rootClassName={classNames(css.logoutButton, css.normalBlackText, css.navigationLink)}
          onClick={onLogout}
        >
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>
        <NamedLink
          className={classNames(css.navigationLink, css.normalBlackText)}
          name="LandingPage"
        >
          Home
        </NamedLink>
      </div>
      <div className={css.footer}>
        <NamedLink
          className={css.createNewListingLink}
          name="NewListingPage"
          name="NewListingPage"
          to={{ state: { type: 'sell' } }}
        >
          <FormattedMessage id="TopbarMobileMenu.newListingLink" />
        </NamedLink>
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
