import React from 'react';
import { string, bool } from 'prop-types';
import classNames from 'classnames';
// import {  } from '../../components/';

import css from './SectionTestimonial.css';

const SectionTestimonial = props => {
  const { 
    rootClassName, 
    className, 
    quote,
    author,
    color,
    contrast
  } = props;

  const classes = classNames(rootClassName || css.root, className, color, contrast);

  return (
    <div className={classes}>
      <figure className={css.testimonial}>
          <blockquote>
          
          <div dangerouslySetInnerHTML={{ __html: quote }}></div>

          </blockquote>
          <figcaption> { author} </figcaption>
      </figure>

    </div>
  );
};

SectionTestimonial.defaultProps = { 
  rootClassName: null, 
  className: null,
  quote: null,
  author: null,
  color: 'yellow',
  contrast: null
 };

SectionTestimonial.propTypes = {
  rootClassName: string,
  className: string,
  quote: string,
  author: string,
  color: string,
  contrast: string
};

export default SectionTestimonial;
