import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FacebookShareCount,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
} from 'react-share';

import css from './ManageListingCard.css';

const SocialShareButtons = ({ shareUrl, title }) => (
  <div className={css.socialContainer}>
    <div className={css.socialNetworkHeader}>
      <span className={css.share}>
        <FormattedMessage id="ManageListingCard.share" />
      </span>
    </div>
    <div className={css.socialNetwork}>
      <FacebookShareButton url={shareUrl} quote={title} className={css.networkShareButton}>
        <FacebookIcon size={32} round />
      </FacebookShareButton>

      <div>
        <FacebookShareCount url={shareUrl} className={css.networkShareButton}>
          {count => count}
        </FacebookShareCount>
      </div>
    </div>

    <div className={css.socialNetwork}>
      <WhatsappShareButton
        url={shareUrl}
        title={title}
        separator=":: "
        className={css.networkShareButton}
      >
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>

      <div className={css.socialShareCount}>&nbsp;</div>
    </div>

    <div className={css.socialNetwork}>
      <TwitterShareButton url={shareUrl} title={title} className={css.networkShareButton}>
        <TwitterIcon size={32} round />
      </TwitterShareButton>

      <div className={css.socialShareCount}>&nbsp;</div>
    </div>
  </div>
);

export default SocialShareButtons;
