import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
// import { Modal, NamedLink } from '../../components';
import { NamedLink } from '../../components';
// import { EnquiryForm } from '../../forms';

import css from './ListingPage.css';

const SectionHostMaybe = props => {
  const {
    // title,
    // hostLink,
    listing,
    // authorDisplayName,
    // onContactUser,
    // isEnquiryModalOpen,
    // onCloseEnquiryModal,
    // sendEnquiryError,
    // sendEnquiryInProgress,
    // onSubmitEnquiry,
    // onManageDisableScrolling,
    authorId,
    // salePending,
    // isSold,
  } = props;

  if (!listing.author) {
    return null;
  }

  // const handleContactUserClick = () => {
  //   onContactUser(listing.author);
  // };

  return (
    <div id="host" className={css.sectionHost}>
      <p className={css.hostInfo}>
        <NamedLink name="ProfilePage" params={{ id: authorId }}>
          <FormattedMessage id="ListingPage.moreFromSeller" />
        </NamedLink>
      </p>
      {/* Contact button moved to SeactionHeading component */}
      {/* {!salePending && !isSold && (
        <p className={css.hostInfo}>
          <a href="#" onClick={handleContactUserClick}>
            <FormattedMessage id="ListingPage.contactUser" />
          </a>
        </p>
      )}
      <Modal
        id="ListingPage.enquiry"
        contentClassName={css.enquiryModalContent}
        isOpen={isEnquiryModalOpen}
        onClose={onCloseEnquiryModal}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <EnquiryForm
          className={css.enquiryForm}
          submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
          listingTitle={title}
          authorDisplayName={authorDisplayName}
          sendEnquiryError={sendEnquiryError}
          onSubmit={onSubmitEnquiry}
          inProgress={sendEnquiryInProgress}
        />
      </Modal> */}
    </div>
  );
};

export default SectionHostMaybe;
