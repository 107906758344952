import React, { Component } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { DividerTitle, ListingCard } from '../../components';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import css from './SectionRecentlySold.css';

class SectionRecentlySold extends Component {
  render() {
    const { title, listings, history } = this.props;
    const dataFetched = this.props.listings;
    let recentListings;

    const clickSoldListing = (sold, category) => {
      if (sold) {
        history.push(
          createResourceLocatorString(
            'SearchPage',
            routeConfiguration(),
            {},
            { pub_category: category }
          ),
          {
            sold: true,
          }
        );
      }
    };

    if (dataFetched) {
      recentListings = (
        <ul className={css.listingCards}>
          {listings && listings.length > 4
            ? listings.slice(0, 4).map(l => {
                const { sold, category } = l.attributes.publicData;
                return (
                  <div
                    className={css.listingContainer}
                    onClick={() => clickSoldListing(sold, category[0])}
                  >
                    <ListingCard
                      className={css.listingCard}
                      key={l.id.uuid}
                      listing={l}
                      dontClickSold={sold}
                    />
                  </div>
                );
              })
            : listings.map(l => {
                const { sold, category } = l.attributes.publicData;
                return (
                  <div
                    className={css.listingContainer}
                    onClick={() => clickSoldListing(sold, category[0])}
                  >
                    <ListingCard
                      className={css.listingCard}
                      key={l.id.uuid}
                      listing={l}
                      dontClickSold={sold}
                    />
                  </div>
                );
              })}
        </ul>
      );
    } else {
      recentListings = null;
    }

    return (
      <div className={css.recentlyListed}>
        <DividerTitle title={title} />
        {recentListings}
      </div>
    );
  }
}

export default SectionRecentlySold;
