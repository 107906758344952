import React from 'react';
import classNames from 'classnames';
import {
  LayoutWrapperTopbar, 
  LayoutSingleColumn,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Page,
  Footer,
  SectionHero,
  SectionSteps,
  SectionTestimonial,
	SectionTextOverImage
} from "../../components";
import {isScrollingDisabled} from "../../ducks/UI.duck";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {TopbarContainer} from "../index";
import css from "./HowItWorks.css";
import facebookImage from "../../assets/The_Octopus_Club-01.png";
import config from "../../config";
import twitterImage from "../../assets/The_Octopus_Club-01.png";

// Content
import heroContent from '../../content/how-it-works-hero.json';
import sellContent from '../../content/how-it-works-sell-content.json';
import buyContent from '../../content/how-it-works-buy-content.json';
import donateContent from '../../content/how-it-works-donate-content.json';
import resellContent from '../../content/how-it-works-resell-content.json';
import testimonial1 from '../../content/how-it-works-testimonial-1.json';
import testimonial2 from '../../content/how-it-works-testimonial-2.json';
import testimonial3 from '../../content/how-it-works-testimonial-3.json';

import iHaveMoreQuestions from '../../content/how-it-works-i-have-more-questions.json';

const HowItWorks = ({scrollingDisabled}) => {


  return <Page
    title={"How it works"}
    scrollingDisabled={scrollingDisabled}
  >

    <LayoutSingleColumn>
      <LayoutWrapperTopbar>
        <TopbarContainer />
      </LayoutWrapperTopbar>
      <LayoutWrapperMain>
      
        <SectionHero
            className={css.hero}
            title={heroContent.title}
            picture={heroContent.picture}
          />
          
        <div className={css.anchorNav}>
          <ul>
            <li><a className={classNames(css.button, css.buttonYellow)} href="#sell">I have items to sell</a></li>
            <li><a className={classNames(css.button, css.buttonPink)} href="#buy">I want to buy</a></li>
            <li><a className={classNames(css.button, css.buttonGreen)} href="#donate">I want to donate</a></li>
            <li><a className={classNames(css.button, css.buttonYellow)} href="#resell">I want to resell</a></li>
          </ul>
        </div>

        {/* SELL */}
        <SectionSteps 
              className={css.steps}
              title={sellContent.title}
              copy={sellContent.copy}
              namedLink={sellContent.namedLink}
              namedLinkSlug={sellContent.namedLinkSlug}
              namedLinkTo={sellContent.namedLinkTo}
              linkLabel={sellContent.linkLabel}
              steps={sellContent.steps}
              color={sellContent.color}
              contrast={sellContent.contrast}
							anchor="sell"
          /> 

        <SectionTestimonial
              quote={testimonial1.quote}
              author={testimonial1.author}
              color={testimonial1.color}
              contrast={testimonial1.contrast}
							anchor="give"
            />

        {/* BUY */}
        <SectionSteps 
              className={css.steps}
              title={buyContent.title}
              copy={buyContent.copy}
              namedLink={buyContent.namedLink}
              namedLinkSlug={buyContent.namedLinkSlug}
              linkLabel={buyContent.linkLabel}
              steps={buyContent.steps}
              color={buyContent.color}
              contrast={buyContent.contrast}
							anchor="buy"
          /> 

        <SectionTestimonial
              quote={testimonial2.quote}
              author={testimonial2.author}
              color={testimonial2.color}
              contrast={testimonial2.contrast}
            />

        {/* DONATE */}
        <SectionSteps 
              className={css.steps}
							title={donateContent.title}
							copy={donateContent.copy}
              namedLink={donateContent.namedLink}
              namedLinkSlug={donateContent.namedLinkSlug}
              namedLinkTo={donateContent.namedLinkTo}
              linkLabel={donateContent.linkLabel}
							steps={donateContent.steps}
							color={donateContent.color}
							contrast={donateContent.contrast}
							anchor="donate"
					/> 

        <SectionTestimonial
              quote={testimonial3.quote}
              author={testimonial3.author}
              color={testimonial3.color}
              contrast={testimonial3.contrast}
            />

        {/* RESELL */}
        <SectionSteps 
          className={css.steps}
          title={resellContent.title}
          copy={resellContent.copy}
          namedLink={resellContent.namedLink}
          namedLinkSlug={resellContent.namedLinkSlug}
          linkLabel={resellContent.linkLabel}
          steps={resellContent.steps}
          color={resellContent.color}
          contrast={resellContent.contrast}
          anchor="resell"
          /> 

        {/* I HAVE MORE QUESTIONS */}

				<SectionTextOverImage     
						className={css.text}
						title={iHaveMoreQuestions.title}
						copy={iHaveMoreQuestions.copy}
						namedLink={iHaveMoreQuestions.namedLink}
						namedLinkSlug={iHaveMoreQuestions.namedLinkSlug}
						linkLabel={iHaveMoreQuestions.linkLabel}
						color={iHaveMoreQuestions.color}
						contrast={iHaveMoreQuestions.contrast}
						layout={iHaveMoreQuestions.layout}
						image={iHaveMoreQuestions.image}
						imageSrcset={iHaveMoreQuestions.imageSrcset}
						imageAlt={iHaveMoreQuestions.imageAlt}
					/>

      </LayoutWrapperMain>

      <LayoutWrapperFooter className={css.mobileView}>
            <Footer  />
      </LayoutWrapperFooter>
    </LayoutSingleColumn>
  </Page>

}

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

export default connect(mapStateToProps)(HowItWorks);
