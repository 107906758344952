import React, { Component } from 'react';
import { arrayOf, bool, func, shape, string, node, object } from 'prop-types';
import { propTypes } from '../../util/types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { parse } from '../../util/urlHelpers';
import { Form as FinalForm } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';

import {
  SelectMultipleFilter,
  Button,
  IconClose,
  IconArrowHead,
  FieldCheckbox,
  Form,
} from '../../components';

import css from './SelectSingleFilterPlain.css';
import {subCategories} from "../../marketplace-custom-config";
const FILTER_DROPDOWN_OFFSET = -14;

const getQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
};

class SelectSingleFilterPlainComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: true };
    this.selectOption = this.selectOption.bind(this);
    this.toggleIsOpen = this.toggleIsOpen.bind(this);
  }

  selectOption(option, e) {
    const { queryParamNames, onSelect } = this.props;
    const queryParamName = getQueryParamName(queryParamNames);

    onSelect({ [queryParamName]: option });

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  }

  toggleIsOpen() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    const {
      rootClassName,
      className,
      options,
      queryParamNames,
      initialValues,
      twoColumns,
      useBullets,
      subCategoriesFilter,
      initialSubCategories,
      onSubCategoriesSelect,
      ages,
      intl,
      redirectToURLWithoutModalState,
      location,
      onResultButtonClick,
      urlQueryParams,
      hideSoldItems,
      hideSold,
      isMobileLayout,
    } = this.props;

    const { everything } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    const subCategoriesLabel = intl.formatMessage({ id: 'SearchFilters.subCategoriesLabel' });
    const queryParamName = getQueryParamName(queryParamNames);
    const initialValue =
      initialValues && initialValues[queryParamName] ? initialValues[queryParamName] : null;
    const labelClass = initialValue ? css.filterLabelSelected : css.filterLabel;

    const hasBullets = useBullets || twoColumns;
    const optionsContainerClass = classNames(css.optionsContainer, {
      [css.optionsContainerOpen]: this.state.isOpen,
      [css.optionsContainerClosed]: !this.state.isOpen,
      [css.hasBullets]: hasBullets,
      [css.twoColumns]: twoColumns,
    });

    const classes = classNames(rootClassName || css.root, className);

    return (
      <div className={classes}>
        <div className={optionsContainerClass}>
          <Button
            onClick={() => redirectToURLWithoutModalState('browsecategories')}
            rootClassName={css.closeFilterButton}
          >
            <span className={css.closeText}>
              <FormattedMessage id="Modal.close" />
            </span>
            <IconClose rootClassName={css.closeIcon} />
          </Button>
          <span className={css.resultText} onClick={onResultButtonClick}>
            <FormattedMessage id="Modal.result" />
            <IconArrowHead rootClassName={css.arrowIcon} />
          </span>
          {options.map(option => {
            // check if this option is selected
            const selected =
              initialValue === null && option.key === 'everything'
                ? everything && everything === 'open' && true
                : initialValue === option.key;
            const optionClass = selected ? css.optionSelected : css.option;
            // menu item selected bullet or border class
            const optionBorderClass = hasBullets
              ? classNames({
                  [css.optionBulletSelected]: selected,
                  [css.optionBullet]: !selected,
                })
              : classNames({
                  [css.optionBorderSelected]: selected,
                  [css.optionBorder]: !selected,
                });

            const subCategoriesClasss = classNames({
              [css.amenitiesFilterWrapper]: true,
              [css.amenitiesFilterWrapperOpen]: true && selected,
            });

            const optionSubCategories = [...option.subCategories];
            let optionAges = option.ages ? [ages] : [];

            if (option.key === 'wear') {
              optionAges[0] = optionAges[0].filter(item => {
                return item.key.includes('month') || item.wear == true;
              });
            } else if (optionAges[0]) {
              optionAges[0] = optionAges[0].filter(item => {
                return item.key.includes('month') || !item.wear;
              });
            }

            return (
              <div className={css.optionWrap} key={option.key}>
                {option.key === 'age' && <div className={css.divider}></div>}
                <button
                  className={optionClass}
                  onClick={() =>
                    selected
                      ? this.selectOption(option.key, selected)
                      : this.selectOption(option.key)
                  }
                >
                  <span className={optionBorderClass} />
                  <span className={css.optionText}>{option.label}</span>
                </button>
                <div className={subCategoriesClasss}>
                  <SelectMultipleFilter
                    id={'SearchFilters.subCategoriesFilter'}
                    name="ages"
                    label={subCategoriesLabel}
                    onSubmit={onSubCategoriesSelect}
                    options={optionAges}
                    initialValues={urlQueryParams}
                    contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
                    keyPrefix={option.key}
                    queryParamNames={['pub_subCategories']}
                    clean
                  />
                  <SelectMultipleFilter
                    id={'SearchFilters.subCategoriesFilter'}
                    name="subCategories"
                    label={subCategoriesLabel}
                    onSubmit={onSubCategoriesSelect}
                    options={optionSubCategories}
                    initialValues={urlQueryParams}
                    contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
                    keyPrefix={option.key}
                    queryParamNames={['pub_subCategories']}
                    clean
                  />
                </div>
              </div>
            );
          })}
          {isMobileLayout && (
            <FinalForm
              initialValues={{ sold: hideSold }}
              render={props => {
                return (
                  <Form>
                    <FieldCheckbox
                      id="sold"
                      name="sold"
                      label={<FormattedMessage id="SearchFilters.hideSoldItems" />}
                      textClassName={css.itemText}
                    />
                    <OnChange name="sold">
                      {(value, previous) => {
                        hideSoldItems(true);
                      }}
                    </OnChange>
                  </Form>
                );
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

SelectSingleFilterPlainComponent.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  twoColumns: false,
  useBullets: false,
  subCategoriesFilter: null,
  initialSubCategories: {},
  onSubCategoriesSelect: () => {},
};

SelectSingleFilterPlainComponent.propTypes = {
  rootClassName: string,
  className: string,
  queryParamNames: arrayOf(string).isRequired,
  label: node.isRequired,
  onSelect: func.isRequired,

  options: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
      subCategories: arrayOf(arrayOf(shape({ key: string.isRequired, label: toString.isRequired }))),
    })
  ).isRequired,
  initialValues: object,
  twoColumns: bool,
  useBullets: bool,
  subCategoriesFilter: propTypes.filterConfig,
  initialSubCategories: object,
  onSubCategoriesSelect: func,
  // from injectIntl
  intl: intlShape.isRequired,
};

const SelectSingleFilterPlain = injectIntl(SelectSingleFilterPlainComponent);
export default SelectSingleFilterPlain;
