import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import range from 'lodash/range';
import { Button } from '../../components';
import { stringify } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';

import css from './LoadMore.css';

const { string, object } = PropTypes;


export const LoadMoreComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    pageName,
    pagePathParams,
    pageSearchParams,
    pagination,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <nav className={classes}>
      
      <div className={css.information}>Showing 20 of 2,013</div>
      <progress className={css.progress} value="32" max="100" />      
      <Button
          className={css.loadMoreButton}
          type="button"
        >Show more</Button>
    </nav>
  );
};

LoadMoreComponent.defaultProps = {
  className: '',
  rootClassName: '',
  pagePathParams: {},
  pageSearchParams: {},
};

LoadMoreComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  pageName: string.isRequired,
  pagePathParams: object,
  pageSearchParams: object,
  pagination: propTypes.pagination.isRequired,
};

export default injectIntl(LoadMoreComponent);
