import React from 'react';
import { string, array, image } from 'prop-types';
import classNames from 'classnames';

import { NamedLink } from '../../components/';

import css from './SectionSteps.css';


const SectionSteps = props => {
  const { 
    rootClassName, 
    className, 
    title,
    copy,
    namedLink,
    namedLinkSlug,
    namedLinkTo,
    linkLabel,
    steps,
    color,
    contrast,
    style,
    layout,
    anchor
  } = props;

  const classes = classNames(rootClassName || css.root, className, color, contrast);
  const id = anchor ? anchor : null;
  
  return (
    <div className={classes} id={id}>
      <div className={css.container}>
        <h2 className={css.title}> { title} </h2>
        <div className={css.intro} dangerouslySetInnerHTML={{ __html: copy }}></div>
        <ul className={css.steps}>
          {steps && (
              steps.map((step, index) => {
                let stepIcon = `icon-${step.icon}`;
                return (
                  <li>
                    <div className={css.step}>
                      <div className={css.stepIcon}>
                        <img src={step.icon} />
                        </div>
                      <h3 className={css.stepTitle} dangerouslySetInnerHTML={{ __html: step.title }}></h3>
                      <div dangerouslySetInnerHTML={{ __html: step.copy }}></div>
                    </div>
                  </li>
              )})
            )}
        </ul>
        { namedLink && 
        <div className={css.footer}>
          <NamedLink
            className={css.button}
            name={namedLink}
            params={{ slug: namedLinkSlug }}
            to={{ state: { type: namedLinkTo } }}
            >
                { linkLabel }
          </NamedLink>
        </div>
        }
      </div>
    </div>
  );
};

SectionSteps.defaultProps = { 
  rootClassName: null, 
  className: null,
  title: null,
  copy: null,
  namedLink: null,
  namedLinkSlug: null,
  namedLinkTo: null,
  linkLabel: null,
  steps: null,
  color: null,
  contrast: 'positive',
  style: null,
  layout: null,
  anchor: null
 };

SectionSteps.propTypes = {
  rootClassName: string,
  className: string,
  title: string,
  copy: string,
  namedLink: string,
  namedLinkSlug: string,
  namedLinkTo: string,
  linkLabel: string,
  steps: array,
  color: string,
  contrast: string,
  style: string,
  layout: string,
  image: image,
  anchor: string
};

export default SectionSteps;
