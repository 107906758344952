import React from 'react';
import { string, array, image } from 'prop-types';
import classNames from 'classnames';

import { ContentCard } from '../../components/';

import css from './SectionContentRail.css';

const SectionContentRail = props => {
  const { 
    rootClassName, 
    className, 
    content
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  const contentBoxes = content.boxes.map((box, index) => {
    return(
      <ContentCard 
        title={box.title}
        copy={box.copy}
        linkName={box.linkName}
        search={box.search}
        linkLabel={box.linkLabel}
        img={box.image}
        srcset={box.srcset}
      />
    )
  })
  

  return (
    <div className={classes}>
      <div className={css.content}>
       {contentBoxes}
     </div>
    </div>
  );
};

SectionContentRail.defaultProps = { 
  rootClassName: null, 
  className: null,
  title: null,
  content: null
 };

SectionContentRail.propTypes = {
  rootClassName: string,
  className: string,
  content: array
};

export default SectionContentRail;
