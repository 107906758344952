import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import IconLogo from './IconLogo';
import css from './Logo.css';

const Logo = props => {
  const { className, format, ...rest } = props;
  return <IconLogo 
    className={className} 
    {...rest} />;
};

const { oneOf, string } = PropTypes;

Logo.defaultProps = {
  className: null,
  alt: null
};

Logo.propTypes = {
  className: string,
  alt: string
};

export default Logo;
