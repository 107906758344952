import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconMap.css';

const IconMap = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={css.icon} viewBox="0 0 15 20" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.34 0C3.3 0 0 3.46 0 7.66c0 5.761 6.2 11.742 6.2 11.742.82.76 1.32.82 2.221 0 0 0 6.26-5.98 6.26-11.741 0-4.2-3.3-7.661-7.34-7.661Zm0 10.801c-1.92 0-3.46-1.56-3.46-3.46 0-1.92 1.54-3.48 3.46-3.48 1.921 0 3.481 1.56 3.481 3.48 0 1.9-1.56 3.46-3.48 3.46Z"/>
  </svg>
  );
};

IconMap.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconMap.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconMap;
