import React from 'react';
import { SelectMultipleFilter } from '../../components';
import css from './AttributeFilter.css';

const AttributeFilter = props => {
  const { label, options } = props;

  return (
    <div className={css.filterWrapper}>
      <SelectMultipleFilter label={label} options={options} />
    </div>
  );
};

export default AttributeFilter;
