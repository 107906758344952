import React from 'react';
import { string, image } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import { NamedLink } from '..';

import css from './ContentCard.css';

const ContentCard = props => {
  const { 
    rootClassName, 
    className, 
    title,
    copy,
    linkName,
    search,
    linkLabel,
    img,
    srcset
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  let cardImage;

  if (linkName) {
     
    cardImage =  
     <NamedLink name={linkName} to={{ search }}>
          <img 
            src={img}
            srcset={srcset}
            width="400"
            height="400"
            alt=""
          />
    </NamedLink>

    } else {
      
    cardImage = 
      <img 
        src={img}
        srcset={srcset}
        loading="lazy"
        width="400"
        height="400"
        alt=""
      />
    }
  

  return (
    <div className={classes}>
      <div className={css.image}>
        { cardImage }
      </div>
      <div className={css.copy}>
          <h2 className={css.title}> {title}</h2>
          { copy &&
          <div dangerouslySetInnerHTML={{ __html: copy }}></div>
          }
     </div>
     { linkName && 
     <div className={css.footer}>
        <NamedLink 
          name={linkName} to={{ search }}
          className={css.button}
          >
          { linkLabel }
        </NamedLink>
      </div>
     }
    </div>
  );
};

ContentCard.defaultProps = { 
  rootClassName: null, 
  className: null,
  title: null,
  copy: null,
  linkName: null,
  search: null,
  linkLabel: null,
  img: null,
  srcset: null
 };

ContentCard.propTypes = {
  rootClassName: string,
  className: string,
  title: string,
  copy: string,
  linkName: string,
  search: string,
  linkLabel: string,
  img: image,
  srcset: image

};

export default ContentCard;
