import React, { Component } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { ListingCard } from '../../components';
import css from './SectionRecentlyListed.css';

class SectionRecentlyListed extends Component {
  render() {
    const { history } = this.props;
    const dataFetched = this.props.listings;
    let recentListings;

    const clickSoldListing = (sold, category) => {
      if (sold) {
        history.push(
          createResourceLocatorString(
            'SearchPage',
            routeConfiguration(),
            {},
            { pub_category: category }
          ),
          {
            sold: true,
          }
        );
      }
    };

    if (dataFetched) {
      recentListings = (
        <ul className={css.listingCards}>
          {this.props.listings.map((l, i) => {
            const { sold, category } = l.attributes.publicData;
            return (
              <li
                key={i}
                className={css.listingContainer}
                onClick={() => clickSoldListing(sold, category[0])}
              >
                <ListingCard
                  className={css.listingCard}
                  key={l.id.uuid}
                  listing={l}
                  dontClickSold={sold}
                />
              </li>
            );
          })}
        </ul>
      );
    } else {
      recentListings = null;
    }

    const title = <FormattedMessage id="LandingPage.featuredItems" />;
    const copy = <FormattedMessage id="LandingPage.featuredItemsCopy" />;
    return (
      <div className={css.recentlyListed}>
        <h2 title={css.title}> {title} </h2>
        <p>{copy}</p>
        {recentListings}
      </div>
    );
  }
}

export default SectionRecentlyListed;
