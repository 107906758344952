import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureListing } from '../../util/data';
import { EditListingFeaturesForm } from '../../forms';
import { ListingLink } from '../../components';

import css from './EditListingFeaturesPanel.css';

import config from '../../config';
import {subCategories} from "../../marketplace-custom-config";

const FEATURES_NAME = 'subCategories';

const EditListingFeaturesPanel = props => {
  const {
    rootClassName,
    className,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const { publicData, title } = currentListing.attributes;

  const shoeSizeOptions = config.custom.shoeSizes;
  const maternitySizeOptions = config.custom.maternitySizes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingFeaturesPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingFeaturesPanel.createListingCategoryTitle" />
  );

  const subCategories = publicData && publicData.subCategories;
  const category = publicData && publicData.category;
  const ages = publicData && publicData.ages;
  const genders = publicData && publicData.genders;
  const seasons = publicData && publicData.seasons;
  const condition = publicData && publicData.condition;

  const initialValues = {
    subCategories,
    ages,
    genders,
    seasons,
    condition,
    category:
      category && category.length ? (typeof category === 'object' ? category : [category]) : null,
  };

  const formatAge = value => {
    let newValue = value;

    if (value.includes('month')) {
      newValue = newValue.replace('month-', '') + 'm';
    } else if (value.includes('age')) {
      newValue = newValue.replace('age-', '') + 'y';
    }

    return newValue;
  };

  const formatShoeSize = value => {
    let newValue = value;

    const sizeOption = shoeSizeOptions.find(o => o.key === value);

    newValue = sizeOption ? sizeOption.label : '';

    return newValue;
  };

  const formatMaternitySize = value => {
    let newValue = value;

    const sizeOption = maternitySizeOptions.find(o => o.key === value);

    newValue = sizeOption ? sizeOption.label : '';

    return newValue;

  }

  let oldTitle = title;

  ages &&
    ages.map(item => {
      // remove formatted age on the description to avoid redundant text
      oldTitle = oldTitle.replace(formatAge(item), '');
      oldTitle = oldTitle.replace(formatShoeSize(item), '');
      oldTitle = oldTitle.replace(formatMaternitySize(item), '');
    });

  return (
    <div className={classes}>
      <h2 className={css.title}>{panelTitle}</h2>
      <EditListingFeaturesForm
        className={css.form}
        name={FEATURES_NAME}
        initialValues={initialValues}
        onSubmit={values => {
          const { subCategories = [], ages = [], category = [], genders = [], seasons = [], condition = [] } = values;
          let newTitle = oldTitle.trim();

          // trim any space after mapping ages
          newTitle = newTitle.trim();

          const selectedAges = ages.filter(a => a.includes('age-') || a.includes('month-'));
          if (selectedAges.length > 0) {
            newTitle += ' ' + selectedAges.map(item => formatAge(item)).join(' ');
          }

          // trim any space after mapping shoe sizes
          newTitle = newTitle.trim();

          const selectedShoeSizes = ages.filter(a => a.includes('wear-shoesizes-'));
          if (selectedShoeSizes.length > 0) {
            newTitle += ' ' + selectedShoeSizes.map(item => formatShoeSize(item)).join(' ');
          }

          // trim any space after mapping shoe sizes
          newTitle = newTitle.trim();

          const selectedMaternitySizes = ages.filter(a => a.includes('wear-maternity-'));
          if (selectedMaternitySizes.length > 0) {
            newTitle += ' ' + selectedMaternitySizes.map(item => formatMaternitySize(item)).join(' ');
          }

          // trim any space after mapping maternity
          newTitle = newTitle.trim();

          const updatedValues = {
            publicData: { subCategories, ages, category, seasons, genders, condition },
            title: newTitle,
          };

          onSubmit(updatedValues);
        }}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
      />
    </div>
  );
};

EditListingFeaturesPanel.defaultProps = {
  rootClassName: null,
  className: null,
  listing: null,
};

const { bool, func, object, string } = PropTypes;

EditListingFeaturesPanel.propTypes = {
  rootClassName: string,
  className: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingFeaturesPanel;
