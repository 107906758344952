import React from 'react';
import {
  Button
} from '../../components';

import css from './ListingPage.css';
import IconMapMarker from '../../components/ListingCard/IconMapMarker';

const SectionFeaturesMaybe = props => {
  const { options, publicData, postagePrice, isGive, toggleMap, mapVisibility } = props;

  if (!publicData) {
    return null;
  }
  

  const { brand, location, color, size, age, collectionInPerson, collection } = publicData;

  const isBrandApplicable = !(brand === 'Unknown');
  const isSizeApplicable = !!(size === null);
  const isAgeApplicable = !(age === 'not applicable');

  const isCollectionAnObject = collection && typeof collection === 'object';
  const isCollectionInPersonAnObject = collectionInPerson && typeof collectionInPerson === 'object';

  const isCollectionOnly =
    collection === 'collection' ||
    !!(isCollectionAnObject ? collection && collection.length : collection === 'collection');

  const isPostageOnly =
    collection === 'postage' ||
    (isCollectionInPersonAnObject
      ? !isCollectionOnly && !(collectionInPerson && collectionInPerson.length)
      : collection === 'postage');

  const isCollectionAndPostage =
    collection === 'collection-and-postage' || (!isCollectionOnly && !isPostageOnly);

  return (

    <div className={css.sectionFeatures}>
    <table className={css.sectionFeaturesTable}>
      <tbody>
      {isBrandApplicable ? 
        <tr className={css.featuresItem}>
          <th>Brand</th>
          <td>{brand}</td>
        </tr>
      : null }
        <tr className={css.featuresItem}>
          <th>Colour</th>
          <td>{color}</td>
        </tr>
      {isSizeApplicable ? 
        <tr className={css.featuresItem}>
          <th>Size</th>
          <td>{size}</td>
        </tr>
      : null }
      {isAgeApplicable ? 
        <tr className={css.featuresItem}>
          <th>Age</th>
          <td>{age}</td>
        </tr>
      : null }
      {isCollectionOnly || isCollectionAndPostage ? (
        <tr className={css.featuresItem}>
          <th>Location</th>
          <td>{location.address}<br />
            <a className={css.mapToggle}
              onClick={toggleMap}>
              {mapVisibility ? 'Hide map' : 'View map'}
            </a>
          </td>
        </tr>
      ) : null}
      {isCollectionOnly ? 
        <tr className={css.featuresItem}>
          <th></th>
          <td>Collection in person only</td>
        </tr> : null}
        <tr className={css.featuresItem}>
          <th>Postage</th>
          <td>
          {isPostageOnly || isCollectionAndPostage ? (
            <>
              {postagePrice}
            </>
          ) : null}
          {isCollectionAndPostage ? (
            <span className={css.collection}>Collection in person available</span>
          ) : null}
            <span className={css.returns}>Returns not accepted</span>
          </td>
        </tr>
      </tbody>
    </table>
    </div>

      );
};

export default SectionFeaturesMaybe;
