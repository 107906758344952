import React from 'react';
import {TopbarContainer} from '../../containers';
import {
  NamedLink,
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import {isScrollingDisabled} from '../../ducks/UI.duck';

import css from './OctoplusPage.css';

import {withRouter} from 'react-router-dom';
import {injectIntl} from '../../util/reactIntl';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {loadData} from './OctoplusPage.duck';
import {hasAccessToOctoplus, octoplusAccessUntil, octplusAccessUntilDays} from '../../util/user';

// Assets
import octoplusLogoImage from './octoplus-logo-white.svg';
import imagePlaceholder from './placeholder.jpg';
import logoPlaceholder from './logoPlaceholder.png';
import PropTypes from "prop-types";

const OctoplusPageComponent = props => {
  const {
    intl,
    scrollingDisabled,
    partners,
    loadPartnersInProgress,
    loadPartnersError,
    isAuthenticated,
    currentUser,
  } = props;

  // TODO: possible need to add in extra schema stuff
  const title = intl.formatMessage({id: 'OctoplusPage.title'});

  const renderJoinButton = () => {
    if (!currentUser) {
      return (
        <p>
          <NamedLink name="SignupPage" className={css.button}>
            Join our club for free
          </NamedLink>
        </p>
      )
    } else if (!hasAccessToOctoplus(currentUser)) {
      return (
        <p>
          <NamedLink name="SearchPage" className={css.button}>
            Start buying & selling
          </NamedLink>
        </p>
      )
    }
  }

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer/>
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.wrapper}>
          <div className={css.header}>
            {/* Logo */}
            <h1 className={css.octoplusLogo}>
              <img src={octoplusLogoImage} alt="Octoplus"/>
            </h1>

            {/* Intro */}
            <div className={css.headerIntro}>
              <p>Our favourite brands for less</p>
            </div>
          </div>

          <div className={css.verticallySpaced}>
            <div className={css.container}>
              {hasAccessToOctoplus(currentUser) ? (
                <>
                  <p>Woohoo! You are an active buyer or seller on The Octopus Club, so you get to access our favourite
                    brands, for less.</p>
                  <p>Your access will expire
                    in {octplusAccessUntilDays(currentUser)} {octplusAccessUntilDays(currentUser) > 1 ? "days" : "day"}.</p>
                  {/* <p>Access expires on {octoplusAccessUntil(currentUser)}</p> */}
                  <p>Every time you buy or sell something on The Octopus Club, you have access to all of the discounts
                    codes for the next 30 days. Each new transaction refreshes your access for 30 days.</p>
                </>
              ) : (
                <>
                  <p>
                    If you are an active buyer or seller on The&nbsp;Octopus&nbsp;Club,
                    you get access to our favourite brands, for less.
                  </p>

                  {renderJoinButton()}

                  <p>Every time you buy or sell something on The Octopus Club,
                    <br/>you have access to all of the discount codes for the next 30 days*.
                    <br/>Each new transaction refreshes your access for 30 days.
                  </p>
                  <div className={css.note}>
                    <p>*Based on transactions happening after launch date, 30/05/2022.</p>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className={css.perks}>
            <div className={css.perksHeader}>Check out our partners and their unique deals, just for us:</div>

            <ul className={css.perksList}>
              {partners && (
                partners.map((partner) => {
                  let id = partner.id;
                  let slug = partner.slug;

                  return (
                    <li>
                        <div className={css.perkCard}>
                          {/* Image */}
                          <div className={css.perkCardImage}>
                            <img src={partner.partnerFeatureImage[0].large1x }
                              srcSet={`${partner.partnerFeatureImage[0].large1x } 1x, ${partner.partnerFeatureImage[0].large2x } 2x`}
                              width="350"
                              height="350"
                              alt="" />
                          </div>
                          <div className={css.perkCardInfo}>
                            {/* Logo */}
                            <div className={css.perkCardLogo}>
                              <img src={partner.partnerLogo[0].large1x}
                                srcSet={`${partner.partnerLogo[0].large1x } 1x, ${partner.partnerLogo[0].large2x } 2x`}
                                width="160"
                                height="160"
                                alt="" />
                            </div>
                            {/* Copy */}
                            <div className={css.perkCardCopy}>
                              <NamedLink
                                name={'OctoplusPartnerPage'}
                                params={{ id, slug }}
                              >
                                <div dangerouslySetInnerHTML={{__html:partner.partnerDiscountSummaryTop}}></div>
                                <div className={css.perkCardCopyDiscount}>{partner.partnerDiscountSummaryMiddle}</div>
                                <div dangerouslySetInnerHTML={{__html:partner.partnerDiscountSummaryBottom}}></div>
                              </NamedLink>
                            </div>
                          </div>
                        </div>
                    </li>
                  )
                })
              )}
            </ul>
          </div>

          <div className={css.verticallySpaced}>
            <div className={css.container}>
              {renderJoinButton()}

              <p>
                <NamedLink name="GhostPage" params={{ slug: 'octoplusterms' }}>
                  Octoplus T&amp;Cs
                </NamedLink>
              </p>
            </div>
          </div>

        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer/>
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
}

const {bool} = PropTypes;

OctoplusPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
};

const mapStateToProps = state => {
  const {isAuthenticated} = state.Auth;
  const {currentUser} = state.user;

  const {
    loadPartnersInProgress,
    loadPartnersError,
    partners,
  } = state.OctoplusPage;

  return {
    loadPartnersInProgress,
    loadPartnersError,
    partners,
    isAuthenticated,
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const OctoplusPage = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(OctoplusPageComponent);

OctoplusPage.loadData = loadData;

export default OctoplusPage;
