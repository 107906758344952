import React from 'react';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';

import css from './GhostPage.css';
import config from '../../config';

import { withRouter } from 'react-router-dom';
import { injectIntl } from '../../util/reactIntl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { selectPageBySlug } from '../../ducks/ghost.duck';
import OurStoryMain from "../OurStory/OurStoryMain";
import OurStoryTop from "../OurStory/OurStoryTop";

const GhostPageComponent = props => {
  const { page, intl } = props;

  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'TermsOfServicePage.schemaTitle' }, { siteTitle });
  const schema = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    description: page.excerpt,
    name: schemaTitle,
  };
  // prettier-ignore
  return (
    <StaticPage
      title={page.title}
      schema={schema}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain >

          {page.slug === 'my-story' && <><OurStoryTop /><div class={css.extraPadding} /></>}

          <div className={css.staticPageWrapper}>
            <h1 className={css.pageTitle}>{page.title}</h1>
            {page.feature_image ? <img className={css.coverImage} src={page.feature_image} alt={page.title} /> : null}

          <div className={css.contentWrapper}>
            {page.excerpt ? <div className={css.contentSide}>
              <p>{page.excerpt}</p>
            </div> : null}

            <div className={css.contentMain} dangerouslySetInnerHTML={{ __html: page.html }}>
            </div>


          </div>

          </div>

          {page.slug === 'my-story' && <><div className={css.extraPadding}/><OurStoryMain /></>}

        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

const mapStateToProps = state => {
  const { ghostActivePage } = state.ghost;

  return {
    page: ghostActivePage,
  };
};

const mapDispatchToProps = dispatch => ({});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const GhostPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(GhostPageComponent);

GhostPage.loadData = (params, search) => {
  const { slug } = params;

  return selectPageBySlug(slug);
};

export default GhostPage;
