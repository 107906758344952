import React from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';
import { Field } from 'react-final-form';

import css from './FieldCheckbox.css';

const IconCheckbox = props => {
  const { className, checkedClassName, boxClassName } = props;
  return (
    <svg className={className} width="14" height="14" xmlns="http://www.w3.org/2000/svg">
          <path 
            className={checkedClassName || css.checked}
            d="m4 0h6c2.2 0 4 1.8 4 4v6c0 2.2-1.8 4-4 4h-6c-2.2 0-4-1.8-4-4v-6c0-2.2 1.8-4 4-4z" 
            fill="currentColor"
          />
          <path 
            d="m5.8 9.4.7-.7m0 0 4.7-4.7m-4.7 4.7.7.7m-.7-.7-2.5-2.6" 
            fill="none" 
            stroke="#fff" 
            stroke-width="2"
          />

          <path 
            className={boxClassName || css.box}
            d="m4 .5h6c1.9 0 3.5 1.6 3.5 3.5v6c0 1.9-1.6 3.5-3.5 3.5h-6c-1.9 0-3.5-1.6-3.5-3.5v-6c0-1.9 1.6-3.5 3.5-3.5z" 
            fill="none" 
            stroke="currentColor" />
    </svg>
  );
};

IconCheckbox.defaultProps = { className: null, checkedClassName: null, boxClassName: null };

IconCheckbox.propTypes = { className: string, checkedClassName: string, boxClassName: string };

const FieldCheckboxComponent = props => {
  const {
    rootClassName,
    className,
    svgClassName,
    textClassName,
    id,
    label,
    useSuccessColor,
    ...rest
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const checkboxProps = {
    id,
    className: css.input,
    component: 'input',
    type: 'checkbox',
    ...rest,
  };

  const successColorVariantMaybe = useSuccessColor
    ? {
        checkedClassName: css.checkedSuccess,
        boxClassName: css.boxSuccess,
      }
    : {};

  return (
    <span className={classes}>
      <Field {...checkboxProps} />
      <label htmlFor={id} className={css.label}>
        <span className={css.checkboxWrapper}>
          <IconCheckbox className={svgClassName} {...successColorVariantMaybe} />
        </span>
        <span className={classNames(css.text, textClassName || css.textRoot)}>{label}</span>
      </label>
    </span>
  );
};

FieldCheckboxComponent.defaultProps = {
  className: null,
  rootClassName: null,
  svgClassName: null,
  textClassName: null,
  label: null,
};

FieldCheckboxComponent.propTypes = {
  className: string,
  rootClassName: string,
  svgClassName: string,
  textClassName: string,

  // Id is needed to connect the label with input.
  id: string.isRequired,
  label: node,

  // Name groups several checkboxes to an array of selected values
  name: string.isRequired,

  // Checkbox needs a value that is passed forward when user checks the checkbox
  value: string.isRequired,
};

export default FieldCheckboxComponent;
