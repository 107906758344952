/**
 * Creates a sortable image grid with children added to the end of the created grid.
 *
 * Example:
 * // images = [{ id: 'tempId', imageId: 'realIdFromAPI', file: File }];
 * <AddImages images={images}>
 *   <input type="file" accept="images/*" onChange={handleChange} />
 * </AddImages>
 */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ImageFromFile, ResponsiveImage, IconSpinner } from '../../components';

import css from './AddImages.css';
import RemoveImageButton from './RemoveImageButton';
import RotateImageButton from './RotateImageButton';
import Sortable from 'sortablejs';

export const ThumbnailWrapper = props => {
  const {
    className,
    image,
    savedImageAltText,
    onRemoveImage,
    onRotateImage,
  } = props;
  const handleRemoveClick = e => {
    e.stopPropagation();
    onRemoveImage(image.id);
  };
  const handleRotateClick = e => {
    e.stopPropagation();
    onRotateImage(image);
  };

  // image.file = '<value is too large to edit>';

  if (image.file) {
    // Add remove button only when the image has been uploaded and can be removed
    const removeButton = image.imageId ? (
      <RemoveImageButton onClick={handleRemoveClick} />
    ) : null;
    const rotateButton = image.imageId ? (
      <RotateImageButton onClick={handleRotateClick} />
    ) : null;

    // While image is uploading we show overlay on top of thumbnail
    const uploadingOverlay = !image.imageId ? (
      <div className={css.thumbnailLoading}>
        <IconSpinner />
      </div>
    ) : null;

    return (
      <ImageFromFile
        key={image.id}
        id={image.id}
        className={className}
        rootClassName={css.thumbnail}
        file={image.file}
      >
        {removeButton}
        {rotateButton}
        {uploadingOverlay}
      </ImageFromFile>
    );
  } else {
    const classes = classNames(css.thumbnail, className);
    return (
      <div className={classes}>
        <div className={css.threeToTwoWrapper}>
          <div className={css.aspectWrapper}>
            <ResponsiveImage
              rootClassName={css.rootForImage}
              image={image}
              alt={savedImageAltText}
              variants={['scaled-small', 'scaled-medium']}
            />
          </div>
          <RemoveImageButton onClick={handleRemoveClick} />
        </div>
      </div>
    );
  }
};

ThumbnailWrapper.defaultProps = { className: null };

const { array, func, node, string, object } = PropTypes;

ThumbnailWrapper.propTypes = {
  className: string,
  image: object.isRequired,
  savedImageAltText: string.isRequired,
  onRemoveImage: func.isRequired,
};

const AddImages = props => {
  const {
    children,
    className,
    classNameWrap,
    thumbnailClassName,
    images,
    savedImageAltText,
    onRemoveImage,
    onRotateImage,
    onUpdateImageOrder,
  } = props;

  const onSortableEnd = result => {
    onUpdateImageOrder(result);
  };

  const classes = classNames(css.root, className);

  return (
    <div className={classNameWrap}>
      <SortableGrid
        key={images}
        data={images}
        onDragEnd={onSortableEnd}
        className={classes}
      >
        {images.map((image, k) => {
          return (
            <div
              key={image.id.uuid || image.id}
              data-id={image.id.uuid || image.id}
            >
              <ThumbnailWrapper
                image={image}
                className={classNames(
                  thumbnailClassName,
                  k == 0 ? css.thumbnailBorder : null
                )}
                savedImageAltText={savedImageAltText}
                onRemoveImage={onRemoveImage}
                onRotateImage={onRotateImage}
              />
            </div>
          );
        })}
      </SortableGrid>

      {children}
    </div>
  );
};

AddImages.defaultProps = {
  className: null,
  thumbnailClassName: null,
  images: [],
};

AddImages.propTypes = {
  images: array,
  children: node.isRequired,
  className: string,
  thumbnailClassName: string,
  savedImageAltText: string.isRequired,
  onRemoveImage: func.isRequired,
  onUpdateImageOrder: func.isRequired,
};

const SortableGrid = ({ data, onDragEnd, className, children }) => {
  const gridRef = useRef(null);
  const sortableJsRef = useRef(null);

  const onListChange = () => {
    const newData = [...gridRef.current.children]
      .map(i => i.dataset.id)
      .map(id => data.find(image => (image.id.uuid || image.id) === id));

    onDragEnd(newData);
  };

  useEffect(() => {
    sortableJsRef.current = new Sortable(gridRef.current, {
      animation: 150,
      delay: 50,
      onEnd: onListChange,
    });
  }, []);

  return (
    <div ref={gridRef} className={className}>
      {children}
    </div>
  );
};

export default AddImages;
