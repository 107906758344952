import React, { Component } from 'react';
import _ from 'lodash';
import { DividerTitle, ListingCard } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import { denormalisedResponseEntities } from '../../util/data';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

import css from './ListingPage.css';

class SectionRelatedProducts extends Component {
  constructor(props) {
    super(props);
    this.state = { listings: this.getRecentListings() };
  }

  getRecentListings() {
    /* TODO: Pull recently listed items */
    // Temporary solution to show 4 random listings for design review purposes
    const sharetribeSdk = require('sharetribe-flex-sdk');
    const sdk = sharetribeSdk.createInstance({
      clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
    });
    const self = this;

    const { category, id } = this.props;

    const params = {
      pub_category: category,
      pub_sold: false,
      include: ['images'],
      'fields.image': ['variants.scaled-medium'],
      'limit.images': 1,
    };

    const randomInteger = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

    sdk.listings
      .query(params)
      .then(res => {
        const listings = denormalisedResponseEntities(res);
        const ensureListing = listings.filter(item => id !== item.id.uuid);
        const listingCount = ensureListing.length;

        if (listingCount < 4) {
          const neededCount = 4 - listingCount;

          const params2 = {
            pub_sold: false,
            include: ['images'],
            'fields.image': ['variants.scaled-medium'],
            'limit.images': 1,
          };

          sdk.listings
            .query(params2)
            .then(res1 => {
              const extraListings = denormalisedResponseEntities(res1);
              let filteredExtraListing = [];
              let neededRandomNumbersArray = [];
              let neededListings = [];
              let forStateListing = ensureListing;

              if (extraListings && extraListings.length > 4) {
                filteredExtraListing = extraListings.filter(l => {
                  let exist = false;
                  listings.map(l1 => {
                    if (l1.id.uuid === l.id.uuid) {
                      exist = true;
                    }
                  });

                  return !exist;
                });

                while (neededRandomNumbersArray.length < neededCount) {
                  const randomNumber = randomInteger(1, filteredExtraListing.length);
                  let randomNumberExist = false;

                  if (neededRandomNumbersArray.length < 1) {
                    neededRandomNumbersArray.push(randomNumber);
                  } else {
                    neededRandomNumbersArray.map(num => {
                      if (num === randomNumber) {
                        randomNumberExist = true;
                      }
                    });

                    if (!randomNumberExist) neededRandomNumbersArray.push(randomNumber);
                  }
                }

                neededListings = filteredExtraListing.filter((_, i) => {
                  let areEqualToIndex = false;
                  neededRandomNumbersArray.map(num => {
                    if (num - 1 === i) {
                      areEqualToIndex = true;
                    }
                  });

                  return areEqualToIndex;
                });

                Array.from({ length: neededCount }).forEach((_, i) => {
                  forStateListing = [...forStateListing, neededListings[i]];
                });
              }

              self.setState({ listings: _.shuffle(forStateListing) });
            })
            .catch(res => {
              // An error occurred
              console.log(`Request failed with status: ${res.status} ${res.statusText}`);
              self.setState({ listings: [] });
            });
        } else {
          const shuffledEnsuredListing = _.shuffle(ensureListing);
          let fourEnsuredListing = [];
          Array.from({ length: 4 }).forEach((_, i) => {
            fourEnsuredListing = [...fourEnsuredListing, shuffledEnsuredListing[i]];
          });
          self.setState({ listings: fourEnsuredListing });
        }
      })
      .catch(res => {
        // An error occurred
        console.log(`Request failed with status: ${res.status} ${res.statusText}`);
        self.setState({ listings: [] });
      });

    /* End of temporrary solution */
  }

  render() {
    const dataFetched = this.state.listings;
    let recentListings;
    const { id, history } = this.props;

    const clickSoldListing = (sold, category) => {
      if (sold) {
        history.push(
          createResourceLocatorString(
            'SearchPage',
            routeConfiguration(),
            {},
            { pub_category: category }
          ),
          {
            sold: true,
          }
        );
      }
    };

    if (dataFetched) {
      const ensure4Listing =
        this.state.listings && this.state.listings.length > 0
          ? this.state.listings.filter(item => {
              if (item && item.id && item.id.uuid) {
                return id !== item.id.uuid;
              }

              return false;
            })
          : [];
      // remove last item
      // ensure4Listing.length === 4 && ensure4Listing.pop();

      recentListings = (
        <ul className={css.listingCards}>
          {ensure4Listing && ensure4Listing.length > 0
            ? ensure4Listing.map(l => {
                const { sold, category } = l.attributes.publicData;
                return (
                  id !== l.id.uuid && (
                    <li
                      className={css.listingCardContainer}
                      onClick={() => clickSoldListing(sold, category[0])}
                    >
                      <ListingCard
                        className={css.listingCard}
                        key={l.id.uuid}
                        listing={l}
                        dontClickSold={sold}
                      />
                    </li>
                  )
                );
              })
            : null}
        </ul>
      );
    } else {
      recentListings = null;
    }

    const sectionTitle = <FormattedMessage id="ListingPage.relatedProducts" />;

    return (
      <div className={css.sectionRelated}>
        <h3 className={css.sectionRelatedHeader}>{sectionTitle}</h3>
        {recentListings}
      </div>
    );
  }
}

export default SectionRelatedProducts;
