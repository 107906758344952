import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";

const FilterCheckbox = ({
                          checked,
                          handleChange,
                          label,
                          name,
                          value,
                          wrapWithLi
}) => {

  const [ _checked, set_Checked] = useState(checked)

  useEffect(() => {
    set_Checked(checked)
  },[checked])

  const _handleChange = () => {
    handleChange(!_checked);
    set_Checked(!_checked);
  }

  const returnCheckbox = () => {
    return <label>
      <input
        type="checkbox"
        value={value}
        name={name}
        onChange={_handleChange}
        checked={_checked}
      />
          <span>{ label }</span>
    </label>
  }

  if (wrapWithLi) {
     return <li>{returnCheckbox()}</li>
  } else {
    return returnCheckbox()
  }

}

FilterCheckbox.propTypes = {
  checked : PropTypes.bool,
  label: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
  name : PropTypes.string.isRequired,
  value : PropTypes.string.isRequired,
  wrapWithLi : PropTypes.bool
}

FilterCheckbox.defaultProps = {
  checked : false,
  handleChange : () => {},
  wrapWithLi : true,
}

export default FilterCheckbox;