import React from 'react';

import css from './DividerTitle.css';

const DividerTitle = props => {
  const { title } = props;
  return (
    <div className={css.divider}>
      <span className={css.titleBorder}></span>
      <h2 className={css.title}>{title}</h2>
      <span className={css.titleBorder}></span>
    </div>
  );
};

export default DividerTitle;
