import React from 'react';
import { compose } from 'redux';
import { object, string, bool, number, func, shape } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import omit from 'lodash/omit';

import config from '../../config';
import {
  BookingDateRangeFilter,
  PriceFilter,
  KeywordFilter,
  SortBy,
  SelectSingleFilter,
} from '../../components';
import routeConfiguration from '../../routeConfiguration';
import { parseDateFromISO8601, stringifyDateToISO8601 } from '../../util/dates';
import { createResourceLocatorString } from '../../util/routes';
import { propTypes } from '../../util/types';
import css from './SearchFilters.css';
import { parse } from '../../util/urlHelpers';
import {subCategories} from "../../marketplace-custom-config";

// Dropdown container can have a positional offset (in pixels)
const FILTER_DROPDOWN_OFFSET = -14;
// const RADIX = 10;

// resolve initial value for a single value filter
const initialValue = (queryParams, paramName) => {
  return queryParams[paramName];
};

// resolve initial values for a multi value filter
const initialValues = (queryParams, paramName) => {
  return !!queryParams[paramName] ? queryParams[paramName].split(',') : [];
};

// const initialPriceRangeValue = (queryParams, paramName) => {
//   const price = queryParams[paramName];
//   const valuesFromParams = !!price ? price.split(',').map(v => Number.parseInt(v, RADIX)) : [];

//   return !!price && valuesFromParams.length === 2
//     ? {
//       minPrice: valuesFromParams[0],
//       maxPrice: valuesFromParams[1],
//     }
//     : null;
// };

// const initialDateRangeValue = (queryParams, paramName) => {
//   const dates = queryParams[paramName];
//   const rawValuesFromParams = !!dates ? dates.split(',') : [];
//   const valuesFromParams = rawValuesFromParams.map(v => parseDateFromISO8601(v));
//   const initialValues =
//     !!dates && valuesFromParams.length === 2
//       ? {
//         dates: { startDate: valuesFromParams[0], endDate: valuesFromParams[1] },
//       }
//       : { dates: null };

//   return initialValues;
// };

const SearchFiltersComponent = props => {
  const {
    rootClassName,
    className,
    urlQueryParams,
    sort,
    listingsAreLoaded,
    resultsCount,
    searchInProgress,
    priceFilter,
    dateRangeFilter,
    keywordFilter,
    isSearchFiltersPanelOpen,
    toggleSearchFiltersPanel,
    searchFiltersPanelSelectedCount,
    history,
    intl,
    categoryFilter,
    subCategoriesFilter,
    redirectToURLWithoutModalState,
    location,
    onResultButtonClick,
  } = props;

  const { browsecategories } = parse(location.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  const hasNoResult = listingsAreLoaded && resultsCount === 0;
  const classes = classNames(
    rootClassName || css.root,
    { [css.longInfo]: hasNoResult },
    browsecategories && browsecategories === 'open' ? css.searchFiltersOpen : className
  );

  const categoryLabel = intl.formatMessage({
    id: 'SearchFilters.categoryLabel',
  });

  const initialSubCategories = subCategoriesFilter
    ? initialValues(urlQueryParams, subCategoriesFilter.paramName)
    : null;

  const initialCategory = categoryFilter
    ? initialValue(urlQueryParams, categoryFilter.paramName)
    : null;

  // const initialPriceRange = priceFilter
  //   ? initialPriceRangeValue(urlQueryParams, priceFilter.paramName)
  //   : null;

  // const initialDateRange = dateRangeFilter
  //   ? initialDateRangeValue(urlQueryParams, dateRangeFilter.paramName)
  //   : null;

  // const initialKeyword = keywordFilter
  //   ? initialValue(urlQueryParams, keywordFilter.paramName)
  //   : null;

  const handleSelectOptions = (urlParam, options) => {
    const queryParams =
      options && options.length > 0
        ? { ...urlQueryParams, [urlParam]: options.join(',') }
        : omit(urlQueryParams, urlParam);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  };

  const handleSelectOption = (urlParam, option, selected) => {
    // query parameters after selecting the option
    // if no option is passed, clear the selection for the filter
    // pub_subCategories should be null when selecting category
    // to clear pub_subCategories history
    const queryParams = option
      ? selected
        ? { ...urlQueryParams, [urlParam]: null, pub_subCategories: null, everything: null }
        : { ...urlQueryParams, [urlParam]: option, pub_subCategories: null, everything: null }
      : omit(urlQueryParams, urlParam);

    const everythingQueryParams = option === 'everything' && {
      ...urlQueryParams,
      everything: selected ? null : 'open',
      pub_category: null,
      pub_subCategories: null,
    };

    history.replace(
      option === 'everything'
        ? createResourceLocatorString('SearchPage', routeConfiguration(), {}, everythingQueryParams)
        : createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams)
    );
  };

  // const handlePrice = (urlParam, range) => {
  //   const { minPrice, maxPrice } = range || {};
  //   const queryParams =
  //     minPrice != null && maxPrice != null
  //       ? { ...urlQueryParams, [urlParam]: `${minPrice},${maxPrice}` }
  //       : omit(urlQueryParams, urlParam);

  //   history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  // };

  // const handleDateRange = (urlParam, dateRange) => {
  //   const hasDates = dateRange && dateRange.dates;
  //   const { startDate, endDate } = hasDates ? dateRange.dates : {};

  //   const start = startDate ? stringifyDateToISO8601(startDate) : null;
  //   const end = endDate ? stringifyDateToISO8601(endDate) : null;

  //   const queryParams =
  //     start != null && end != null
  //       ? { ...urlQueryParams, [urlParam]: `${start},${end}` }
  //       : omit(urlQueryParams, urlParam);
  //   history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  // };

  // const handleKeyword = (urlParam, values) => {
  //   const queryParams = values
  //     ? { ...urlQueryParams, [urlParam]: values }
  //     : omit(urlQueryParams, urlParam);

  //   history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  // };

  const categoryFilterElement = categoryFilter ? (
    <SelectSingleFilter
      urlParam={categoryFilter.paramName}
      label={categoryLabel}
      onSelect={handleSelectOption}
      options={categoryFilter.config.options}
      initialValue={initialCategory}
      contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
      subCategoriesFilter={subCategoriesFilter}
      initialSubCategories={initialSubCategories}
      onSubCategoriesSelect={handleSelectOptions}
      redirectToURLWithoutModalState={redirectToURLWithoutModalState}
      location={location}
      onResultButtonClick={onResultButtonClick}
      queryParamNames={categoryFilter.queryParamNames}
    ></SelectSingleFilter>
  ) : null;

  // const priceFilterElement = priceFilter ? (
  //   <PriceFilter
  //     id="SearchFilters.priceFilter"
  //     urlParam={priceFilter.paramName}
  //     onSubmit={handlePrice}
  //     showAsPopup
  //     {...priceFilter.config}
  //     initialValues={initialPriceRange}
  //     contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
  //   />
  // ) : null;

  // const dateRangeFilterElement =
  //   dateRangeFilter && dateRangeFilter.config.active ? (
  //     <BookingDateRangeFilter
  //       id="SearchFilters.dateRangeFilter"
  //       urlParam={dateRangeFilter.paramName}
  //       onSubmit={handleDateRange}
  //       showAsPopup
  //       contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
  //       initialValues={initialDateRange}
  //     />
  //   ) : null;

  // const keywordFilterElement =
  //   keywordFilter && keywordFilter.config.active ? (
  //     <KeywordFilter
  //       id={'SearchFilters.keywordFilter'}
  //       name="keyword"
  //       urlParam={keywordFilter.paramName}
  //       label={keywordLabel}
  //       onSubmit={handleKeyword}
  //       showAsPopup
  //       initialValues={initialKeyword}
  //       contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
  //     />
  //   ) : null;

  // const toggleSearchFiltersPanelButtonClasses =
  //   isSearchFiltersPanelOpen || searchFiltersPanelSelectedCount > 0
  //     ? css.searchFiltersPanelOpen
  //     : css.searchFiltersPanelClosed;
  // const toggleSearchFiltersPanelButton = toggleSearchFiltersPanel ? (
  //   <button
  //     className={toggleSearchFiltersPanelButtonClasses}
  //     onClick={() => {
  //       toggleSearchFiltersPanel(!isSearchFiltersPanelOpen);
  //     }}
  //   >
  //     <FormattedMessage
  //       id="SearchFilters.moreFiltersButton"
  //       values={{ count: searchFiltersPanelSelectedCount }}
  //     />
  //   </button>
  // ) : null;

  return (
    <div className={classes}>
      {categoryFilterElement}

      {/* <div className={css.filters}>
        {categoryFilterElement}
        {subCategoriesFilterElement}
        {priceFilterElement}
        {dateRangeFilterElement}
        {keywordFilterElement}
        {toggleSearchFiltersPanelButton}
      </div>

      {listingsAreLoaded && resultsCount > 0 ? (
        <div className={css.searchResultSummary}>
          <span className={css.resultsFound}>
            <FormattedMessage id="SearchFilters.foundResults" values={{ count: resultsCount }} />
          </span>
        </div>
      ) : null}

      {hasNoResult ? (
        <div className={css.noSearchResults}>
          <FormattedMessage id="SearchFilters.noResults" />
        </div>
      ) : null}

      {searchInProgress ? (
        <div className={css.loadingResults}>
          <FormattedMessage id="SearchFilters.loadingResults" />
        </div>
      ) : null}
      */}
    </div>
  );
};

SearchFiltersComponent.defaultProps = {
  rootClassName: null,
  className: null,
  resultsCount: null,
  searchingInProgress: false,
  categoryFilter: null,
  subCategoriesFilter: null,
  priceFilter: null,
  dateRangeFilter: null,
  isSearchFiltersPanelOpen: false,
  toggleSearchFiltersPanel: null,
  searchFiltersPanelSelectedCount: 0,
};

SearchFiltersComponent.propTypes = {
  rootClassName: string,
  className: string,
  urlQueryParams: object.isRequired,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchingInProgress: bool,
  onManageDisableScrolling: func.isRequired,
  categoriesFilter: propTypes.filterConfig,
  subCategoriesFilter: propTypes.filterConfig,
  priceFilter: propTypes.filterConfig,
  dateRangeFilter: propTypes.filterConfig,
  isSearchFiltersPanelOpen: bool,
  toggleSearchFiltersPanel: func,
  searchFiltersPanelSelectedCount: number,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SearchFilters = compose(
  withRouter,
  injectIntl
)(SearchFiltersComponent);

export default SearchFilters;
