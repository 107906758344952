import { storableError } from '../../util/errors';
import { denormalisedResponseEntities } from '../../util/data';
import { TRANSITION_COMPLETE, TRANSITION_COMPLETE_GIVE } from '../../util/transaction';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { types as sdkTypes } from '../../util/sdkLoader';

const { UUID } = sdkTypes;

export const RECENTLY_LISTED_REQUEST = 'app/LandingPage/RECENTLY_LISTED_REQUEST';
export const RECENTLY_LISTED_SUCCESS = 'app/LandingPage/RECENTLY_LISTED_SUCCESS';
export const RECENTLY_LISTED_ERROR = 'app/LandingPage/RECENTLY_LISTED_ERROR';

export const RECENTLY_SOLD_LISTED_REQUEST = 'app/LandingPage/RECENTLY_SOLD_LISTED_REQUEST';
export const RECENTLY_SOLD_LISTED_SUCCESS = 'app/LandingPage/RECENTLY_SOLD_LISTED_SUCCESS';
export const RECENTLY_SOLD_LISTED_ERROR = 'app/LandingPage/RECENTLY_SOLD_LISTED_ERROR';

export const searchListingsRequest = searchParams => ({
  type: RECENTLY_LISTED_REQUEST,
  payload: { searchParams },
});

export const loadRecentlyListedSuccess = response => {
  return {
    type: RECENTLY_LISTED_SUCCESS,
    payload: { data: response.data },
  };
};

export const loadRecentlyListedError = e => ({
  type: RECENTLY_LISTED_ERROR,
  error: true,
  payload: e,
});

export const searchSoldListingsRequest = searchParams => ({
  type: RECENTLY_SOLD_LISTED_REQUEST,
  payload: { searchParams },
});

export const loadRecentlySoldListedSuccess = response => {
  return {
    type: RECENTLY_SOLD_LISTED_SUCCESS,
    payload: { data: response.data },
  };
};

export const loadRecentlySoldListedError = e => ({
  type: RECENTLY_SOLD_LISTED_ERROR,
  error: true,
  payload: e,
});

const RECENTLY_LISTED_PAGE_SIZE = 4;

const initialState = {
  loadInProgress: false,
  loadListingsError: null,
  recentlyListedListings: [],
  loadRecentlySoldTransactionsInProgress: false,
  loadRecentlySoldTransactionsError: null,
  recentlySoldListings: [],
  fetchRecentlySoldListingsInProgress: false,
  fetchRecentlySoldListingsError: null,
};

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case RECENTLY_LISTED_REQUEST:
      return {
        ...state,
        loadInProgress: true,
        recentlyListedListings: [],
        loadListingsError: null,
      };
    case RECENTLY_LISTED_SUCCESS:
      return {
        ...state,
        recentlyListedListings: denormalisedResponseEntities(payload),
        loadInProgress: false,
      };
    case RECENTLY_LISTED_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, loadInProgress: false, loadListingsError: payload };
    case RECENTLY_SOLD_LISTED_REQUEST:
      return {
        ...state,
        loadInProgress: true,
        recentlySoldListings: [],
        loadListingsError: null,
      };
    case RECENTLY_SOLD_LISTED_SUCCESS:
      return {
        ...state,
        recentlySoldListings: denormalisedResponseEntities(payload),
        loadInProgress: false,
      };
    case RECENTLY_SOLD_LISTED_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, loadInProgress: false, loadListingsError: payload };

    default:
      return state;
  }
};

export default listingPageReducer;

export const loadRecentlyListed = () => (dispatch, getState, sdk) => {
  dispatch(searchListingsRequest());

  const params = {
    meta_featured: true,
    perPage: RECENTLY_LISTED_PAGE_SIZE,
    include: ['author', 'images'],
    'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.scaled-medium'],
    'limit.images': 1,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(loadRecentlyListedSuccess(response));
    })
    .catch(e => {
      dispatch(loadRecentlyListedError(storableError(e)));
      throw e;
    });
};

export const loadRecentlySoldListed = () => (dispatch, getState, sdk) => {
  dispatch(searchSoldListingsRequest());

  const params = {
    meta_sold: true,
    perPage: RECENTLY_LISTED_PAGE_SIZE,
    include: ['author', 'images'],
    'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.scaled-medium'],
    'limit.images': 1,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(loadRecentlySoldListedSuccess(response));
    })
    .catch(e => {
      dispatch(loadRecentlySoldListedError(storableError(e)));
      throw e;
    });
};

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  return Promise.all([dispatch(loadRecentlyListed()), dispatch(loadRecentlySoldListed())]);
};
