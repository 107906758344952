import React from 'react';
import { string, image, object, array } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import { NamedLink } from '../../components/';

import css from './SectionCombo.css';

const SectionCombo = props => {
  const { 
    rootClassName, 
    className, 
    title,
    copy,
    namedLink,
    namedLinkSlug,
    params,
    to,
    linkLabel,
    color,
    contrast,
    layout,
    layoutMobile,
    image,
    imageSrcset,
    imageAlt
  } = props;

  const classes = classNames(rootClassName || css.root, className, color, contrast, layout, layoutMobile);

  return (
    <div className={classes}>
      <div className={css.image}>
        <img 
          src={image}
          srcset={imageSrcset}
          width="560"
          height="560"
          alt={imageAlt}
          loading="lazy"
        />
      </div>
      <div className={css.container}>
        { title && 
        <h2 className={css.title}> { title} </h2>
        }
        { copy && 
        <div dangerouslySetInnerHTML={{ __html: copy }}></div>
        }
        { namedLink && 
        <div className={css.footer}>
          <NamedLink
            className={css.button}
            name={namedLink}
            params={{ slug: namedLinkSlug }}
            to={to}
            >
                { linkLabel }
          </NamedLink>
        </div>
        }
      </div>
    </div>
  );
};

SectionCombo.defaultProps = { 
  rootClassName: null, 
  className: null,
  title: null,
  copy: null,
  namedLink: null,
  namedLinkSlug: null,
  params: null,
  to: null,
  linkLabel: null,
  color: null,
  contrast: 'positive',
  layout: null,
  layoutMobile: null,
  image: null,
  imageSrcset: null,
  imageAlt: string
};

SectionCombo.propTypes = {
  rootClassName: string,
  className: string,
  title: string,
  copy: string,
  namedLink: string,
  namedLinkSlug: string,
  params: array,
  to: object,
  linkLabel: string,
  color: string,
  contrast: string,
  layout: string,
  layoutMobile: string,
  image: string,
  imageSrcset: string,
  imageAlt: string
};

export default SectionCombo;
