import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {filters} from '../../marketplace-custom-config';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import {
  Button,
  NamedLink,
} from '../../components';
import css from './CurrentFilters.css';

import { intlShape, injectIntl } from '../../util/reactIntl';

const CurrentFilters = ({filterState,handleClearAll,handleFilterClick,keyWords,removeKeyWord}) => {

  const [allSubCategories, setAllSubCategories] = useState([]);
  const [allCategories, setAllCategories] = useState([]);

  useEffect(() => {

    filters.map( category => {
      const { subCategories, ..._category} = category;
      setAllCategories([
        ...allCategories,
        _category
      ])
      setAllSubCategories([
        allSubCategories,
        subCategories
      ])

    })

  },[]);




  if ( (Object?.keys(filterState)?.length > 0 && Object?.values(filterState)?.flat()?.length > 0) || !!keyWords ) {
    return (
      <div className={css.root}>
        <ul>
          { !!keyWords && <li><a
              className={css.currentFilter}
              onClick={removeKeyWord}
          >Search: {keyWords} </a></li>}
          { Object.keys(filterState).map( filter => {

            return filterState[filter].map( ({name,key,label,parentLabel}) => (
              <li><a
                className={css.currentFilter}
                onClick={() => {
                  handleFilterClick(name,{key,name,label,parentLabel},true)
                }}
              >{parentLabel}: {label} </a></li>
            ))

          })}
        </ul>
        <a onClick={handleClearAll} href="javascript:void(0)" className={css.clearAll}>Clear filters</a>
      </div>
    );
  } else {
    return <></>
  }

};

CurrentFilters.defaultProps = {
};

const { array, node, object, string } = PropTypes;

CurrentFilters.propTypes = {
};

export default injectIntl(CurrentFilters);
